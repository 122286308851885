<form [formGroup]="bannerForm">
  <div class="row">
    <div class="col-md-6">
      <app-form-component [title]="'Баннер'">
        <app-text-field
          formControlName="title"
          class="mb-3 d-block"
          placeholder="Введите название баннера"
          label="Название"
          [isRequired]="true"
        ></app-text-field>

        <app-textarea-field
          formControlName="description"
          class="mb-3 d-block"
          label="Описание"
          placeholder="Введите описание описание баннера"
        ></app-textarea-field>

        <app-attachment-cropper-field
          formControlName="image"
          class="mb-3 d-block"
          [aspectRatio]="328/123"
          label="Загрузите изображение баннера"
          [recommendationInfo]="[
                {title: 'Ограничение для фото', message: 'Минимальный размер - 328 x 123\nРекомендуемый размер - 984 х 369'}
          ]"
        ></app-attachment-cropper-field>

        <app-text-field
          class="mb-3"
          formControlName="buttonTitle"
          label="Призыв к действию"
          placeholder="Введите текст который будет отображаться на элементе призыва к действию"
        ></app-text-field>

        <app-app-link-field formControlName="appLink" class="mb-3"></app-app-link-field>
      </app-form-component>
    </div>

    <div class="col-md-6">
      <div class="card sticky-top">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр баннера в приложении</h3>
        </div>
        <div class="card-body">
          <app-mobile-banner-preview [form]="bannerForm"></app-mobile-banner-preview>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/banners'])" class="btn btn-link">Отмена</a>
            <button (click)="onSubmit()" type="submit" class="btn btn-primary" name="submit_button">
              <span
                *ngIf="formState == UIStateType.Loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Сохрантиьть
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
