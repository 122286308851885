<div class="card">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          [ngClass]="{ active: this.value.appLinkType == AppLinkTypeEnum.HTTP_LINK }"
          (click)="didClickChangeType(AppLinkTypeEnum.HTTP_LINK)"
          >Ссылка</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          [ngClass]="{ active: this.value.appLinkType == AppLinkTypeEnum.DEEPLINK }"
          (click)="didClickChangeType(AppLinkTypeEnum.DEEPLINK)"
          >Диплинк</a
        >
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="tab-content">
      <div
        class="tab-pane"
        [ngClass]="{ active: this.value.appLinkType == AppLinkTypeEnum.HTTP_LINK }"
      >
        <app-text-field
          [formControl]="urlFormControl"
          placeholder="Введите ссылку на которую будет перенаправлять баннер из приложения"
        ></app-text-field>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{ active: this.value.appLinkType == AppLinkTypeEnum.DEEPLINK }"
      >
        <app-deeplink-field [formControl]="deeplinkFormControl"></app-deeplink-field>
      </div>
    </div>
  </div>
</div>
