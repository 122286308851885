import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { AutocompleteSearchInputComponent } from '../../../../../components/common/inputs/autocomplete-search-input/autocomplete-search-input.component';
import { CommonModule } from '@angular/common';

import { ImageCropperComponent } from 'ngx-image-cropper';
import { Router, RouterModule } from '@angular/router';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { ServiceExtensionType } from '../../../domain/entities/service-type';
import { DynamicModule } from 'ng-dynamic-component';
import {
  SelectFieldComponent,
  SelectFieldValue,
} from '../../../../../components/common/form-fields/select-field/select-field.component';
import { ServiceSelectFieldComponent } from '../../../../../components/common/form-fields/service-select-field/service-select-field.component';
import {
  AttributedFormOutput,
  ServiceExtensionAttributesComponent,
} from '../service-extension-attributes/service-extension-attributes.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../../components/common/form-fields/textarea-field/textarea-field.component';
import { ServiceDef } from '../../../domain/entities/service-def';
import { MobileSmotreshkaPacketPreviewComponent } from '../service-previews/mobile-smotreshka-packet-preview/mobile-smotreshka-packet-preview.component';
import { MobileGeneralPreviewComponent } from '../service-previews/mobile-general-preview/mobile-general-preview.component';
import { MobileSmotreshkaOnlineCinemaPreviewComponent } from '../service-previews/mobile-smotreshka-online-cinema-preview/mobile-smotreshka-online-cinema-preview.component';
import { MobileCatvPacketPreviewComponent } from '../service-previews/mobile-catv-packet-preview/mobile-catv-packet-preview.component';
import { MobileFullDescPreviewComponent } from '../service-previews/mobile-full-desc-preview/mobile-full-desc-preview.component';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';

@Component({
  selector: 'app-service-extension-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageHeaderComponent,
    PageWrapperComponent,
    AutocompleteSearchInputComponent,
    ImageCropperComponent,
    RouterModule,
    MobilePreviewComponent,
    FormComponentComponent,
    DynamicModule,
    SelectFieldComponent,
    ServiceSelectFieldComponent,
    ServiceExtensionAttributesComponent,
    TextFieldComponent,
    TextareaFieldComponent,
    MobileSmotreshkaPacketPreviewComponent,
    MobileGeneralPreviewComponent,
    MobileSmotreshkaOnlineCinemaPreviewComponent,
    MobileCatvPacketPreviewComponent,
    MobileFullDescPreviewComponent,
  ],
  templateUrl: './service-extension-form.component.html',
  styleUrl: './service-extension-form.component.css',
})
export class ServiceExtensionFormComponent implements OnInit {
  @Input() delegate?: ServiceExtensionFormComponentDelegate;
  @Input() formSendingState: UIStateType = UIStateType.NotRequested;

  protected serviceExtForm!: FormGroup<ServiceExtForm>;

  protected availableExtTypeSelectables: SelectFieldValue<ServiceExtensionType>[] = [
    { value: ServiceExtensionType.CATV_PACKET, selectTitle: 'Пакет КТВ' },
    { value: ServiceExtensionType.GENERAL, selectTitle: 'Прочие услуги' },
    {
      value: ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA,
      selectTitle: 'Онлайн кинотеатр смотрешки',
    },
    { value: ServiceExtensionType.SMOTRESHKA_TV_PACKET, selectTitle: 'Пакет каналов смотрешки' },
  ];

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.serviceExtForm = this.formBuilder.group({
      title: new FormControl(''),
      description: new FormControl(''),
      service: new FormControl(null, Validators.required),
      serviceType: new FormControl(null, Validators.required),
      attributes: new FormControl(null, Validators.required),
    }) as FormGroup<ServiceExtForm>;

    if (this.delegate?.didCreateForm) {
      this.delegate.didCreateForm(this.serviceExtForm);
    }
  }

  didClickSubmit() {
    if (this.serviceExtForm.valid && this.delegate?.didSubmitForm) {
      this.delegate.didSubmitForm(this.serviceExtForm);
    }
  }

  protected readonly ServiceExtensionType = ServiceExtensionType;
  protected readonly UIStateType = UIStateType;
}

export interface ServiceExtensionFormComponentDelegate {
  didCreateForm?: (form: FormGroup<ServiceExtForm>) => void;
  didSubmitForm?: (form: FormGroup<ServiceExtForm>) => void;
}

export interface ServiceExtForm {
  title: FormControl<string>;
  description: FormControl<string>;
  service: FormControl<ServiceDef | null>;
  serviceType: FormControl<ServiceExtensionType | null>;
  attributes: FormControl<AttributedFormOutput | null>;
}
