import { Injectable } from '@angular/core';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';
import { AbonentServiceType } from '../../domain/abonent-service';
import { AbonentServiceCreateRequest } from '../../domain/abonent-service-create-request';
import { AbonentServiceCreateRequestDto } from '../dto/abonent-service-create-request-dto';

@Injectable({ providedIn: 'root' })
export class AbonentServiceCreateRequestMapper {
  constructor(private attachmentMapper: AttachmentMapper) {}

  public toData(domain: AbonentServiceCreateRequest): AbonentServiceCreateRequestDto {
    return new AbonentServiceCreateRequestDto(
      domain.type.valueOf().toLowerCase(),
      domain.title,
      domain.short_description,
      domain.full_description,
      domain.type == AbonentServiceType.mobile_app ? null : domain.service_link_web!,
      domain.type == AbonentServiceType.mobile_app ? domain.service_link_android! : null,
      domain.type == AbonentServiceType.mobile_app ? domain.service_link_ios! : null,
      domain.icon_id,
      domain.image_id,
    );
  }
}
