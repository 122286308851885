import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { Loadable, UIStateType } from '../../../core/utils/wrappers/loadable';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { PageHeaderComponent } from '../common/page-header/page-header.component';
import { PageWrapperComponent } from '../common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../common/breadcrumbs/breadcrumbs.component';
import { PaginationResponse } from '../../../entity/pagination-response/domain/pagination-response';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination-view',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
  ],
  templateUrl: './pagination-view.component.html',
  styleUrl: './pagination-view.component.css',
})
export class PaginationViewComponent<T> {
  @ContentChild(TemplateRef) public dataTemplate: TemplateRef<any> | null = null;

  @Input() public data?: Loadable<PaginationResponse<T>>;
  @Input() public perPage: number = 10;
  @Input() public pageNumber: number = 1;

  @Input() public delegate?: PaginationViewDelegate;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  get pageArray(): number[] {
    let pages = this.data?.safeData?.pages ?? 1;
    return new Array(pages).fill(null).map((_, i) => i + 1);
  }

  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.delegate?.didChangePage(pageNumber);
  }

  protected readonly UIStateType = UIStateType;
}

export interface PaginationViewDelegate {
  didChangePage: (newPage: number) => void;
}
