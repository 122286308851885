import { WeekDaysEnum } from './orion-office-business-hours';

export class OrionOfficeBusinessHoursCreate {
  constructor(
    public officeId: number,
    public weekday: WeekDaysEnum,
    public openTime: string | null,
    public closeTime: string | null,
    public isDayOff: boolean,
  ) {}
}
