import { Banner } from '../../domain/banner';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { BannerMapper } from '../mappers/banner-mapper';
import { DataStatus } from '../../../../../core/network/data.status';
import { BannerApiDto } from '../dto/banner-api-dto';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';
import { PaginationResponseItemMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-item-mapper';
import { BannerCreateRequest } from '../../domain/banner-create-request';
import { BannerCreateRequestMapper } from '../mappers/banner-create-request-mapper';

@Injectable({ providedIn: 'root' })
export class BannerRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private bannerMapper: BannerMapper,
    private bannerCreateRequestMapper: BannerCreateRequestMapper,
    private paginationResponseItemMapper: PaginationResponseItemMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAll(
    per_page: number,
    page_number: number,
    is_active: boolean,
  ): Promise<DataStatus<PaginationResponse<Banner>>> {
    const payload = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
      'filters': [
        {
          'column': 'is_active',
          'operator': 'eq',
          'value': is_active,
        },
        {
          'column': 'deleted_at',
          'operator': 'eq',
          'value': null,
        },
      ],
    };

    let response: DataStatus<PaginationResponseApiDto<BannerApiDto>> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners`,
      payload,
    );

    return response.map((data) => {
      return this.paginationResponseItemMapper.toDomain(data, (t) => {
        return this.bannerMapper.toDomain(t);
      });
    });
  }

  async getById(id: number) {
    let response: DataStatus<BannerApiDto> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners/${id}`,
    );

    return response.map((t) => {
      return this.bannerMapper.toDomain(t);
    });
  }

  async create(request: BannerCreateRequest): Promise<DataStatus<Banner>> {
    let response: DataStatus<BannerApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners`,
      this.bannerCreateRequestMapper.toData(request),
    );

    return response.map((data) => {
      return this.bannerMapper.toDomain(data);
    });
  }

  async delete(id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners/${id}`,
    );
  }

  async update(body: any, banner_id: number): Promise<DataStatus<Banner>> {
    let response: DataStatus<BannerApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners/${banner_id}`,
      body,
    );
    return response.map((data) => {
      return this.bannerMapper.toDomain(data);
    });
  }
}
