<td class="text-center" style="color: #6c7a91">{{ item?.tariffId }}</td>
<td class="text-center">{{ item?.name }}</td>
<td class="text-center">{{ item?.name }}</td>
<td class="block text-center">{{ item?.description }}</td>
<td>
  <div class="dropdown">
    <button
      class="btn btn-link"
      type="button"
      id="dropdownMenuButton"
      style="height: 1.5rem; font-size: 20px"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <div
        class="spinner-border"
        *ngIf="this.tariffDeleteStatus.status == UIStateType.Loading"
      ></div>
      <i
        class="ti ti-dots-vertical"
        *ngIf="this.tariffDeleteStatus.status != UIStateType.Loading"
      ></i>
    </button>
    <div
      class="dropdown-menu dropdown-menu-demo"
      *ngIf="this.tariffDeleteStatus.status != UIStateType.Loading"
      aria-labelledby="dropdownMenuButton"
    >
      <button type="button" class="dropdown-item" (click)="didClickDelete()">
        <span>Удалить</span>
      </button>
    </div>
  </div>
</td>
