import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { WeekDaysEnum } from '../../../../../domain/orion-office-business-hours';
import { NgForOf } from '@angular/common';
import { WeekdayLocalized } from '../../../../orion-offices/orion-offices.component';

@Component({
  selector: 'app-orion-business-hours-field',
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf],
  templateUrl: './orion-business-hours-field.component.html',
  styleUrl: './orion-business-hours-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OrionBusinessHoursFieldComponent,
    },
  ],
})
export class OrionBusinessHoursFieldComponent implements OnInit, ControlValueAccessor {
  formGroup!: FormGroup<OrionBusinessHoursFormArray>;

  private onChange?: (value: OrionBusinessHoursFormValue[]) => void;

  constructor(private formBuilder: NonNullableFormBuilder) {}

  ngOnInit() {
    let weekDaysFormArray: FormGroup<OrionBusinessHoursForm>[] = [];

    for (let i in WeekDaysEnum) {
      weekDaysFormArray.push(
        this.formBuilder.group({
          weekDay: new FormControl(
            WeekDaysEnum[i as keyof typeof WeekDaysEnum],
            Validators.required,
          ),
          openTime: new FormControl(null),
          closeTime: new FormControl(null),
          isDayOff: new FormControl(false),
        }) as FormGroup<OrionBusinessHoursForm>,
      );
    }

    this.formGroup = this.formBuilder.group({
      items: new FormArray<FormGroup<OrionBusinessHoursForm>>(
        weekDaysFormArray,
        Validators.required,
      ),
    }) as FormGroup<OrionBusinessHoursFormArray>;

    this.formGroup.valueChanges.subscribe((t) => {
      if (this.formGroup.valid) {
        let items = t.items?.map((e) => {
          return new OrionBusinessHoursFormValue(
            e.id,
            e.weekDay!,
            e.openTime!,
            e.closeTime!,
            e.isDayOff!,
          );
        });

        if (this.onChange && items) {
          this.onChange(items);
        }
      }
    });
  }

  private getFormItem(weekDay: WeekDaysEnum) {
    return this.formBuilder.group({
      weekDay: new FormControl(weekDay, Validators.required),
      openTime: new FormControl(null),
      closeTime: new FormControl(null),
      isDayOff: new FormControl(false),
    }) as FormGroup<OrionBusinessHoursForm>;
  }

  registerOnChange(fn: (value: OrionBusinessHoursFormValue[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: OrionBusinessHoursFormValue[]): void {
    this.formGroup.patchValue({
      items: obj.map((t) => {
        return (
          this.formBuilder.group({
            id: [t.id],
            weekDay: [t.weekDay],
            closeTime: [t.closeTime.substring(0, 5)],
            openTime: [t.openTime.substring(0, 5)],
            isDayOff: [t.isDayOff],
          }) as FormGroup<OrionBusinessHoursForm>
        ).getRawValue();
      }),
    });
  }

  protected readonly WeekdayLocalized = WeekdayLocalized;
}

export class OrionBusinessHoursFormValue {
  constructor(
    public id: number | null = null,
    public weekDay: WeekDaysEnum,
    public openTime: string,
    public closeTime: string,
    public isDayOff: boolean,
  ) {}
}

interface OrionBusinessHoursFormArray {
  items: FormArray<FormGroup<OrionBusinessHoursForm>>;
}

interface OrionBusinessHoursForm {
  id?: FormControl<number | null>;
  weekDay: FormControl<WeekDaysEnum>;
  openTime: FormControl<string | null>;
  closeTime: FormControl<string | null>;
  isDayOff: FormControl<boolean>;
}
