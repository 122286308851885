import {
  StatsByNewUsersItem,
  StatsByNewUsersPlatformEnum,
  StatsByNewUsersResponse,
} from '../domain/stats-by-new-users-response';
import { StatsByNewUsersResponseDto } from '../data/stats-by-new-users-response-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StatsByNewUsersResponseMapper {
  toDomain(dto: StatsByNewUsersResponseDto): StatsByNewUsersResponse {
    return new StatsByNewUsersResponse(
      dto.data.map((item) => {
        return new StatsByNewUsersItem(
          item.count,
          new Date(item.date),
          StatsByNewUsersPlatformEnum.fromString(item.platform),
        );
      }),
    );
  }
}
