<a
  class="btn btn-link"
  data-bs-toggle="modal"
  [attr.data-bs-target]="'#' + componentId"
  *ngIf="value == null"
>
  <i class="ti ti-plus"></i> Выбрать диплинку
</a>

<div class="row align-items-center" *ngIf="value != null">
  <div class="col">
    <div class="p-3 deeplink-item">
      <div class="row">
        <div class="col" style="font-size: 16px">
          {{ value.title }}
        </div>

        <div class="col-auto" style="font-size: 16px; color: #929dab">ID: {{ value.id }}</div>
      </div>
      <div class="row">
        <div class="col line-limit" style="font-size: 14px; color: #929dab">
          {{ value.description }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-auto align-items-center">
    <a class="btn btn-danger btn-icon" (click)="didClickRemoveSelectedDeeplink()"
      ><i class="ti ti-trash"></i
    ></a>
  </div>
</div>

<div
  class="modal modal-blur fade"
  [attr.id]="componentId"
  tabindex="-1"
  style="display: none"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">Добавление диплинка</div>
      <div class="modal-body">
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Выберите диплинк</label>
            <div class="input-icon">
              <input
                type="text"
                value=""
                class="form-control"
                placeholder="Поиск по названию..."
                (input)="setQuery($event)"
              />
              <span class="input-icon-addon">
                <i class="ti ti-search"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="card overflow-auto" style="height: 20rem">
          <app-loadable-wrapper
            [loadable]="searchQuery.value.length > 1 ? deeplinkSearchState : deeplinkLoadingState"
            loadingTitle="Поиск диплинков"
          >
            <div class="list-group list-group-flush">
              <div
                class="list-group-item cursor-pointer"
                [ngClass]="{ active: this.deeplinkModalSelected == item }"
                (click)="selectModalDeeplink(item)"
                *ngFor="
                  let item of (searchQuery.value.length > 1
                    ? deeplinkSearchState
                    : deeplinkLoadingState
                  ).safeData?.data
                "
              >
                <div class="row">
                  <div class="col" style="font-size: 16px">
                    {{ item.title }}
                  </div>

                  <div class="col-auto" style="font-size: 16px; color: #929dab">
                    ID: {{ item.id }}
                  </div>
                </div>
                <div class="row">
                  <div class="col line-limit" style="font-size: 14px; color: #929dab">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </div>
          </app-loadable-wrapper>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link link-secondary me-auto" data-bs-dismiss="modal">
          Отменить
        </button>

        <button
          type="button"
          class="btn btn-primary"
          (click)="setSelectedItem()"
          data-bs-dismiss="modal"
        >
          Добавить
        </button>
      </div>
    </div>
  </div>
</div>
