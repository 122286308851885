import { Component, Input, OnInit } from '@angular/core';
import { EditorJsFieldComponent } from '../../../../../../components/common/form-fields/editor-js-field/editor-js-field.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OutputData } from '@editorjs/editorjs';
import {
  GeneralAttributesFormOutput,
  ServiceExtensionAttributesDelegate,
} from '../service-extension-attributes.component';

@Component({
  selector: 'app-general-service-ext-form',
  standalone: true,
  imports: [EditorJsFieldComponent, ReactiveFormsModule],
  templateUrl: './general-service-ext-form.component.html',
  styleUrl: './general-service-ext-form.component.css',
})
export class GeneralServiceExtFormComponent implements OnInit {
  @Input() delegate?: ServiceExtensionAttributesDelegate;

  text: FormControl<OutputData | null> = new FormControl<OutputData | null>(null);

  ngOnInit() {
    this.text.valueChanges.subscribe((newValue) => {
      const newAttributes = new GeneralAttributesFormOutput({}, newValue);
      this.delegate?.onChangeValue(newAttributes);
    });
  }
}
