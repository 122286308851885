import { Component, OnInit, SkipSelf } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Task } from '../../../../../../core/utils/task';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { CommonModule } from '@angular/common';
import { ServicesExtRepository } from '../../../data/repositories/service-ext-repository';
import { ActivatedRoute, Router } from '@angular/router';

import { AttachmentRepository } from '../../../../../../entity/attachments/data/repositories/attachment-repository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../../entity/attachments/domain/attachment';
import {
  CATVPacketAttributes,
  GeneralAttributes,
  ServiceExtension,
  ServiceExtensionAttributes,
  SmotreshkaCinemaAttributes,
  SmotreshkaPacketAttributes,
} from '../../../domain/entities/service-extension';
import { ServiceExtensionType } from '../../../domain/entities/service-type';
import {
  AttributedFormOutput,
  CATVPocketFormOutput,
  GeneralAttributesFormOutput,
  SmotreshkaCinemaAttributesFormOutput,
  SmotreshkaPocketFormOutput,
} from '../../components/service-extension-attributes/service-extension-attributes.component';
import {
  ServiceExtensionFormComponent,
  ServiceExtensionFormComponentDelegate,
  ServiceExtForm,
} from '../../components/service-extension-form/service-extension-form.component';
import { ServiceDefRepository } from '../../../data/repositories/service-def-repository';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';

@Component({
  selector: 'app-service-ext-add-page',
  standalone: true,
  templateUrl: './service-ext-update-page.component.html',
  styleUrl: './service-ext-update-page.component.css',
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageHeaderComponent,
    PageWrapperComponent,
    ServiceExtensionFormComponent,
  ],
  providers: [ModalService],
})
export class ServiceExtUpdatePageComponent
  implements OnInit, ServiceExtensionFormComponentDelegate
{
  updateServiceExtState: BehaviorSubject<Loadable<ServiceExtension>> = new BehaviorSubject<
    Loadable<ServiceExtension>
  >(Loadable.notRequested());

  private oldService: Loadable<ServiceExtension> = Loadable.notRequested();
  private serviceExtId!: number;

  pageTitle: string = 'Расширение услуг';
  pageSubtitle: string = 'Тарифы и услуги';

  constructor(
    private serviceExtRepository: ServicesExtRepository,
    private readonly router: Router,
    private attachmentRepo: AttachmentRepository,
    private serviceDefRepo: ServiceDefRepository,
    @SkipSelf() private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.serviceExtId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  didCreateForm(form: FormGroup<ServiceExtForm>) {
    new Task(async () => {
      this.oldService = Loadable.getFromDataStatus(
        await this.serviceExtRepository.getById(this.serviceExtId),
      );

      let data = this.oldService.safeData;

      if (data) {
        form.patchValue({
          title: data.title,
          description: data.description,
          serviceType: data.serviceType,
          attributes: this.mapToAttributedFormOutput(data.attributes, data.serviceType),
        });
      }
    });
  }

  private mapToAttributedFormOutput(
    attributes: ServiceExtensionAttributes,
    type: ServiceExtensionType,
  ): AttributedFormOutput {
    switch (type) {
      case ServiceExtensionType.SMOTRESHKA_TV_PACKET:
        let packAttr = attributes as SmotreshkaPacketAttributes;
        return new SmotreshkaPocketFormOutput({
          initialUrl: packAttr.cover.content.attributes.contentPath,
        });
      case ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA:
        let cinemaAttr = attributes as SmotreshkaCinemaAttributes;
        return new SmotreshkaCinemaAttributesFormOutput(
          { initialUrl: cinemaAttr.banner.content.attributes.contentPath },
          { initialUrl: cinemaAttr.icon.content.attributes.contentPath },
        );

      case ServiceExtensionType.CATV_PACKET:
        let catvAttr = attributes as CATVPacketAttributes;
        return new CATVPocketFormOutput(catvAttr.numberOfChannels);

      case ServiceExtensionType.GENERAL:
        let generalAttr = attributes as GeneralAttributes;
        return new GeneralAttributesFormOutput(
          { initialUrl: generalAttr.banner.content.attributes.contentPath },
          generalAttr.text,
        );
    }
  }
  didSubmitForm(form: FormGroup<ServiceExtForm>) {}
}
