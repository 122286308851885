export enum AvailableServiceTypeEnum {
  cctv = 'Видеонаблюдение',
  smart_intercom = 'Домофон 2.0',
  internet = 'Интернет',
  intercom = 'НЕО',
  security = 'Охрана',
  voip = 'Телефония',
  parking = 'Умные парковки',
  gate = 'Умный шлагбаум',
  daily = 'Ежедневная',
  monthly = 'Ежемесячная',
  common = 'Общего типа',
  access = 'Предоставление доступа',
  once = 'Разовая',
  installment = 'Рассрочка',
  rent = 'Аренда',
  smotreshka = 'Смотрешка',
  itv = 'ИТВ',
  catv = 'КТВ',
}

export class AvailableService {
  constructor(
    public id: number,
    public serviceId: number,
    public manualDeactivation: boolean,
    public serviceExtId: number | null,
    public name: string,
    public description: string,
    public serviceType: string,
  ) {}
}
