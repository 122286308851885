<div>
  <app-attachment-cropper-field
    label="Загрузите иконку онлайн-кинотеатра"
    [formControl]="formGroup.controls.icon"
    [aspectRatio]="1"
    [recommendationInfo]="[
      {
        title: 'Соотношение сторон',
        message: 'Рекомендуемое соотношение сторон для фото или видео - 1:1',
      },
      {
        title: 'Ограничение для фото',
        message: 'Минимальный размер - 28 x 28 \nРекомендуемый размер - 84 х 84',
      },
    ]"
  >
  </app-attachment-cropper-field>

  <app-attachment-cropper-field
    class="mt-3 d-block"
    label="Загрузите баннер онлайн-кинотеатра"
    [aspectRatio]="984 / 354"
    [formControl]="formGroup.controls.banner"
    [recommendationInfo]="[
      {
        title: 'Ограничение для фото',
        message: 'Минимальный размер - 328 x 118 \nРекомендуемый размер - 984 х 354',
      },
    ]"
  >
  </app-attachment-cropper-field>
</div>
