<div class="mt-3" [ngSwitch]="extensionType">
  <app-smotreshka-cinema-ext-form
    [delegate]="this"
    *ngSwitchCase="ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA"
  >
  </app-smotreshka-cinema-ext-form>

  <app-general-service-ext-form [delegate]="this" *ngSwitchCase="ServiceExtensionType.GENERAL">
  </app-general-service-ext-form>

  <app-smotreshka-pocket-ext-form
    [delegate]="this"
    *ngSwitchCase="ServiceExtensionType.SMOTRESHKA_TV_PACKET"
  ></app-smotreshka-pocket-ext-form>

  <app-ctv-pocket-ext-form
    [delegate]="this"
    *ngSwitchCase="ServiceExtensionType.CATV_PACKET"
  ></app-ctv-pocket-ext-form>
</div>
