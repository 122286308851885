<form [formGroup]="serviceExtForm">
  <div class="row">
    <div class="col-md-6">
      <app-form-component [title]="'Расширение услуги из биллинга'">
        <app-service-select-field
          [isRequired]="true"
          formControlName="service"
          label="Выберите услугу"
        >
        </app-service-select-field>

        <app-select-field
          label="Задайте тип услуги"
          [isRequired]="true"
          class="mb-3"
          [selectValues]="availableExtTypeSelectables"
          placeholder="Выберите тип, к которому относится услуга"
          [formControl]="serviceExtForm.controls.serviceType"
        >
        </app-select-field>

        <hr class="hr" />

        <app-text-field
          *ngIf="serviceExtForm.controls.service"
          class="mb-3"
          formControlName="title"
          label="Название услуги"
          [placeholder]="serviceExtForm.controls.service.value?.name ?? 'Наиенование услуги'"
        >
        </app-text-field>

        <app-textarea-field
          *ngIf="serviceExtForm.controls.service"
          class="mt-3"
          formControlName="description"
          label="Описание услуги"
          [placeholder]="serviceExtForm.controls.service.value?.description ?? 'Описание услуги'"
        >
        </app-textarea-field>

        <app-service-extension-attributes
          formControlName="attributes"
          [extensionType]="serviceExtForm.getRawValue().serviceType"
        ></app-service-extension-attributes>
      </app-form-component>
    </div>

    <div class="col-md-6">
      <div class="card sticky-top">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр услуги в приложении</h3>
        </div>
        <div class="card-body" [ngSwitch]="serviceExtForm.getRawValue().serviceType">
          <div *ngSwitchDefault>
            <app-mobile-preview></app-mobile-preview>
          </div>

          <div *ngSwitchCase="ServiceExtensionType.SMOTRESHKA_TV_PACKET">
            <app-mobile-smotreshka-packet-preview [form]="serviceExtForm">
            </app-mobile-smotreshka-packet-preview>
          </div>

          <div *ngSwitchCase="ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA">
            <app-mobile-smotreshka-online-cinema-preview [form]="serviceExtForm">
            </app-mobile-smotreshka-online-cinema-preview>
          </div>

          <div *ngSwitchCase="ServiceExtensionType.CATV_PACKET">
            <app-mobile-catv-packet-preview [form]="serviceExtForm">
            </app-mobile-catv-packet-preview>
          </div>

          <div class="card-tabs" *ngSwitchCase="ServiceExtensionType.GENERAL">
            <!-- Cards navigation -->

            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  href="#tab-top-1"
                  class="nav-link active"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  role="tab"
                  >В списке</a
                >
              </li>

              <li class="nav-item" role="presentation">
                <a
                  href="#tab-top-2"
                  class="nav-link"
                  data-bs-toggle="tab"
                  aria-selected="false"
                  role="tab"
                  tabindex="-1"
                  >Подробное описание</a
                >
              </li>
            </ul>

            <div class="tab-content">
              <!-- Content of card #1 -->
              <div id="tab-top-1" class="card tab-pane active show" role="tabpanel">
                <div class="card-body">
                  <app-mobile-full-desc-preview
                    [form]="serviceExtForm"
                  ></app-mobile-full-desc-preview>
                </div>
              </div>

              <!-- Content of card #2 -->
              <div id="tab-top-2" class="card tab-pane" role="tabpanel">
                <div class="card-body">
                  <app-mobile-general-preview [form]="serviceExtForm"></app-mobile-general-preview>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/service-ext-list'])" class="btn btn-link"> Отмена </a>
            <button
              type="submit"
              class="btn btn-primary"
              (click)="didClickSubmit()"
              [disabled]="!serviceExtForm.valid"
            >
              <span
                *ngIf="formSendingState == UIStateType.Loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
