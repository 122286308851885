import { Component, Input } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-stats-container',
  standalone: true,
  imports: [ChartComponent],
  templateUrl: './stats-container.component.html',
  styleUrl: './stats-container.component.css',
})
export class StatsContainerComponent {
  @Input() title?: string;
  @Input() amount?: number;
}
