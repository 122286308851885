import EditorJS, {
  API,
  BlockToolConstructorOptions,
  OutputBlockData,
  ToolConstructable,
} from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';

export class ExpandableData {
  private api: API;
  private data: CustomBlockData;
  private wrapper: HTMLElement;
  private nestedEditor: EditorJS | null = null;

  private static id: number = 0;
  componentId = `editor-js-field-${ExpandableData.id++}`;
  constructor({ data, api }: BlockToolConstructorOptions<CustomBlockData>) {
    this.api = api;
    this.data = {
      title: data.title || '',
      content: data.content || [],
      expanded: data.expanded || false,
    };

    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('expandable-data-block');
  }

  static get toolbox() {
    return {
      title: 'Сворачивцающийся блок',
      icon: '<svg width="18" height="18" viewBox="0 0 24 24"><path d="M4 4h6v6h-6zm0 10h6v6h-6zm10-10h6v6h-6zm0 10h6v6h-6z"/></svg>',
    };
  }

  render() {
    const titleInput = document.createElement('input');

    titleInput.value = this.data.title;
    titleInput.placeholder = 'Введите заголовок';

    titleInput.addEventListener('input', (event) => {
      this.data.title = (event.target as HTMLInputElement).value;
    });

    const contentWrapper = document.createElement('div');
    contentWrapper.classList.add(`nested-editor-${this.componentId}`);

    this.wrapper.innerHTML = '';
    this.wrapper.appendChild(titleInput);
    this.wrapper.appendChild(contentWrapper);

    this.nestedEditor = new EditorJS({
      holder: contentWrapper,
      data: { blocks: this.data.content },
      onChange: async () => {
        let savedData = await this.nestedEditor?.save();
        this.data.content = savedData?.blocks ?? [];
      },
      minHeight: 50,
      tools: {
        header: {
          class: Header as unknown as ToolConstructable,
          inlineToolbar: ['link', 'bold'],
        },
        list: {
          class: List as unknown as ToolConstructable,
          inlineToolbar: ['link', 'bold'],
        },
      },
    });

    contentWrapper.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.stopPropagation(); // Останавливаем всплытие события
      }
    });

    return this.wrapper;
  }

  save(): CustomBlockData {
    return {
      title: this.data.title,
      content: this.data.content,
      expanded: this.data.expanded,
    };
  }

  destroy() {
    this.wrapper.remove();

    if (this.nestedEditor?.destroy) {
      this.nestedEditor.destroy();
    }
  }

  private getToolBoxHTML() {
    return `
            <div class="ce-popover-item__icon">
              <svg width="26" height="26" viewBox="0 0 24 24" style="margin-right: 8px;">
                <path d="${!this.data.expanded ? 'M19 13H5v-2h14v2z' : 'M7 10l5 5 5-5z'}"></path>
              </svg>
            </div>
            <div class="ce-popover-item__title">
                <span style="font-size: 12px; color: #929dab">Начальное состояние:</span>
                <br>
               ${this.data.expanded ? 'Развернуто' : 'Свернуто'}
            </div>
        `;
  }
  renderSettings() {
    const settingsWrapper = document.createElement('div');

    // Создаем кастомный элемент, имитирующий ce-popover-item
    const customSetting = document.createElement('div');
    customSetting.classList.add('ce-popover-item');

    customSetting.innerHTML = this.getToolBoxHTML();
    // customSetting.innerHTML = `По умолчанию: ${this.data.isCollapsed ? 'свернуто' : 'развернуто'}`;

    customSetting.addEventListener('click', () => {
      this.data.expanded = !this.data.expanded;
      customSetting.innerHTML = this.getToolBoxHTML();
    });

    // Добавляем кастомный элемент в обертку настроек
    settingsWrapper.appendChild(customSetting);

    return settingsWrapper;
  }
}

interface CustomBlockData {
  title: string;
  content: OutputBlockData[];
  expanded: boolean;
}
