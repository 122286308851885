<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button [title]="'Добавить историю'" [routerLink]="['create-story']"></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="row">
    <div class="col-12">
      <div class="input-icon">
        <input
          type="text"
          value=""
          class="form-control"
          placeholder="Поиск историй…"
          (input)="searchStory($event)"
        />
        <span class="input-icon-addon">
          <i class="ti ti-search"></i>
        </span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6">
      <app-loadable-wrapper [loadable]="pageActiveStories" loadingTitle="Загрузка данных...">
        <app-pagination-view
          [data]="pageActiveStories"
          [perPage]="activeStoriesPerPage"
          [pageNumber]="activeStoriesPageNumber"
        >
          <div class="table-responsive">
            <table class="table table-vcenter card-table table-striped">
              <thead>
                <tr>
                  <th>Активные</th>
                  <th class="text-center"><i class="ti ti-eye"></i></th>
                  <th class="text-center"><i class="ti ti-link"></i></th>
                  <th class="text-center"><i class="ti ti-user-plus"></i></th>
                  <th class="w-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pageActiveStories.safeData?.data">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="story-preview-container me-2">
                        <img
                          class="story-preview"
                          [src]="item.preview.content.attributes.contentPath"
                        />
                        <i
                          *ngIf="
                            item.slides.length > 0 &&
                            item.slides[0].attachment.content.type == AttachmentContentType.PHOTO
                          "
                          class="ti ti-photo story-type"
                        ></i>
                        <i
                          *ngIf="
                            item.slides.length > 0 &&
                            item.slides[0].attachment.content.type == AttachmentContentType.VIDEO
                          "
                          class="ti ti-movie story-type"
                        ></i>
                      </div>
                      <div class="flex-fill">
                        <div class="id-text">ID: {{ item.id }}</div>
                        <div class="text-reset d-block">{{ item.name }}</div>
                        <div class="text-secondary text-truncate mt-n1">
                          {{ item.adminCreator.name }}
                        </div>
                        <div *ngIf="item.deactivatedAt" class="text-secondary text-truncate mt-n1">
                          {{ item.createdAt.getTime() | dateOutput }} •
                          {{
                            item.deactivatedAt ? (item.deactivatedAt.getTime() | dateOutput) : null
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">{{ item.storyViews }}</td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td>
                    <div class="dropdown">
                      <button
                        class="btn btn-link"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="ti ti-dots-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-demo"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          routerLink="update-story/{{ item.id }}"
                          [state]="item"
                          >Редактировать</a
                        >
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="deactivateStory(item.id).finally()"
                        >
                          Завершить показ
                        </button>
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="deleteStory(item.id).finally()"
                        >
                          Удалить
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-pagination-view>
      </app-loadable-wrapper>
    </div>

    <div class="col-md-6">
      <app-loadable-wrapper [loadable]="pageHiddenStories" loadingTitle="Загрузка данных...">
        <app-pagination-view
          [data]="pageHiddenStories"
          [perPage]="hiddenStoriesPerPage"
          [pageNumber]="hiddenStoriesPageNumber"
        >
          <div class="table-responsive">
            <table class="table table-vcenter card-table table-striped">
              <thead>
                <tr>
                  <th>Завершенные</th>
                  <th class="text-center"><i class="ti ti-eye"></i></th>
                  <th class="text-center"><i class="ti ti-link"></i></th>
                  <th class="text-center"><i class="ti ti-user-plus"></i></th>
                  <th class="w-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pageHiddenStories.safeData?.data">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="story-preview-container me-2">
                        <img
                          class="story-preview"
                          [src]="item.preview.content.attributes.contentPath"
                        />
                        <i
                          *ngIf="
                            item.slides.length > 0 &&
                            item.slides[0].attachment.content.type == AttachmentContentType.PHOTO
                          "
                          class="ti ti-photo story-type"
                        ></i>
                        <i
                          *ngIf="
                            item.slides.length > 0 &&
                            item.slides[0].attachment.content.type == AttachmentContentType.VIDEO
                          "
                          class="ti ti-movie story-type"
                        ></i>
                      </div>
                      <div class="flex-fill">
                        <div class="id-text">ID: {{ item.id }}</div>
                        <div class="text-reset d-block">{{ item.name }}</div>
                        <div class="text-secondary text-truncate mt-n1">
                          {{ item.adminCreator.name }}
                        </div>
                        <div class="text-secondary text-truncate mt-n1">
                          {{ item.createdAt.getTime() | dateOutput }} •
                          {{
                            item.deactivatedAt.getTime()
                              ? (item.deactivatedAt.getTime() | dateOutput)
                              : null
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">{{ item.storyViews }}</td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td>
                    <div class="dropdown">
                      <button
                        class="btn btn-link"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="ti ti-dots-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-demo"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          routerLink="update-story/{{ item.id }}"
                          [state]="item"
                          >Редактировать</a
                        >
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="activateStory(item.id).finally()"
                        >
                          Возобновить показ
                        </button>
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="deleteStory(item.id).finally()"
                        >
                          Удалить
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-pagination-view>
      </app-loadable-wrapper>
    </div>
  </div>
</app-page-wrapper>
