<div class="card" [formGroup]="form">
  <div class="card-body">
    <app-attachment-field
      formControlName="attachment"
      [recommendationInfo]="[
        {
          title: 'Соотношение сторон',
          message:
            'Рекомендуемое соотношение сторон для фото или видео - 9:16\n\nПри загрузке фото или видео с другими пропорциями значительная часть изображения может обрезаться',
        },
        {
          title: 'Ограничение для фото',
          message:
            'Минимальный размер - 720 x 1280 Рекомендуемый размер - 1080 х 1920\n\n<b>Ограничение для видео</b>\nДлительность не более NN секунд; размер файла не более NN Мб',
        },
      ]"
    ></app-attachment-field>

    <app-text-field
      class="mt-3"
      label="Заголовок"
      placeholder="Введите заголовок"
      formControlName="title"
    ></app-text-field>

    <app-textarea-field
      class="mt-3 d-block"
      label="Описание истории"
      formControlName="description"
      placeholder="Введите описание (текст будет отображаться в открытой истории)"
    ></app-textarea-field>

    <div class="mt-3">
      <label class="form-label"> Кнопки <span style="color: gray">(опционально)</span> </label>

      <div
        class="mb-3"
        formArrayName="actionButtons"
        *ngFor="let item of this.form.controls.actionButtons.controls; let i = index"
      >
        <app-action-button-field
          [formControl]="item"
          [showRemoveButton]="true"
          (removeAction)="removeButtonAtIndex(i)"
        />
      </div>

      <button type="button" class="btn btn-link" (click)="addButton()">
        <i class="ti ti-plus"></i> Добавить кнопку в историю
      </button>
    </div>
  </div>
</div>
