<app-mobile-preview>
  <div
    class="service-image"
    style="background-image: url('../../../../../../../assets/dist/img/map-preview.png')"
  ></div>
  <div class="service-desc">
    <div class="title">{{ form.getRawValue().title }}</div>
    <div class="row address align-items-center">
      <div class="col-lg-10">
        <div class="address-header">
          <i class="ti ti-map-pin" style="margin-right: 4px"></i>Адрес:
        </div>
        <div
          *ngIf="form.getRawValue().address == undefined; else addressTemplate"
          class="address-placeholder"
        >
          Введите адрес
        </div>
        <ng-template #addressTemplate>
          <div class="address-output">
            {{ form.getRawValue().address }}
          </div>
        </ng-template>
      </div>
      <div class="col-lg-2">
        <div class="d-flex justify-content-end">
          <i class="ti ti-copy"></i>
        </div>
      </div>
    </div>
    <div class="business-hours">
      <div class="business-hours-header">
        <i class="ti ti-clock" style="margin-right: 4px"></i>Часы работы<i
          class="ti ti-chevron-up"
        ></i>
      </div>
      <div class="business-hours-container">
        <div *ngFor="let item of form.getRawValue().businessDays ?? []">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="weekday">
                {{ WeekdayLocalized.localized(item.weekDay) }}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="d-flex justify-content-end">
                <div *ngIf="!item.isDayOff; else dayOff" class="hours">
                  {{ item.openTime }}
                  —
                  {{ item.closeTime }}
                </div>
                <ng-template #dayOff>
                  <div class="dayOff">Выходной</div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts">
      <div class="contacts-header">
        <i class="ti ti-phone" style="margin-right: 4px"></i>Контакты
      </div>
      <div *ngIf="form.getRawValue().contacts.length > 0" class="contact-container">
        <div *ngFor="let contact of form.getRawValue().contacts ?? []">
          <div class="row align-items-center d-flex">
            <div class="col-lg-8">
              {{ contact.phone | phoneOutput }}
            </div>
            <div class="col-lg-4">
              <div
                *ngIf="contact.phone != undefined"
                class="contact-icons d-flex justify-content-end"
              >
                <i class="ti ti-copy"></i><i class="ti ti-phone"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>
