import { Component, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { PhoneOutputPipe } from '../../../../components/common/pipes/phone-output-pipe';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { OfficeCityType, OfficeCityTypeMapper, OrionOffice } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/orion-office-repository';
import { keys } from 'lodash';
import { WeekDaysEnum } from '../../domain/orion-office-business-hours';

@Component({
  selector: 'app-orion-offices',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    RouterModule,
    LoadableWrapperComponent,
    AddButtonComponent,
    BtnListComponent,
    PhoneOutputPipe,
  ],
  providers: [ModalService],
  templateUrl: './orion-offices.component.html',
  styleUrl: './orion-offices.component.css',
})
export class OrionOfficesComponent implements OnInit {
  pageTitle: string = 'Офисы';
  pageSubtitle: string = 'Офисы';

  public orionOffices: Loadable<OrionOffice[]> = Loadable.notRequested();
  private officeCity: string = 'krasnoyarsk';

  constructor(private orionOfficeRepository: OrionOfficeRepository) {}

  ngOnInit(): void {
    this.getOrionOffices(this.officeCity).finally();
  }

  async getOrionOffices(city: string | null) {
    if (this.orionOffices.status != UIStateType.Loading) {
      this.orionOffices = Loadable.loading();
      let response = await this.orionOfficeRepository.findAllByCity(city);
      this.orionOffices = Loadable.getFromDataStatus(response);
    }
    // this.orionOffices.safeData?.map((office) => this.sortOfficeHours(office));
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityTypeMapper.fromString(officeCity);
  }

  getWeekDayAbbrEnum(weekday: string): string {
    return WeekdayShortLocalized.localized(WeekDaysEnum[weekday as keyof typeof WeekDaysEnum]);
  }

  setOfficeCity($event: any) {
    this.officeCity = $event.target.value;
    this.getOrionOffices(this.officeCity).finally();
  }

  async delete(id: number) {
    await this.orionOfficeRepository.delete(id);
    this.getOrionOffices(this.officeCity).finally();
  }

  protected readonly keys = keys;
  protected readonly OfficeCityType = OfficeCityType;
  protected readonly Object = Object;
}

export namespace WeekdayShortLocalized {
  export function localized(weekday: WeekDaysEnum): string {
    switch (weekday) {
      case WeekDaysEnum.monday:
        return 'Пн.';
      case WeekDaysEnum.tuesday:
        return 'Вт.';
      case WeekDaysEnum.wednesday:
        return 'Ср.';
      case WeekDaysEnum.thursday:
        return 'Чт.';
      case WeekDaysEnum.friday:
        return 'Пт.';
      case WeekDaysEnum.saturday:
        return 'Сб.';
      case WeekDaysEnum.sunday:
        return 'Вс.';
    }
  }
}

export namespace WeekdayLocalized {
  export function localized(weekday: WeekDaysEnum): string {
    switch (weekday) {
      case WeekDaysEnum.monday:
        return 'Поннедельник';
      case WeekDaysEnum.tuesday:
        return 'Вторник';
      case WeekDaysEnum.wednesday:
        return 'Среда';
      case WeekDaysEnum.thursday:
        return 'Четверг';
      case WeekDaysEnum.friday:
        return 'Пятница';
      case WeekDaysEnum.saturday:
        return 'Суббота';
      case WeekDaysEnum.sunday:
        return 'Воскресенье';
    }
  }
}
