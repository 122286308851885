<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить расширение'"
    (click)="router.navigate(['/tariff-ext-list/create'])"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-loadable-wrapper
    [loadable]="tariffListState"
    loadingTitle="Загрузка расширений..."
    [errorRepeatAction]="fetchTariffList"
  >
    <app-pagination-view
      [data]="tariffListState"
      [perPage]="perPage"
      [pageNumber]="currentPage"
      [delegate]="this"
    >
      <table class="table table-vcenter card-table">
        <thead>
          <tr>
            <th class="text-center">ID тарифа</th>
            <th class="text-center">Название в биллинге</th>
            <th class="text-center">Название в приложении</th>
            <th class="text-center">Описание в приложении</th>
            <th class="w-1"></th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let item of tariffListState.safeData?.data">
            <tr app-tariff-ext-list-item [item]="item" [didDeleteItem]="didDeleteItem"></tr>
          </ng-container>
        </tbody>
      </table>
    </app-pagination-view>
  </app-loadable-wrapper>
</app-page-wrapper>
