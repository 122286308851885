import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../../components/common/form-fields/textarea-field/textarea-field.component';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deeplinks-create-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormComponentComponent,
    TextFieldComponent,
    TextareaFieldComponent,
    NgIf,
  ],
  templateUrl: './deeplinks-create-form.component.html',
  styleUrl: './deeplinks-create-form.component.css',
})
export class DeeplinksCreateFormComponent implements OnInit {
  @Input() sendStatus?: UIStateType = UIStateType.NotRequested;
  @Input() delegate?: DeeplinkFormDelegate;

  formGroup!: FormGroup<DeeplinkCreateForm>;

  constructor(
    protected router: Router,
    private formBuilder: NonNullableFormBuilder,
  ) {}
  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      link: ['', Validators.required],
    }) as FormGroup<DeeplinkCreateForm>;

    this.delegate?.didCreateForm(this.formGroup);
  }

  didClickSubmit() {
    this.delegate?.didSubmitForm(this.formGroup);
  }

  protected readonly UIStateType = UIStateType;
}

export interface DeeplinkFormDelegate {
  didCreateForm: (form: FormGroup<DeeplinkCreateForm>) => void;
  didSubmitForm: (form: FormGroup<DeeplinkCreateForm>) => void;
}
export interface DeeplinkCreateForm {
  title: FormControl<string>;
  description: FormControl<string>;
  link: FormControl<string>;
}
