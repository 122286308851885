import { Component, OnInit, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { AbonentServiceRepository } from '../../data/repositories/abonent-service-repository';
import { AbonentService } from '../../domain/abonent-service';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { MobileAbonentServicesListComponent } from '../../components/mobile-abonent-services-list-preview/mobile-abonent-services-list-preview.component';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { DatepickerFieldComponent } from '../../../../components/common/form-fields/datepicker-field/datepicker-field.component';
import { Task } from '../../../../../core/utils/task';

@Component({
  selector: 'app-abonent-services',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    BtnListComponent,
    AddButtonComponent,
    DragDropModule,
    MobilePreviewComponent,
    MobileAbonentServicesListComponent,
    DatepickerFieldComponent,
  ],
  providers: [ModalService],
  templateUrl: './abonent-services.component.html',
  styleUrl: './abonent-services.component.css',
})
export class AbonentServicesComponent implements OnInit {
  pageTitle: string = 'Сервисы';
  pageSubtitle: string = 'Список сервисов';

  public abonentServices: Loadable<AbonentService[]> = new Loadable<AbonentService[]>();
  public deleteServiceStatus: UIStateType = UIStateType.NotRequested;

  private currentAbonentService: any = undefined;

  deleteItemsId: number[] = [];

  isEditButtonsActive: boolean = false;

  constructor(
    private abonentServiceRepo: AbonentServiceRepository,
    @SkipSelf() private modalService: ModalService,
  ) {}

  async getAllAbonentServices(): Promise<void> {
    if (this.abonentServices.status != UIStateType.Loading) {
      this.abonentServices = Loadable.loading();

      await this.abonentServiceRepo.findAll().then((data) => {
        this.abonentServices = Loadable.getFromDataStatus(data);
      });
    }
  }

  ngOnInit(): void {
    new Task(async () => {
      await this.getAllAbonentServices();
    });
  }

  async onDelete(id: number): Promise<void> {
    let task = async () => {
      if (this.deleteServiceStatus != UIStateType.Loading) {
        this.deleteItemsId.push(id);
        this.deleteServiceStatus = UIStateType.Loading;

        let deleteStatus = Loadable.getFromDataStatus(await this.abonentServiceRepo.delete(id));

        if (deleteStatus.isSuccess) {
          await this.abonentServiceRepo.findAll().then((data) => {
            this.abonentServices = Loadable.getFromDataStatus(data);
            this.deleteServiceStatus = UIStateType.Success;
          });
        } else {
          this.modalService.createModal({ type: ModalType.DANGER, message: deleteStatus.message! });
        }
        //Лучше в компонент переделать со своим жизненом циклом, но пока что так
        let indexForDeleting = this.deleteItemsId.indexOf(id);
        delete this.deleteItemsId[indexForDeleting];
      }
    };

    this.modalService.createModal({
      type: ModalType.DANGER,
      header: 'Вы действительно хотите удалить сервис?',
      message: 'Подтвердив данное действие, сервис будет удален безвозвратно.',
      acceptButtonAction: () => {
        task().finally();
      },
      acceptButtonText: 'Удалить',
      closeButtonText: 'Отмена',
    });
  }

  activateEditButtons(): void {
    this.isEditButtonsActive = !this.isEditButtonsActive;
  }

  drop(event: CdkDragDrop<AbonentService[]>) {
    moveItemInArray(this.abonentServices.data, event.previousIndex, event.currentIndex);
  }

  saveOrdering() {
    for (let i = 0; i < this.abonentServices.data.length; i++) {
      // this.AbonentServiceRepo.update({ 'ordering': i + 1 }, this.abonentServices.data[i].id);
    }
  }

  protected readonly UIStateType = UIStateType;
}
