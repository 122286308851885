import { Component, Input, OnInit } from '@angular/core';
import {
  AttachmentCropperData,
  AttachmentCropperFieldComponent,
} from '../../../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import {
  ServiceExtensionAttributesDelegate,
  SmotreshkaCinemaAttributesFormOutput,
} from '../service-extension-attributes.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-smotreshka-cinema-ext-form',
  standalone: true,
  imports: [AttachmentCropperFieldComponent, ReactiveFormsModule],
  templateUrl: './smotreshka-cinema-ext-form.component.html',
  styleUrl: './smotreshka-cinema-ext-form.component.css',
})
export class SmotreshkaCinemaExtFormComponent implements OnInit {
  @Input() delegate?: ServiceExtensionAttributesDelegate;

  protected formGroup!: FormGroup<SmotreshkaCinemaAttributesForm>;
  constructor(protected formBuilder: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      banner: new FormControl({}, Validators.required),
      icon: new FormControl({}, Validators.required),
    }) as FormGroup<SmotreshkaCinemaAttributesForm>;

    this.formGroup.valueChanges.subscribe((newValue) => {
      console.log(`cinema update values ${newValue}`);
      if (this.formGroup.valid) {
        console.log('cinema update');
        const newAttributes = new SmotreshkaCinemaAttributesFormOutput(
          newValue.banner!,
          newValue.icon!,
        );
        this.delegate?.onChangeValue(newAttributes);
      }
    });
  }
}

interface SmotreshkaCinemaAttributesForm {
  banner: FormControl<AttachmentCropperData>;
  icon: FormControl<AttachmentCropperData>;
}
