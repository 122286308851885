<div class="card">
  <div class="card-body">
    <div class="row mb-3">
      <div class="col">
        <app-text-field [formControl]="form.controls.title" placeholder="Название кнопки" />
      </div>
      <div class="col-auto" *ngIf="removeAction">
        <button
          (click)="removeAction.emit('removeButtonClicked')"
          type="button"
          class="btn btn-link preview-btn"
        >
          <i class="ti ti-x"></i>
        </button>
      </div>
    </div>

    <app-app-link-field [formControl]="form.controls.appLink" />
  </div>
</div>
