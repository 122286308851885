export enum AvailableTariffTypeEnum {
  cctv = 'Видеонаблюдение',
  smart_intercom = 'Домофон 2.0',
  internet = 'Интернет',
  intercom = 'НЕО',
  security = 'Охрана',
  voip = 'Телефония',
  parking = 'Умные парковки',
  gate = 'Умный шлагбаум',
  daily = 'Ежедневная',
  monthly = 'Ежемесячная',
  common = 'Общего типа',
  access = 'Предоставление доступа',
  once = 'Разовая',
  installment = 'Рассрочка',
  rent = 'Аренда',
  ctv = 'CTV',
  megogo = 'Мегого',
  smotreshka = 'Смотрешка',
  vlan = 'VLAN',
  mtss = 'МТС',
  catv = 'КТВ',
}

export class AvailableTariff {
  constructor(
    public id: number,
    public billingTariffId: number,
    public name: string,
    public includedServices: string[],
    public isForPrivateHousing: boolean,
    public speedDayIn: number,
    public cost: number,
    public city: string,
  ) {}
}
