import { Attachment } from '../../../../entity/attachments/domain/attachment';

export enum AbonentServiceType {
  web_app = 'WEB_APP',
  mobile_app = 'MOBILE_APP',
}

export class AbonentService {
  constructor(
    public id: number,
    public type: string,
    public title: string,
    public short_description: string,
    public full_description: { [id: string]: any },
    public service_link_web: string | null,
    public service_link_android: string | null,
    public service_link_ios: string | null,
    public ordering: number,
    public icon: Attachment,
    public image: Attachment | null,
  ) {}

  public getBackgroundImage(): string {
    return 'background-image: url(' + this.icon.content.attributes.contentPath + ')';
  }
}
