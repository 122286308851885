<app-mobile-preview>
  <div class="story-container" style="{{ this.slide?.attachment?.getBackgroundImage() }}">
    <div class="header-and-body">
      <div class="content">
        <h2 class="header">{{ this.slide?.title }}</h2>
        <div class="body">{{ this.slide?.description }}</div>

        <div *ngFor="let button of this.slide?.actionButtons ?? []" class="btn-container">
          <button class="btn action-btn">{{ button.title }}</button>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>

<!--<div class="story-container">-->
<!--  <img class="slide" [src]="slide" />-->
<!--  <div class="container-fluid header-and-body">-->
<!--    <h2 class="header">{{ header }}</h2>-->

<!--    <div class="body">{{ body }}</div>-->

<!--    <br />-->

<!--    <div *ngFor="let button of actionButtons">-->
<!--      <button class="btn action-btn">{{ button['buttonName'] }}</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
