import { Injectable } from '@angular/core';
import { ModalParamsInterface } from './modal-component/domain/modal-params-interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public presentedModals$: BehaviorSubject<ModalParamsInterface[]> = new BehaviorSubject<
    ModalParamsInterface[]
  >([]);
  constructor() {
    console.log(`createModalService  ${new Date().getMilliseconds()}`);
  }
  createModal(params: ModalParamsInterface) {
    console.log(`create modal ${params}`);

    let list = this.presentedModals$.value;
    list.push(params);

    this.presentedModals$.next(list);
  }

  removeModal(modal: ModalParamsInterface) {
    const index: number = this.presentedModals$.value.indexOf(modal);

    if (index !== -1) {
      let list = this.presentedModals$.value;
      list.splice(index, 1);

      this.presentedModals$.next(list);
    }
  }
}
