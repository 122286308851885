<form [formGroup]="formGroup" (ngSubmit)="didSubmitForm()">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Расширение тарифов из биллинга</h3>
    </div>

    <div class="card-body">
      <app-tariff-select-field
        class="d-block"
        [isRequired]="true"
        label="Выберите тариф"
        formControlName="tariff"
      ></app-tariff-select-field>

      <hr />

      <app-text-field
        [isRequired]="true"
        class="mb-3 d-block"
        label="Название тарифа"
        placeholder="Введите название тарифа, которое будет отображаться в приложении"
        formControlName="name"
      ></app-text-field>

      <app-textarea-field
        class="mt-3 d-block"
        label="Описание тарифа"
        placeholder="Введите название тарифа, которое будет отображаться в приложении"
        formControlName="description"
      ></app-textarea-field>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <a (click)="router.navigate(['/tariff-ext-list'])" class="btn btn-link">Отмена</a>
        <button type="submit" class="btn btn-primary">
          <span
            *ngIf="sendingState == UIStateType.Loading"
            class="spinner-border spinner-border-sm me-2"
            role="status"
          ></span>
          Сохранить
        </button>
      </div>
    </div>
  </div>
</form>
