export class StatsByNewUsersResponse {
  constructor(public data: StatsByNewUsersItem[]) {}
}

export class StatsByNewUsersItem {
  constructor(
    public count: number,
    public date: Date,
    public platform: StatsByNewUsersPlatformEnum,
  ) {}
}

export enum StatsByNewUsersPlatformEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export namespace StatsByNewUsersPlatformEnum {
  export function fromString(val: string): StatsByNewUsersPlatformEnum {
    return <StatsByNewUsersPlatformEnum>(
      StatsByNewUsersPlatformEnum[val as keyof typeof StatsByNewUsersPlatformEnum]
    );
  }
}
