import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AppLinkTypeEnum } from '../../../../../entity/app-link/domain/app-link-create';
import {
  AppLinkFieldComponent,
  AppLinkFieldOutput,
} from '../app-link-field/app-link-field.component';
import { TextFieldComponent } from '../text-field/text-field.component';

@Component({
  selector: 'app-action-button-field',
  standalone: true,
  imports: [ReactiveFormsModule, TextFieldComponent, AppLinkFieldComponent, NgIf],
  templateUrl: './action-button-field.component.html',
  styleUrl: './action-button-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ActionButtonFieldComponent,
    },
  ],
})
export class ActionButtonFieldComponent implements OnInit, ControlValueAccessor {
  @Input() showRemoveButton: boolean = false;
  @Output() removeAction = new EventEmitter();

  protected form!: FormGroup<ActionButtonFormItem>;
  private onChange?: (data: ActionButtonFormItemOutput | null) => void;

  constructor(private formBuilder: NonNullableFormBuilder) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      appLink: new FormControl(null, Validators.required),
    }) as FormGroup<ActionButtonFormItem>;

    this.form.valueChanges.subscribe((t) => {
      if (this.onChange) {
        if (t.appLink && t.title) {
          let output = new ActionButtonFormItemOutput(t.title, t.appLink);
          this.onChange(output);
        } else {
          this.onChange(null);
        }
      }
    });
  }

  public registerOnChange(fn: (data: ActionButtonFormItemOutput | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public writeValue(obj: ActionButtonFormItemOutput | null): void {
    console.log(`write ActionButton ${obj}`);

    this.form.patchValue({
      title: obj?.title,
      appLink: obj?.appLink,
    });
  }
}

export interface ActionButtonFormItem {
  title: FormControl<string>;
  appLink: FormControl<AppLinkFieldOutput | null>;
}

export class ActionButtonFormItemOutput {
  static get defaultValue(): ActionButtonFormItemOutput {
    return new ActionButtonFormItemOutput('', <AppLinkFieldOutput>{
      appLinkType: AppLinkTypeEnum.HTTP_LINK,
      deepLink: null,
      httpLink: null,
    });
  }
  constructor(
    public title: string,
    public appLink: AppLinkFieldOutput,
  ) {}
}
