import { Injectable } from '@angular/core';
import { AvailableServiceCreateRequest } from '../../domain/available-service-create-request';
import { AvailableServiceCreateRequestDto } from '../dto/available-service-create-request-dto';

@Injectable({ providedIn: 'root' })
export class AvailableServiceCreateRequestMapper {
  toData(entity: AvailableServiceCreateRequest): AvailableServiceCreateRequestDto {
    return new AvailableServiceCreateRequestDto(
      entity.serviceId,
      entity.manualDeactivation,
      entity.serviceExtId,
    );
  }
}
