<app-attachment-cropper-field
  label="Загрузите иконку пакета каналов"
  [formControl]="attributedForm.controls.cover"
  [recommendationInfo]="[
    {
      title: 'Соотношение сторон',
      message: 'Рекомендуемое соотношение сторон для фото или видео - 1:1',
    },
    {
      title: 'Ограничение для фото',
      message: 'Минимальный размер - 72 x 72 \nРекомендуемый размер - 216 х 216',
    },
  ]"
></app-attachment-cropper-field>
