export class StatsBySentPushResponse {
  constructor(public data: StatsBySentPushItem[]) {}
}

export class StatsBySentPushItem {
  constructor(
    public count: number,
    public type: NotificationTypeEnum,
    public date: Date,
  ) {}
}

export enum NotificationTypeEnum {
  LOW_BALANCE = 'low_balance',
  NEW_PROMO = 'new_promo',
  NEW_TARIFF = 'new_tariff',
  NEW_SERVICE = 'new_service',
  NEW_CHAT_MESSAGE = 'new_chat_message',
  NEW_SURVEY = 'new_survey',
  REPLENISHMENT_OF_BALANCE = 'replenishment_of_balance',
  CUSTOM_PUSH = 'custom_push',
}

export namespace NotificationTypeEnum {
  export function fromString(str: string): NotificationTypeEnum {
    return <NotificationTypeEnum>NotificationTypeEnum[str as keyof typeof NotificationTypeEnum];
  }
}
