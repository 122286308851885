<form [formGroup]="formGroup" (ngSubmit)="didClickSubmit()">
  <div class="row">
    <div class="col">
      <div class="card mb-3">
        <div class="card-header">
          <h3 class="card-title">Добавление диплинка</h3>
        </div>
        <div class="card-body">
          <app-text-field
            class="mt-3"
            label="Диплинка"
            placeholder="Введите диплинк"
            formControlName="link"
            [isRequired]="true"
          ></app-text-field>

          <app-text-field
            class="mt-3"
            label="Название диплинка"
            placeholder="Введите название"
            [isRequired]="true"
            formControlName="title"
          ></app-text-field>

          <app-textarea-field
            class="mt-3 d-block"
            label="Короткое описание диплинка"
            placeholder="Введите короткое описание"
            [isRequired]="true"
            formControlName="description"
          ></app-textarea-field>
        </div>

        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/deeplink-list'])" class="btn btn-link">Отмена</a>
            <button type="submit" class="btn btn-primary">
              <span
                *ngIf="sendStatus == UIStateType.Loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
