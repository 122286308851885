import { ErrorDto } from '../dashboard/data/dto/responce/error.response.dto';

export interface DataStatus<Data> {
  map<R>(map: (data: Data) => R): DataStatus<R>;
}
export class DataStatusSuccess<Data> implements DataStatus<Data> {
  public data: Data;
  constructor(data: Data) {
    this.data = data;
  }

  map<Result>(map: (data: Data) => Result): DataStatusSuccess<Result> {
    return new DataStatusSuccess<Result>(map(this.data!));
  }
}

export class DataStatusError<Data> implements DataStatus<Data> {
  private readonly _message?: string;
  get message(): string {
    return (this._message != null ? this._message : this.messages[0].title) ?? 'Неизвестная ошибка';
  }

  messages: [ErrorDto];

  constructor(messages: [ErrorDto], message?: string) {
    this.messages = messages;
    this._message = message;
  }

  map<R>(map: (data: Data) => R): DataStatus<R> {
    return new DataStatusError<R>(this.messages, this._message);
  }
}
