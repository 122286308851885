import { Injectable } from '@angular/core';
import { OrionOfficeApiDto } from '../dto/orion-office-dto';
import { OfficeCityTypeMapper, OrionOffice } from '../../domain/orion-office';
import { OfficeBusinessHoursMapper } from './orion-office-business-hours';
import { OfficeContactMapper } from './orion-office-contact';
import { OfficeBusinessHours } from '../../domain/orion-office-business-hours';

@Injectable({ providedIn: 'root' })
export class OrionOfficeMapper {
  constructor(
    private officeBusinessHoursMapper: OfficeBusinessHoursMapper,
    private officeContactMapper: OfficeContactMapper,
  ) {}

  public toDomain(dto: OrionOfficeApiDto): OrionOffice {
    return new OrionOffice(
      dto.id,
      dto.title,
      dto.address,
      dto.latitude,
      dto.longitude,
      OfficeCityTypeMapper.fromString(dto.city),
      this.sortOfficeHours(
        dto.business_days.map((day) => {
          return this.officeBusinessHoursMapper.toDomain(day);
        }),
      ),
      dto.contacts.map((contact) => {
        return this.officeContactMapper.toDomain(contact);
      }),
    );
  }

  private sortOfficeHours(businessDays: OfficeBusinessHours[]) {
    return businessDays.sort(function sortByDay(a, b) {
      let sortedWeekdays: { [index: string]: any } = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7,
      };
      let day1 = a.weekday.toLowerCase();
      let day2 = b.weekday.toLowerCase();
      return sortedWeekdays[day1] - sortedWeekdays[day2];
    });
  }
}
