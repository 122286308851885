import { ActionButtonCreateRequest } from '../../domain/action-button-create-request';
import { ActionButtonCreateRequestDto } from '../dto/action-button-create-reqeust-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ActionButtonCreateRequestMapper {
  toData(entity: ActionButtonCreateRequest): ActionButtonCreateRequestDto {
    return new ActionButtonCreateRequestDto(entity.body, entity.appLinkId, entity.slideId);
  }
}
