<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <div class="mt-3">
    <app-push-stats-chart-bar> </app-push-stats-chart-bar>
  </div>

  <div class="mt-3">
    <app-sms-send-statistics-chart-bar> </app-sms-send-statistics-chart-bar>
  </div>

  <div class="mt-3">
    <app-users-stats-chart-line> </app-users-stats-chart-line>
  </div>
</app-page-wrapper>
