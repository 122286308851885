<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить тариф'"
    data-bs-toggle="modal"
    data-bs-target="#modal-create-tariff"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="row mb-3">
    <div class="col-12">
      <div class="input-icon">
        <input type="text" class="form-control" placeholder="Поиск по доступным тарифам..." />
        <span class="input-icon-addon">
          <i class="ti ti-search"></i>
        </span>
      </div>
    </div>
  </div>

  <app-loadable-wrapper [loadable]="pageAvailableTariffs" loadingTitle="Загрузка данных">
    <app-pagination-view
      [data]="pageAvailableTariffs"
      [perPage]="perPage"
      [pageNumber]="currentPageNumber"
    >
      <table class="table table-vcenter card-table">
        <thead>
          <tr>
            <th class="text-center">ID тарифа</th>
            <th class="text-center">Город</th>
            <th class="text-center">Тариф для частного сектора</th>
            <th class="text-center">Название</th>
            <th class="text-center">Скорость</th>
            <th class="text-center">Стоимость</th>
            <th class="text-center">Входящие услуги</th>
            <th class="w-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of pageAvailableTariffs.safeData?.data">
            <td class="text-center" style="color: #6c7a91">{{ item.billingTariffId }}</td>
            <td class="text-center">{{ item.city }}</td>
            <td class="text-center">{{ item.isForPrivateHousing ? 'Да' : 'Нет' }}</td>
            <td class="text-center">{{ item.name }}</td>
            <td class="text-center">{{ item.speedDayIn / 1000 }} Мб/с</td>
            <td class="text-center">{{ item.cost }} руб/мес.</td>
            <td class="block text-center">{{ parseIncludedServices(item.includedServices) }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-link"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="ti ti-dots-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-demo" aria-labelledby="dropdownMenuButton">
                  <button type="button" class="dropdown-item" (click)="onDelete(item.id).finally()">
                    Удалить
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </app-pagination-view>
  </app-loadable-wrapper>
</app-page-wrapper>

<div
  class="modal modal-blur fade"
  id="modal-create-tariff"
  tabindex="-1"
  style="display: none"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">Добавление доступного тарифа в список</div>
      <div class="modal-body">
        <div class="mb-3">
          <label class="form-label">Выберите тариф</label>
          <app-tariff-select-field> </app-tariff-select-field>
        </div>
        <div>
          <label class="form-check">
            <input class="form-check-input" type="checkbox" #privateHousingCheckbox />
            <span class="form-check-label">Для частного сектора</span>
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link link-secondary me-auto" data-bs-dismiss="modal">
          Отменить
        </button>

        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Добавить</button>
      </div>
    </div>
  </div>
</div>
