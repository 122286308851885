import { Component, OnInit, SkipSelf } from '@angular/core';
import {
  AbonentServiceForm,
  AbonentServiceFormComponent,
  AbonentServiceFormComponentDelegate,
} from '../../components/abonent-service-form/abonent-service-form.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { FormGroup, ɵFormGroupRawValue, ɵTypedOrUntyped } from '@angular/forms';
import { DataStatusSuccess } from '../../../../../core/network/data.status';
import { AbonentService, AbonentServiceType } from '../../domain/abonent-service';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { AbonentServiceRepository } from '../../data/repositories/abonent-service-repository';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AbonentServiceUpdateRequest } from '../../domain/abonent-service-update-request';
import { Attachment, AttachmentType } from '../../../../../entity/attachments/domain/attachment';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';

@Component({
  selector: 'app-abonent-service-update',
  standalone: true,
  imports: [
    AbonentServiceFormComponent,
    BreadcrumbsComponent,
    PageHeaderComponent,
    PageWrapperComponent,
  ],
  templateUrl: './abonent-service-update.component.html',
  styleUrl: './abonent-service-update.component.css',
})
export class AbonentServiceUpdateComponent implements OnInit {
  public abonentServiceChangeStatus$: BehaviorSubject<Loadable<AbonentService>> =
    new BehaviorSubject(new Loadable<AbonentService>());

  pageTitle: string = 'Редактирование сервиса';
  pageSubtitle: string = 'Сервисы';
  serviceId!: number;

  sendFormDelegate: AbonentServiceFormComponentDelegate = {
    didCreateForm: (form) => {
      this.bindDefaultValues(form).finally();
    },
    didSubmitForm: (form) => {
      this.onSubmit(form).finally();
    },
  };

  constructor(
    @SkipSelf() private modalService: ModalService,
    private abonentsServiceRepo: AbonentServiceRepository,
    private attachmentRepo: AttachmentRepository,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.serviceId = Number(this.route.snapshot.paramMap.get('id'));

    this.abonentServiceChangeStatus$.subscribe((value) => {
      this.handleResponseState(value);
    });
  }

  private handleResponseState(response: Loadable<AbonentService>) {
    switch (response.status) {
      case UIStateType.Success:
        this.router.navigate(['abonent-services']).then(async () => {
          this.modalService.createModal({
            type: ModalType.SUCCESS,
            message: 'Сервис успешно изменен.',
            acceptButtonAction: () => {},
          });
        });
        break;

      case UIStateType.Error:
        this.modalService.createModal({
          type: ModalType.DANGER,
          message: response.message!,
          acceptButtonAction: () => {},
        });
        break;
    }
  }

  private async bindDefaultValues(form: FormGroup<AbonentServiceForm>) {
    let result = await this.abonentsServiceRepo.getById(this.serviceId);

    if (result instanceof DataStatusSuccess) {
      let abonentService: AbonentService = result.data;

      let serviceType: AbonentServiceType = ((): AbonentServiceType => {
        switch (abonentService.type) {
          case 'mobile_app': {
            return AbonentServiceType.mobile_app;
          }
          default: {
            return AbonentServiceType.web_app;
          }
        }
      })();

      form.controls.title.setValue(abonentService.title);
      form.controls.type.setValue(serviceType);
      form.controls.service_link_ios?.setValue(abonentService.service_link_ios);
      form.controls.service_link_web?.setValue(abonentService.service_link_web);
      form.controls.service_link_android?.setValue(abonentService.service_link_android);
      form.controls.short_description.setValue(abonentService.short_description);
      form.controls.full_description.setValue(Object(abonentService.full_description));
      form.controls.icon.setValue({
        initialUrl: abonentService.icon.content.attributes.contentPath,
      });
      form.controls.image.setValue({
        initialUrl: abonentService.image?.content.attributes.contentPath,
      });
    }
  }
  private async onSubmit(form: FormGroup<AbonentServiceForm>) {
    if (form.valid) {
      this.abonentServiceChangeStatus$.next(new Loadable<AbonentService>({ kind: 'Loading' }));

      let request = await this.assembleRequest(form.getRawValue());

      if (request) {
        let response = await this.abonentsServiceRepo.update(request);

        this.abonentServiceChangeStatus$.next(Loadable.getFromDataStatus(response));
      } else {
        this.abonentServiceChangeStatus$.next(
          new Loadable<AbonentService>({
            kind: 'Error',
            message: 'Не удалось загрузить изображения на сервер',
          }),
        );
      }
    }
  }

  private async assembleRequest(
    formOutput: ɵTypedOrUntyped<AbonentServiceForm, ɵFormGroupRawValue<AbonentServiceForm>, any>,
  ): Promise<AbonentServiceUpdateRequest | undefined> {
    if (formOutput.icon?.croppedData && formOutput.image?.croppedData) {
      let attachments: { icon: Attachment; image: Attachment } = await Promise.all([
        this.attachmentRepo.createOnlyResult(
          formOutput.icon.croppedData,
          AttachmentType.ABONENT_SERVICE_ICON,
        ),
        this.attachmentRepo.createOnlyResult(
          formOutput.image.croppedData,
          AttachmentType.ABONENT_SERVICE_COVER,
        ),
      ]).then(([icon, image]) => {
        return { icon: icon, image: image };
      });

      return new AbonentServiceUpdateRequest(
        this.serviceId,
        formOutput.type!,
        formOutput.title!,
        formOutput.short_description!,
        formOutput.full_description!,
        formOutput.type == AbonentServiceType.web_app ? formOutput.service_link_web! : undefined,
        formOutput.type == AbonentServiceType.mobile_app
          ? formOutput.service_link_android!
          : undefined,
        formOutput.type == AbonentServiceType.mobile_app ? formOutput.service_link_ios! : undefined,
        attachments.icon.id,
        attachments.image.id,
      );
    }

    return undefined;
  }
}
