<app-mobile-preview>
  <div class="general-container">
    <div class="preview-header">
      <div class="preview-header-content">
        <app-preview-box
          width="100%"
          height="112px"
          [cornerRadius]="12"
          style="margin-bottom: 12px"
        ></app-preview-box>
      </div>
    </div>
    <div class="banner-content">
      <div
        *ngIf="!backgroundImage"
        class="banner-placeholder"
        style="border: 1px solid #96979c; border-radius: 12px; margin-bottom: 16px"
      >
        <app-preview-box height="123px" width="100%" [cornerRadius]="12"></app-preview-box>
      </div>

      <div *ngIf="backgroundImage" class="banner-image">
        <app-image-preview-with-button
          [preview]="backgroundImage"
          [fakeBtnText]="form.getRawValue().buttonTitle!"
        ></app-image-preview-with-button>
      </div>
      <app-preview-box height="40px" width="100%" [cornerRadius]="12"></app-preview-box>
    </div>
    <div class="preview-body">
      <app-preview-box
        width="100%"
        height="20px"
        [cornerRadius]="6"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="96px"
        [cornerRadius]="16"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="96px"
        [cornerRadius]="16"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="96px"
        [cornerRadius]="16"
        style="margin-bottom: 12px"
      ></app-preview-box>
    </div>
  </div>
</app-mobile-preview>
