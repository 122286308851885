import { Inject, Injectable } from '@angular/core';
import { TariffExtMapper } from '../mappers/tariff-ext-mapper';
import { TariffExtCreateRequestMapper } from '../mappers/tariff-ext-create-request-mapper';
import { TariffExtCreateRequest } from '../../domain/tariff-ext-create-request';
import { DataStatus } from '../../../../../core/network/data.status';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { PaginationResponseDto } from '../../../../../core/dashboard/data/dto/pagination-response.dto';
import { PaginationResponseItemMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-item-mapper';
import { TariffExtDto } from '../dto/tariff-ext-dto';

@Injectable({ providedIn: 'root' })
export class TariffExtRepository {
  constructor(
    private tariffExtMapper: TariffExtMapper,
    private tariffExtCreateRequestMapper: TariffExtCreateRequestMapper,
    private httpService: HttpClientWrapper,
    private paginationResponseItemMapper: PaginationResponseItemMapper,
    @Inject(APP_CONFIG) private applicationConfig: IEnvironment,
  ) {}

  async getAll(perPage: number, pageNumber: number) {
    let payload = {
      'pagination': {
        'per_page': perPage,
        'page_number': pageNumber,
      },
      'filters': [
        {
          'column': 'deleted_at',
          'operator': 'eq',
          'value': null,
        },
      ],
    };

    let response: DataStatus<PaginationResponseDto<TariffExtDto>> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/tariff_ext`,
      payload,
    );

    return response.map((t) => {
      return this.paginationResponseItemMapper.toDomain(t, (e) => {
        return this.tariffExtMapper.toDomain(e);
      });
    });
  }

  async create(request: TariffExtCreateRequest) {
    let response: DataStatus<TariffExtDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/tariff_ext`,
      this.tariffExtCreateRequestMapper.toData(request),
    );

    return response.map((t) => {
      return this.tariffExtMapper.toDomain(t);
    });
  }

  async update(request: TariffExtCreateRequest, id: number) {
    let response: DataStatus<TariffExtDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/tariff_ext/${id}`,
      this.tariffExtCreateRequestMapper.toData(request),
    );

    return response.map((t) => {
      this.tariffExtMapper.toDomain(t);
    });
  }

  async delete(id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/tariff_ext/${id}`,
    );
  }
}
