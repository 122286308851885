import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PaginationResponse as PR } from '../../../../../core/dashboard/domain/pagination-response';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { ServiceSelectFieldComponent } from '../../../../components/common/form-fields/service-select-field/service-select-field.component';
import { AutocompleteSearchInputComponent } from '../../../../components/common/inputs/autocomplete-search-input/autocomplete-search-input.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { PaginationTableViewComponent } from '../../../../components/pagination-table-view/pagination-table-view.component';
import {
  PaginationViewComponent,
  PaginationViewDelegate,
} from '../../../../components/pagination-view/pagination-view.component';
import { Service } from '../../../services/domain/entities/service';
import { ServiceDef } from '../../../services/domain/entities/service-def';
import { AvailableServiceRepository } from '../../data/repositories/available-service-repository';
import { AvailableService, AvailableServiceTypeEnum } from '../../domain/available-service';
import { AvailableServiceCreateRequest } from '../../domain/available-service-create-request';

@Component({
  selector: 'app-available-services',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgFor,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    PaginationTableViewComponent,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    AddButtonComponent,
    BtnListComponent,
    RouterModule,
    PaginationViewComponent,
    AutocompleteSearchInputComponent,
    ServiceSelectFieldComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './available-services.component.html',
  styleUrl: './available-services.component.css',
})
export class AvailableServiceComponent implements OnInit, PaginationViewDelegate {
  public pageAvailableServices: Loadable<PaginationResponse<AvailableService>> =
    Loadable.notRequested();

  pageTitle: string = 'Список доступных услуг';
  pageSubtitle: string = 'Тарифы и услуги';

  public perPage: number = 5;
  public pageNumber: number = 1;
  public services: Loadable<PR<ServiceDef>> | null = null;

  createServiceState: Loadable<AvailableService> = Loadable.notRequested();

  serviceForm!: FormGroup<AvailableServiceCreateForm>;
  private searchName!: string;

  constructor(
    public formBuilder: NonNullableFormBuilder,
    private availableServiceRepo: AvailableServiceRepository,
  ) {}

  ngOnInit(): void {
    this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();

    this.serviceForm = this.formBuilder.group({
      service: new FormControl(null, Validators.required),
      manualDeactivation: new FormControl(false, Validators.required),
    }) as FormGroup<AvailableServiceCreateForm>;
  }

  async getAllAvailableServicesPost(
    per_page: number,
    page_number: number,
    name: string | null = null,
  ): Promise<void> {
    if (this.pageAvailableServices.status != UIStateType.Loading) {
      this.pageAvailableServices = Loadable.loading();
      let response = await this.availableServiceRepo.findAllPost(
        per_page,
        page_number,
        name ? name : null,
      );
      this.pageAvailableServices = Loadable.getFromDataStatus(response);
    }
  }

  didChangePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    if (this.searchName) {
      this.getAllAvailableServicesPost(this.perPage, this.pageNumber, this.searchName).finally();
    } else {
      this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();
    }
  }

  getServiceType(type: string) {
    return AvailableServiceTypeEnum[type as keyof typeof AvailableServiceTypeEnum];
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.availableServiceRepo.delete(id);
      this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  async createService() {
    if (this.serviceForm.valid && this.createServiceState.status != UIStateType.Loading) {
      this.createServiceState = Loadable.loading();

      let response = await this.availableServiceRepo.create(
        new AvailableServiceCreateRequest(
          this.serviceForm.value.service!.serviceId,
          this.serviceForm.value.manualDeactivation ?? false,
        ),
      );

      this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();

      this.createServiceState = Loadable.getFromDataStatus(response);
    }
  }

  async searchAvailableService($event: any) {
    this.searchName = $event.target.value;

    if (this.searchName !== '') {
      await this.getAllAvailableServicesPost(this.perPage, 1, this.searchName);
    } else {
      await this.getAllAvailableServicesPost(this.perPage, this.pageNumber);
    }
  }

  async onQueryString($event: string) {
    let query = $event;

    this.services = Loadable.loading();

    try {
      // let result: DataStatus<PR<ServiceDef>> =
      //   await this.serviceDefRepository.findAllByLikeSearchCriteria('name', query, 1, 15);
      // this.services = Loadable.getFromDataStatus(result);
    } catch (error: any) {
      console.log(error);
    }
  }

  protected readonly UIStateType = UIStateType;
}

export interface AvailableServiceCreateForm {
  service: FormControl<Service | null>;
  manualDeactivation: FormControl<boolean>;
}
