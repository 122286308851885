import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-input',
  standalone: true,
  styleUrls: ['./file-input.component.css'],
  templateUrl: './file-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  @Output() onFileSelect: EventEmitter<any> = new EventEmitter<any>();
  public value?: Blob;
  public preview: string = '';

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: Blob): void {
    this.value = value;
  }

  registerOnChange(fn: ($event: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  selectFile($event: any): void {
    if ($event.target.files.item(0).size > 1024 * 1024 * 15) {
      var element = $event.target;
      element.classList.add('is-invalid');
      return;
    }

    var image: Blob = $event.target.files.item(0);
    this.value = image;
    this.preview = '';
    const reader = new FileReader();

    this.onFileSelect.emit($event);

    this.onChange(image);
    this.onTouch();
  }

  validateCreateFileForm($event: any) {
    var element = $event.target;
    element.classList.remove('is-invalid');
  }
}
