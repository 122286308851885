import { Routes } from '@angular/router';
import { MainComponent } from './features/main/main.component';
import { canActivate, canActivateChild } from './features/auth/permisions/access-guard';
import { ServiceExtListPageComponent } from './features/dashboard/services/view/pages/service-ext-list/service-ext-list-page.component';
import { ServiceExtAddPageComponent } from './features/dashboard/services/view/pages/service-ext-add-page/service-ext-add-page.component';
import { AbonentServicesComponent } from './features/dashboard/abonent-services/pages/abonent-services/abonent-services.component';
import { AbonentServicesCreateComponent } from './features/dashboard/abonent-services/pages/abonent-services-create/abonent-services-create.component';
import { BannerComponent } from './features/dashboard/banners/pages/banners/banners.component';
import { BannerCreateComponent } from './features/dashboard/banners/pages/banners-create/banners-create.component';
import { BannerUpdateComponent } from './features/dashboard/banners/pages/banners-update/banners-update.component';
import { ConnectionRequestsComponent } from './features/dashboard/connection-requests/components/connection-requests/connection-requests.component';
import { AvailableServiceComponent } from './features/dashboard/available-services/components/available-services/available-services.component';
import { StoriesListComponent } from './features/dashboard/stories/pages/stories-list/stories-list.component';
import { StoriesCreateComponent } from './features/dashboard/stories/pages/stories-create/stories-create.component';
import { StoriesUpdateComponent } from './features/dashboard/stories/pages/stories-update/stories-update.component';
import { AuthRedirectComponent } from './features/auth/components/auth-redirect/auth-redirect.component';
import { ServiceExtUpdatePageComponent } from './features/dashboard/services/view/pages/service-ext-update-page/service-ext-update-page.component';
import { LogoutComponent } from './features/auth/components/logout/logout.component';
import { AbonentServiceUpdateComponent } from './features/dashboard/abonent-services/pages/abonent-service-update/abonent-service-update.component';
import { StatisticsPageComponent } from './features/dashboard/statistics/statistics-page/statistics-page.component';
import { DeeplinkListComponent } from './features/dashboard/deeplinks/pages/deeplink-list/deeplink-list.component';
import { DeeplinkCreatePageComponent } from './features/dashboard/deeplinks/pages/deeplink-create-page/deeplink-create-page.component';
import { TariffExtListPageComponent } from './features/dashboard/tariffs-extension/pages/tariff-ext-list-page/tariff-ext-list-page.component';
import { TariffExtCreatePageComponent } from './features/dashboard/tariffs-extension/pages/tariff-ext-create-page/tariff-ext-create-page.component';
import { AvailableTariffComponent } from './features/dashboard/available-tariffs/pages/available-tariffs/available-tariffs.component';
import { DeeplinkUpdatePageComponent } from './features/dashboard/deeplinks/pages/deeplink-update-page/deeplink-update-page.component';
import { OrionOfficesComponent } from './features/dashboard/orion-offices/pages/orion-offices/orion-offices.component';
import { OrionOfficesUpdateComponent } from './features/dashboard/orion-offices/pages/orion-offices-update/orion-offices-update.component';
import { OrionOfficesCreateComponent } from './features/dashboard/orion-offices/pages/orion-offices-create/orion-offices-create.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [canActivate],
    canActivateChild: [canActivateChild],
    children: [
      {
        path: '',
        component: StatisticsPageComponent,
        data: { breadcrumb: { alias: 'Статистика' } },
      },
      {
        path: 'service-ext-list',
        component: ServiceExtListPageComponent,
        data: { breadcrumb: { alias: 'Расширения услуг' } },
      },

      {
        path: 'service-ext-list/add',
        component: ServiceExtAddPageComponent,
        data: { breadcrumb: { alias: 'Добавить расширение' } },
      },

      {
        path: 'service-ext-list/update/:id',
        component: ServiceExtUpdatePageComponent,
        data: { breadcrumb: { alias: 'Редактировать расширение' } },
      },

      {
        path: 'abonent-services',
        component: AbonentServicesComponent,
        data: { breadcrumb: { alias: 'Сервисы' } },
      },

      {
        path: 'abonent-services/create-abonent-service',
        component: AbonentServicesCreateComponent,
      },

      {
        path: 'abonent-services/update-abonent-service/:id',
        component: AbonentServiceUpdateComponent,
      },

      { path: 'deeplink-list', component: DeeplinkListComponent },

      { path: 'deeplink-list/create', component: DeeplinkCreatePageComponent },

      { path: 'deeplink-list/update/:id', component: DeeplinkUpdatePageComponent },

      { path: 'banners', component: BannerComponent, data: { breadcrumb: { alias: 'Баннеры' } } },

      { path: 'banners/create-banner', component: BannerCreateComponent },

      { path: 'banners/update-banner/:id', component: BannerUpdateComponent },

      // { path: 'connection-requests', component: ConnectionRequestsComponent },

      { path: 'orion-offices', component: OrionOfficesComponent },
      { path: 'orion-offices/update/:id', component: OrionOfficesUpdateComponent },
      { path: 'orion-offices/create', component: OrionOfficesCreateComponent },

      { path: 'available-services', component: AvailableServiceComponent },

      { path: 'available-tariffs', component: AvailableTariffComponent },

      { path: 'tariff-ext-list', component: TariffExtListPageComponent },
      { path: 'tariff-ext-list/create', component: TariffExtCreatePageComponent },

      {
        path: 'stories',
        component: StoriesListComponent,
      },

      {
        path: 'stories/create-story',
        component: StoriesCreateComponent,
      },

      {
        path: 'stories/update-story/:id',
        component: StoriesUpdateComponent,
      },
    ],
  },

  { path: 'auth/redirect', component: AuthRedirectComponent },

  { path: 'auth/logout', component: LogoutComponent },
];
