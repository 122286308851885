<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"> </app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-tariff-ext-form
    [sendingState]="createStatus.value.status"
    [delegate]="this"
  ></app-tariff-ext-form>
</app-page-wrapper>
