import { Component, Input, SkipSelf, ViewEncapsulation } from '@angular/core';
import { TariffExt } from '../../../domain/tariff-ext';
import { TariffExtRepository } from '../../../data/repo/tariff-ext-repository';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { NgIf } from '@angular/common';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';
import { ModalType } from '../../../../../components/common/modal-service/modal-component/domain/modal-params-interface';

@Component({
  selector: '[app-tariff-ext-list-item]',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf],
  templateUrl: './tariff-ext-list-item.component.html',
  styleUrl: './tariff-ext-list-item.component.css',
})
export class TariffExtListItemComponent {
  tariffDeleteStatus: Loadable<void> = Loadable.notRequested();

  @Input() item?: TariffExt;
  @Input() didDeleteItem?: (item: TariffExt) => void;

  constructor(
    @SkipSelf() private modalService: ModalService,
    private tariffExtRepository: TariffExtRepository,
  ) {}

  async didClickDelete() {
    if (this.tariffDeleteStatus.status != UIStateType.Loading && this.item) {
      this.tariffDeleteStatus = Loadable.loading();
      let response = await this.tariffExtRepository.delete(this.item.id);

      this.tariffDeleteStatus = Loadable.getFromDataStatus(response);

      if (this.tariffDeleteStatus.status == UIStateType.Error) {
        this.modalService.createModal({
          type: ModalType.DANGER,
          message: this.tariffDeleteStatus.message!,
        });
      }

      if (this.tariffDeleteStatus.status == UIStateType.Success && this.didDeleteItem) {
        this.didDeleteItem(this.item);
      }
    }
  }

  protected readonly UIStateType = UIStateType;
}
