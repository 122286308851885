import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';

import { BannerRepository } from '../../data/repositories/banner-repository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf } from '@angular/common';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';
import { ModalService } from '../../../../components/common/modal-service/modal-service';

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    RouterModule,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    BtnListComponent,
    AddButtonComponent,
    PaginationViewComponent,
  ],
  providers: [ModalService],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.css',
})
export class BannerComponent implements OnInit {
  public activeBanners: Loadable<PaginationResponse<Banner>> = Loadable.notRequested();
  public hiddenBanners: Loadable<PaginationResponse<Banner>> = Loadable.notRequested();

  pageTitle: string = 'Баннеры';
  pageSubtitle: string = 'Список баннеров';

  public perPage: number = 5;

  public activeBannersCurrentPageNumber: number = 1;
  public hiddenBannersCurrentPageNumber: number = 1;

  constructor(
    private bannerRepository: BannerRepository,
    private router: Router,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.getAllBanners().finally();
  }

  async getAllActiveBanners(per_page: number, page_number: number): Promise<void> {
    if (this.activeBanners.status != UIStateType.Loading) {
      this.activeBanners = Loadable.loading();

      let response = await this.bannerRepository.findAll(per_page, page_number, true);
      this.activeBanners = Loadable.getFromDataStatus(response);
    }
  }

  async getAllHiddenBanners(per_page: number, page_number: number): Promise<void> {
    if (this.hiddenBanners.status != UIStateType.Loading) {
      this.hiddenBanners = Loadable.loading();

      let response = await this.bannerRepository.findAll(per_page, page_number, false);
      this.hiddenBanners = Loadable.getFromDataStatus(response);
    }
  }

  async getAllBanners(): Promise<void> {
    await Promise.all([
      this.getAllActiveBanners(this.perPage, this.activeBannersCurrentPageNumber),
      this.getAllHiddenBanners(this.perPage, this.hiddenBannersCurrentPageNumber),
    ]).finally();
  }

  async onDelete(id: number): Promise<void> {
    await this.bannerRepository.delete(id);
    await this.getAllBanners();
  }
}
