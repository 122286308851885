import { OfficeCityType } from './orion-office';

export class OrionOfficeCreateRequest {
  constructor(
    public title: string,
    public address: string,
    public latitude: number,
    public longitude: number,
    public city: OfficeCityType,
  ) {}
}
