<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-banner-form
    [delegate]="this"
    [formState]="bannersUpdateState.value.status"
  ></app-banner-form>
</app-page-wrapper>
