import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Service } from '../../../../dashboard/services/domain/entities/service';
import { BillingTariffRepository } from '../../../../../entity/billing-tariff/data/repositories/BillingTariffRepository';
import { BillingTariff } from '../../../../../entity/billing-tariff/domain/billing-tariff';

@Component({
  selector: 'app-tariff-select-field',
  standalone: true,
  imports: [NgForOf, NgIf, ReactiveFormsModule, FormsModule, NgClass],
  templateUrl: './tariff-select-field.component.html',
  styleUrl: './tariff-select-field.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TariffSelectFieldComponent,
    },
  ],
})
export class TariffSelectFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private static id: number = 0;

  componentId = `service-select-field-${TariffSelectFieldComponent.id++}`;

  protected tariffs: Loadable<BillingTariff[]> = new Loadable<BillingTariff[]>({
    kind: 'NotRequested',
  });
  protected isFocused: boolean = false;

  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';

  private onChange?: (value: BillingTariff | null) => void;
  private onTouched!: () => void;

  private _selectedTariff: BillingTariff | null = null;

  set selectedTariff(newValue: BillingTariff | null) {
    this._selectedTariff = newValue;

    if (this.onChange) {
      this.onChange(newValue);
    }
  }
  get selectedTariff(): BillingTariff | null {
    return this._selectedTariff;
  }

  protected searchText$ = new BehaviorSubject<string>('');

  constructor(private billingTariffRepository: BillingTariffRepository) {}

  didChangeFocus(isFocused: boolean) {
    this.isFocused = isFocused;
  }
  ngOnInit() {
    this.searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value.length >= 2) {
        this.searchTariffs(value).finally();
        console.log(`str ${value}`);
      } else {
        this.tariffs = new Loadable<BillingTariff[]>({ kind: 'NotRequested' });
      }
    });
  }

  ngOnDestroy() {
    this.searchText$.unsubscribe();
  }

  private async searchTariffs(value: string) {
    this.tariffs = new Loadable<BillingTariff[]>({ kind: 'Loading' });

    let result = await this.billingTariffRepository.findAll(value, 1, 20);
    this.tariffs = Loadable.getFromDataStatus(
      result.map((data) => {
        return data.data;
      }),
    );
  }

  inputOnChange(str: string) {
    this.searchText$.next(str);
  }

  registerOnChange(fn: (value: BillingTariff | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  didSelectService(tariff: BillingTariff) {
    this.selectedTariff = tariff;
  }

  clearTariff() {
    this.selectedTariff = null;
  }

  writeValue(obj: Service | null): void {}

  protected readonly UIStateType = UIStateType;
}
