import { StatsBySentSmsResponseDto } from '../data/stats-by-sent-sms-response-dto';
import { StatsBySentSmsItem, StatsBySentSmsResponse } from '../domain/stats-by-sent-sms-response';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StatsBySentSmsResponseMapper {
  toDomain(dto: StatsBySentSmsResponseDto): StatsBySentSmsResponse {
    return new StatsBySentSmsResponse(
      dto.data.map((t) => {
        let date = new Date(t.date);

        return new StatsBySentSmsItem(t.count, date);
      }),
    );
  }
}
