import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexDataLabels,
  ApexPlotOptions,
  ChartComponent,
} from 'ng-apexcharts';
import { StatisticsRepository } from '../../../repository/statistics-repository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { StatsBySentPushItem } from '../../../domain/stats-by-sent-push-response';
import { StatsContainerComponent } from '../stats-container/stats-container.component';

@Component({
  selector: 'app-push-stats-chart-bar',
  standalone: true,
  imports: [ChartComponent, StatsContainerComponent],
  templateUrl: './push-stats-chart-bar.component.html',
  styleUrl: './push-stats-chart-bar.component.css',
})
export class PushStatsChartBarComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: ChartOptions;

  dateFrom: Date = new Date(Date.now());
  dateUntil: Date = new Date(Date.now());

  pageTitle: string = 'Главная';
  pageSubtitle: string = 'Статистика по модулям приложения';

  amount?: number;

  protected plotOptions: ApexPlotOptions = {
    bar: {
      dataLabels: {
        position: 'top',
      },
    },
  };

  protected dataLabels: ApexDataLabels = {
    enabled: true,
    formatter: function (val: string) {
      return val;
    },
    offsetY: -20,
    style: {
      fontSize: '14px',
      colors: ['#304758'],
    },
  };

  series: ApexAxisChartSeries = [
    {
      name: 'Кол-во',
      data: [],
    },
  ];

  constructor(private statisticsRepository: StatisticsRepository) {
    this.chartOptions = {
      xaxis: {
        type: 'datetime',

        labels: {
          show: true,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
        axisTicks: {
          show: false,
        },
        position: 'bottom',
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    };
  }

  ngOnInit() {
    this.dateFrom.setMonth(this.dateFrom.getMonth() - 1);

    this.statisticsRepository.getStatsBySentPush(this.dateFrom, this.dateUntil).then((value) => {
      let data = Loadable.getFromDataStatus(value).data.data;
      let periodMap = this.getMapForPeriod(this.dateFrom, this.dateUntil);

      data.forEach((t) => {
        periodMap.get(t.date.getTime())?.push(t);
      });

      let periodWithCount: Map<number, number> = new Map<number, number>();

      periodMap.forEach((v, k) => {
        let count = 0;
        v.forEach((item) => {
          count += item.count;
        });
        periodWithCount.set(k, count);
      });

      let arrNumbers: { x: any; y: any }[] = [];

      periodWithCount.forEach((v, k) => {
        arrNumbers.push({ x: k, y: v });
      });

      this.series = [
        {
          name: 'Кол-во',
          data: arrNumbers,
        },
      ];

      let allCount = 0;
      arrNumbers.forEach((t) => {
        allCount += t.y;
      });

      this.amount = allCount;
    });
  }

  private getMapForPeriod(dateFrom: Date, dateUntil: Date) {
    let map: Map<number, StatsBySentPushItem[]> = new Map<number, StatsBySentPushItem[]>();

    let lastDate = dateFrom;
    lastDate.setUTCHours(0, 0, 0, 0);

    while (true) {
      map.set(lastDate.getTime(), []);

      lastDate.setDate(lastDate.getDate() + 1);

      if (lastDate > dateUntil) {
        break;
      }
    }

    return map;
  }
}

interface PeriodByCount {
  [key: number]: number;
}
interface DatesByUserMap {
  [key: number]: StatsBySentPushItem[];
}
export interface ChartOptions {
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
}
