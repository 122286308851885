import { Injectable } from '@angular/core';
import {
  AttachmentAttributes,
  AttachmentContent,
  AttachmentContentType,
  BaseAttachmentAttribute,
  ImageAttachmentAttribute,
} from '../../domain/attachment';
import { AttributesApiDto, ContentApiDto, ImageAttributesApiDto } from '../attachment-api-dto';

@Injectable({ providedIn: 'root' })
export class AttachmentContentMapper {
  toDomain(content: ContentApiDto): AttachmentContent {
    let type = AttachmentContentType.fromString(content.type);
    return new AttachmentContent(this.getAttributes(content.attributes, type), type);
  }

  private getAttributes(attr: AttributesApiDto, type: AttachmentContentType): AttachmentAttributes {
    switch (type) {
      case AttachmentContentType.PHOTO:
        let imageAttrDto = attr as ImageAttributesApiDto;
        return new ImageAttachmentAttribute(
          imageAttrDto.filename,
          imageAttrDto.content_type,
          imageAttrDto.content_path,
          imageAttrDto.content_path_compressed,
        );
      default:
        return new BaseAttachmentAttribute(attr.filename, attr.content_type, attr.content_path);
    }
  }
}
