import { Component, OnInit } from '@angular/core';
import { AvailableTariff, AvailableTariffTypeEnum } from '../../domain/available-tariff';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AvailableTariffRepository } from '../../data/repositories/AvailableTariffRepository';
import { NgFor, NgForOf, NgIf } from '@angular/common';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { RouterModule } from '@angular/router';
import { PaginationTableViewComponent } from '../../../../components/pagination-table-view/pagination-table-view.component';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';
import { AutocompleteSearchInputComponent } from '../../../../components/common/inputs/autocomplete-search-input/autocomplete-search-input.component';
import { BillingTariffRepository } from '../../../../../entity/billing-tariff/data/repositories/BillingTariffRepository';
import { BillingTariffListItemComponent } from '../../../../components/common/inputs/list-item-components/billing-tariff-list-item/billing-tariff-list-item.component';
import { TariffSelectFieldComponent } from '../../../../components/common/form-fields/tariff-select-field/tariff-select-field.component';

@Component({
  selector: 'app-available-tariffs',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgFor,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    PaginationTableViewComponent,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    AddButtonComponent,
    BtnListComponent,
    RouterModule,
    PaginationViewComponent,
    AutocompleteSearchInputComponent,
    TariffSelectFieldComponent,
  ],
  templateUrl: './available-tariffs.component.html',
  styleUrl: './available-tariffs.component.css',
})
export class AvailableTariffComponent implements OnInit {
  public pageAvailableTariffs: Loadable<PaginationResponse<AvailableTariff>> = new Loadable({
    kind: 'NotRequested',
  });

  pageTitle: string = 'Список доступных тарифов';
  pageSubtitle: string = 'Тарифы и услуги';

  public perPage: number = 30;
  public currentPageNumber: number = 1;
  public listItem = BillingTariffListItemComponent;

  constructor(
    private availableTariffRepository: AvailableTariffRepository,
    private tariffInfoRepository: BillingTariffRepository,
  ) {}

  ngOnInit(): void {
    this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
  }

  async getAllAvailableTariffsPost(perPage: number, pageNumber: number): Promise<void> {
    if (this.pageAvailableTariffs.status != UIStateType.Loading) {
      this.pageAvailableTariffs = Loadable.loading();
      let response = await this.availableTariffRepository.findAllPost(perPage, pageNumber);
      this.pageAvailableTariffs = Loadable.getFromDataStatus(response);
    }
  }

  changePage(pageNumber: number) {
    this.currentPageNumber = pageNumber;
    this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
  }

  getTariffType(type: string) {
    return AvailableTariffTypeEnum[type as keyof typeof AvailableTariffTypeEnum];
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.availableTariffRepository.delete(id);
      this.getAllAvailableTariffsPost(this.perPage, this.currentPageNumber).finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  parseIncludedServices(services: string[]) {
    let result: string[] = [];

    for (let service of services) {
      if (service == '24htv') {
        result.push('24часаТВ');
      } else {
        result.push(AvailableTariffTypeEnum[service as keyof typeof AvailableTariffTypeEnum]);
      }
    }

    return result.join(', ');
  }

  protected readonly UIStateType = UIStateType;
}
