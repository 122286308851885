import { Component, OnInit, SkipSelf } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ɵFormGroupRawValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AbonentServiceRepository } from '../../data/repositories/abonent-service-repository';
import { AbonentService, AbonentServiceType } from '../../domain/abonent-service';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { FormComponentComponent } from '../../../../components/form-component/form-component.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { MobileAbonentServicesDetailPreviewComponent } from '../../components/mobile-abonent-services-detail-preview/mobile-abonent-services-detail-preview.component';
import { AttachmentCropperFieldComponent } from '../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import { TextFieldComponent } from '../../../../components/common/form-fields/text-field/text-field.component';
import { TextareaFieldComponent } from '../../../../components/common/form-fields/textarea-field/textarea-field.component';
import { SelectFieldComponent } from '../../../../components/common/form-fields/select-field/select-field.component';
import { EditorJsFieldComponent } from '../../../../components/common/form-fields/editor-js-field/editor-js-field.component';
import { NgIf } from '@angular/common';
import { AbonentServiceCreateRequest } from '../../domain/abonent-service-create-request';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { BehaviorSubject } from 'rxjs';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import {
  AbonentServiceForm,
  AbonentServiceFormComponent,
  AbonentServiceFormComponentDelegate,
} from '../../components/abonent-service-form/abonent-service-form.component';
import { DatepickerFieldComponent } from '../../../../components/common/form-fields/datepicker-field/datepicker-field.component';

@Component({
  selector: 'app-abonent-services-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    DragAndDropFileUploadComponent,
    FormComponentComponent,
    FormsModule,
    ImageCropperComponent,
    MobileAbonentServicesDetailPreviewComponent,
    ReactiveFormsModule,
    AttachmentCropperFieldComponent,
    TextFieldComponent,
    TextareaFieldComponent,
    SelectFieldComponent,
    EditorJsFieldComponent,
    NgIf,
    AbonentServiceFormComponent,
    DatepickerFieldComponent,
  ],
  providers: [ModalService],
  templateUrl: './abonent-services-create.component.html',
  styleUrl: './abonent-services-create.component.css',
})
export class AbonentServicesCreateComponent implements OnInit {
  public abonentServiceChangeStatus$: BehaviorSubject<Loadable<AbonentService>> =
    new BehaviorSubject(new Loadable<AbonentService>());

  pageTitle: string = 'Добавление сервиса';
  pageSubtitle: string = 'Сервисы';

  sendFormDelegate: AbonentServiceFormComponentDelegate = {
    didSubmitForm: (form) => {
      this.onSubmit(form).finally();
    },
  };

  constructor(
    @SkipSelf() private modalService: ModalService,
    private abonentsServiceRepo: AbonentServiceRepository,
    private attachmentRepo: AttachmentRepository,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.abonentServiceChangeStatus$.subscribe((data) => {
      this.handleLoadableState(data);
    });
  }

  private handleLoadableState(data: Loadable<AbonentService>) {
    switch (data.status) {
      case UIStateType.Success:
        this.router.navigate(['abonent-services']).then(async () => {
          this.modalService.createModal({
            type: ModalType.SUCCESS,
            message: 'Сервис успешно создан.',
            acceptButtonAction: () => {},
          });
        });

        break;

      case UIStateType.Error:
        this.modalService.createModal({
          type: ModalType.SUCCESS,
          message: data.message!,
          acceptButtonAction: () => {},
        });
        break;
    }
  }

  async onSubmit(form: FormGroup<AbonentServiceForm>) {
    if (form.valid) {
      this.abonentServiceChangeStatus$.next(new Loadable<AbonentService>({ kind: 'Loading' }));

      let result = await this.tryAssembleResult(form.getRawValue()).then((result) => {
        return result;
      });

      if (result) {
        let response = await this.abonentsServiceRepo.create(result);

        this.abonentServiceChangeStatus$.next(Loadable.getFromDataStatus(response));
      } else {
        this.abonentServiceChangeStatus$.next(
          new Loadable<AbonentService>({
            kind: 'Error',
            message: 'Не удалось загрузить изображения на сервер',
          }),
        );
      }
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля',
      });
    }
  }
  private async tryAssembleResult(
    formOutput: ɵTypedOrUntyped<AbonentServiceForm, ɵFormGroupRawValue<AbonentServiceForm>, any>,
  ): Promise<AbonentServiceCreateRequest | undefined> {
    if (formOutput.icon?.croppedData && formOutput.image?.croppedData) {
      let attachments: { icon: Attachment; image: Attachment } = await Promise.all([
        this.attachmentRepo.createOnlyResult(formOutput.icon.croppedData),
        this.attachmentRepo.createOnlyResult(formOutput.image.croppedData),
      ]).then(([icon, image]) => {
        return { icon: icon, image: image };
      });

      const result = new AbonentServiceCreateRequest(
        formOutput.type!,
        formOutput.title!,
        formOutput.short_description!,
        formOutput.full_description!,
        formOutput.type == AbonentServiceType.web_app ? formOutput.service_link_web! : undefined,
        formOutput.type == AbonentServiceType.mobile_app
          ? formOutput.service_link_android!
          : undefined,
        formOutput.type == AbonentServiceType.mobile_app ? formOutput.service_link_ios! : undefined,
        attachments.icon.id,
        attachments.image.id,
      );

      return result;
    }

    return undefined;
  }

  protected readonly UIStateType = UIStateType;
}
