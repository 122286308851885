import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Component, OnInit, SkipSelf } from '@angular/core';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ɵFormGroupRawValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FormComponentComponent } from '../../../../components/form-component/form-component.component';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';
import { NgxMaskDirective } from 'ngx-mask';
import { MobileOrionOfficePreviewComponent } from '../components/mobile-orion-office-preview/mobile-orion-office-preview.component';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { OrionOffice } from '../../domain/orion-office';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { OrionOfficeRepository } from '../../data/repositories/orion-office-repository';
import { OrionOfficesContactsRepository } from '../../data/repositories/orion-offices-contacts-repository';
import { OrionOfficeBusinessHoursRepository } from '../../data/repositories/orion-office-business-hours-repository';
import {
  OrionOfficesCreateForm,
  OrionOfficesFormComponent,
  OrionOfficesFormDelegate,
} from '../components/orion-offices-form/orion-offices-form.component';
import { OrionOfficeCreateRequest } from '../../domain/orion-office-create-request';
import { Task } from '../../../../../core/utils/task';
import { OrionOfficeBusinessHoursCreate } from '../../domain/orion-office-business-hours-create';
import { OrionOfficeContactCreateRequest } from '../../domain/orion-office-contact-create-request';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-orion-offices-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
    FormComponentComponent,
    AngularYandexMapsModule,
    NgxMaskDirective,
    MobileOrionOfficePreviewComponent,
    OrionOfficesFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './orion-offices-create.component.html',
  styleUrl: './orion-offices-create.component.css',
})
export class OrionOfficesCreateComponent implements OnInit, OrionOfficesFormDelegate {
  pageTitle: string = 'Добавление офиса';
  pageSubtitle: string = 'Офисы';

  public createStatus: BehaviorSubject<Loadable<OrionOffice>> = new BehaviorSubject(
    Loadable.notRequested(),
  );

  constructor(
    private formBuilder: FormBuilder,
    private orionOfficeRepo: OrionOfficeRepository,
    private officeContactsRepo: OrionOfficesContactsRepository,
    private officeBusinessHoursRepo: OrionOfficeBusinessHoursRepository,
    public router: Router,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.createStatus.subscribe((t) => {
      switch (t.status) {
        case UIStateType.Success:
          new Task(async () => {
            await this.router.navigate(['/orion-offices']);
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: 'Офис успешно создан.',
            });
          });
          break;

        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: t.message!,
          });
      }
    });
  }

  didCreateForm(form: FormGroup<OrionOfficesCreateForm>): void {}

  didSubmitForm(form: FormGroup<OrionOfficesCreateForm>): void {
    console.log(form.getRawValue());

    if (form.valid && this.createStatus.value.status != UIStateType.Loading) {
      new Task(async () => {
        this.createStatus.next(Loadable.loading());

        let request = this.bindFormValue(form.getRawValue());

        let response = Loadable.getFromDataStatus(await this.orionOfficeRepo.create(request));

        if (response.status == UIStateType.Success) {
          let promises: Promise<any>[] = [];

          form.getRawValue().businessDays.forEach((t) => {
            let hoursRequest = new OrionOfficeBusinessHoursCreate(
              response.data.id!,
              t.weekDay,
              t.isDayOff ? null : t.openTime,
              t.isDayOff ? null : t.closeTime,
              t.isDayOff,
            );

            promises.push(this.officeBusinessHoursRepo.create(hoursRequest));
          });

          form.getRawValue().contacts.forEach((t) => {
            let contactRequest = new OrionOfficeContactCreateRequest(response.data.id!, t.phone);

            promises.push(this.officeContactsRepo.create(contactRequest));
          });

          await Promise.all(promises);
        }

        this.createStatus.next(response);
      });
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля',
      });
    }
  }

  private bindFormValue(
    value: ɵTypedOrUntyped<OrionOfficesCreateForm, ɵFormGroupRawValue<OrionOfficesCreateForm>, any>,
  ): OrionOfficeCreateRequest {
    return new OrionOfficeCreateRequest(
      value.title,
      value.address,
      value.latitude!,
      value.longitude!,
      value.city,
    );
  }
}
