export interface ModalParamsInterface {
  type: ModalType;

  message: string;
  header?: string;

  acceptButtonAction?: () => void;
  closeButtonAction?: () => void;

  acceptButtonText?: string;
  closeButtonText?: string;
}

export enum ModalType {
  DANGER,
  SUCCESS,
}
