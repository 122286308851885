<div class="card">
  <ng-content [ngTemplateOutlet]="dataTemplate"></ng-content>
</div>
<div class="d-flex mt-3">
  <ul class="pagination ms-auto">
    <li class="page-item" [class.disabled]="pageNumber == 1">
      <button (click)="changePage(pageNumber - 1)" class="page-link" style="white-space: nowrap">
        <i class="ti ti-chevron-left"></i>
      </button>
    </li>
    <li class="page-item" *ngFor="let x of pageArray">
      <button [class.active]="x == pageNumber" (click)="changePage(x)" class="page-link">
        {{ x }}
      </button>
    </li>
    <li class="page-item" [class.disabled]="pageNumber == data?.safeData?.pages">
      <button class="page-link" (click)="changePage(pageNumber + 1)" style="white-space: nowrap">
        <i class="ti ti-chevron-right"></i>
      </button>
    </li>
  </ul>
</div>
