import { Injectable } from '@angular/core';
import { StoriesCreateRequest } from '../../domain/stories-create-request';
import { StoriesCreateRequestDto } from '../dto/stories-create-request-dto';

@Injectable({ providedIn: 'root' })
export class StoriesCreateRequestMapper {
  toData(entity: StoriesCreateRequest): StoriesCreateRequestDto {
    return new StoriesCreateRequestDto(entity.name, entity.attachmentId, entity.annotation);
  }
}
