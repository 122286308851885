import { StatsBySentPushResponseDto } from '../data/stats-by-sent-push-response-dto';
import {
  NotificationTypeEnum,
  StatsBySentPushItem,
  StatsBySentPushResponse,
} from '../domain/stats-by-sent-push-response';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StatsBySentPushResponseMapper {
  toDomain(dto: StatsBySentPushResponseDto): StatsBySentPushResponse {
    return new StatsBySentPushResponse(
      dto.data.map((it) => {
        let date = new Date(it.date);

        return new StatsBySentPushItem(it.count, NotificationTypeEnum.fromString(it.type), date);
      }),
    );
  }
}
