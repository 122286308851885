import { Deeplink } from '../../../features/dashboard/deeplinks/domain/deeplink';
import { AppLinkTypeEnum } from './app-link-create';

export class AppLink {
  constructor(
    public id: number,
    public type: AppLinkTypeEnum,
    public deeplinkId: number | null,
    public httpLink: string | null,
    public deeplink: Deeplink | null,
  ) {}

  public getLink(): string | null {
    switch (this.type) {
      case AppLinkTypeEnum.DEEPLINK:
        return this.deeplink?.link ?? null;
      case AppLinkTypeEnum.HTTP_LINK:
        return this.httpLink;
    }
  }
}
