import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { ImagePreviewWithButtonComponent } from '../../../../../components/image-preview-with-button/image-preview-with-button.component';
import { FormGroup } from '@angular/forms';
import { PhoneOutputPipe } from '../../../../../components/common/pipes/phone-output-pipe';
import { OrionOfficesCreateForm } from '../orion-offices-form/orion-offices-form.component';
import { WeekdayLocalized } from '../../orion-offices/orion-offices.component';

@Component({
  selector: 'app-mobile-orion-office-preview',
  standalone: true,
  imports: [
    NgFor,
    MobilePreviewComponent,
    PreviewBoxComponent,
    NgIf,
    ImagePreviewWithButtonComponent,
    PhoneOutputPipe,
  ],
  templateUrl: './mobile-orion-office-preview.component.html',
  styleUrl: './mobile-orion-office-preview.component.css',
})
export class MobileOrionOfficePreviewComponent {
  @Input() form!: FormGroup<OrionOfficesCreateForm>;

  protected readonly WeekdayLocalized = WeekdayLocalized;
}
