<div class="page-header d-print-none">
  <div class="container-xxl">
    <div class="row g-2 align-items-center">
      <div class="col">
        <!-- Page pre-title -->
        <div class="page-pretitle" *ngIf="subtitle != null">
          {{ subtitle }}
        </div>
        <h2 class="page-title">
          {{ title }}
        </h2>
      </div>
      <!-- Page title actions -->
      <div class="col-auto ms-auto d-print-none">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
