import { Component, OnInit } from '@angular/core';
import { DeeplinkRepository } from '../../data/repo/deeplink-repository';
import { Loadable } from '../../../../../core/utils/wrappers/loadable';
import { Deeplink } from '../../domain/deeplink';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { PaginationResponseDto } from '../../../../../core/dashboard/data/dto/pagination-response.dto';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf } from '@angular/common';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import {
  PaginationViewComponent,
  PaginationViewDelegate,
} from '../../../../components/pagination-view/pagination-view.component';
import { TariffExtListItemComponent } from '../../../tariffs-extension/pages/tariff-ext-list-page/tariff-ext-list-item/tariff-ext-list-item.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeeplinkListItemComponent } from './deeplink-list-item/deeplink-list-item.component';

@Component({
  selector: 'app-deeplink-list',
  standalone: true,
  imports: [
    LoadableWrapperComponent,
    AddButtonComponent,
    BreadcrumbsComponent,
    NgForOf,
    PageHeaderComponent,
    PageWrapperComponent,
    PaginationViewComponent,
    TariffExtListItemComponent,
    DeeplinkListItemComponent,
  ],
  templateUrl: './deeplink-list.component.html',
  styleUrl: './deeplink-list.component.css',
})
export class DeeplinkListComponent implements OnInit, PaginationViewDelegate {
  deeplinkList: Loadable<PaginationResponseDto<Deeplink>> = new Loadable({ kind: 'NotRequested' });

  pageTitle = 'Список диплинков';
  pageSubtitle = 'Диплинки';

  perPage = 30;
  pageNumber = 1;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private deeplinkRepository: DeeplinkRepository,
  ) {}

  ngOnInit() {
    let queryPage = this.route.snapshot.queryParams['page'];

    if (queryPage) {
      this.pageNumber = queryPage;
    }

    this.fetchDeeplinks().finally();
  }

  protected async fetchDeeplinks() {
    this.deeplinkList = Loadable.loading();
    let data = await this.deeplinkRepository.getAll(this.perPage, this.pageNumber);
    this.deeplinkList = Loadable.getFromDataStatus(data);
  }

  didChangePage(newPage: number): void {
    this.pageNumber = newPage;

    const queryParams: Params = { page: newPage };

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      })
      .then((r) => {});

    this.fetchDeeplinks().finally();
  }

  didDeleteItem() {
    console.log('deleted');
  }
}
