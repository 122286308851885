import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import { easepick, LockPlugin, TimePlugin } from '@easepick/bundle';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-datepicker-field',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatSuffix,
    MatDatepickerInput,
    FormsModule,
  ],

  templateUrl: './datepicker-field.component.html',
  styleUrl: './datepicker-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatepickerFieldComponent,
    },
  ],
})
export class DatepickerFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  private static id: number = 0;
  componentId = `datepicker-field-${DatepickerFieldComponent.id++}`;

  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() datepickerType: DatepickerType = DatepickerType.DATE;

  @Input() minDate?: Date;
  @Input() maxDate?: Date;

  @ViewChild('dateInput') input!: ElementRef<HTMLInputElement>;

  private picker!: easepick.Core;
  private onChangeAction?: (date: Date) => void;
  ngOnInit() {
    console.log(this.input);
  }

  ngAfterViewInit() {
    let plugins: any[] = [LockPlugin];

    if (this.datepickerType == DatepickerType.DATETIME) {
      plugins.push(TimePlugin);
    }

    this.picker = new easepick.create({
      element: this.input.nativeElement,
      lang: 'ru-RU',
      autoApply: true,
      css: ['/assets/dist/css/picker-styles.css'],
      locale: {
        apply: 'Применить',
        cancel: 'Отмена',
      },
      LockPlugin: {
        minDate: this.minDate,
        maxDate: this.maxDate,
      },
      TimePlugin: {
        stepMinutes: 1,
        stepSeconds: 1,
      },
      plugins: plugins,
    });

    this.picker.on('select', (e) => {
      if (this.onChangeAction) {
        this.onChangeAction(this.picker.getDate().toJSDate());
      }
    });
  }

  registerOnChange(fn: (date: Date) => void): void {
    this.onChangeAction = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: Date): void {
    this.picker.setDate(obj);
  }
}

export enum DatepickerType {
  DATE,
  DATETIME,
}
