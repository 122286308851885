import { ActionButtonDto } from '../dto/action-button-dto';
import { ActionButton } from '../../domain/action-button';
import { AppLinkMapper } from '../../../app-link/data/mapper/app-link-mapper';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ActionButtonMapper {
  constructor(private appLinkMapper: AppLinkMapper) {}
  toDomain(dto: ActionButtonDto): ActionButton {
    return new ActionButton(
      dto.id,
      dto.body,
      dto.app_link_id,
      this.appLinkMapper.toDomain(dto.app_link),
    );
  }
}
