import { Attachment, AttachmentContent } from '../../../../entity/attachments/domain/attachment';
import { AdminUser } from '../../../../entity/admin-user/domain/admin-user';
import { ActionButton } from '../../../../entity/action-button/domain/action-button';

export class StorySlide {
  constructor(
    public id: number,
    public header: string,
    public body: string,
    public storyId: number,
    public attachmentId: number,
    public attachment: Attachment,
    public actionButtons: Array<ActionButton>,
  ) {}
}

export class Story {
  constructor(
    public id: number,
    public name: string,
    public createdAt: Date,
    public updatedAt: Date,
    public createdBy: Date,
    public updatedBy: Date | null,
    public preview: Attachment,
    public analyticsTargetName: string,
    public storyViews: number,
    public storyLikes: number,
    public annotation: string | null,
    public slides: Array<StorySlide>,
    public isActive: boolean,
    public deactivatedAt: Date,
    public adminCreator: AdminUser,
    public adminEditor: AdminUser | null,
  ) {}
}
