<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">История</h3>
        </div>

        <div class="card-body">
          <app-text-field
            class="mb-3"
            label="Название истории"
            [isRequired]="true"
            formControlName="title"
            placeholder="Введите название"
          ></app-text-field>

          <div class="story-content">
            <div class="story-tabs">
              <div
                class="story-tab"
                [ngClass]="{ active: i == activeSlideIndex }"
                *ngFor="let group of this.form.controls.slides.controls; let i = index"
                (click)="changeSelectedIndex(i)"
                style="background-size: cover; {{ backgroundImageForSlide(i) }}"
              >
                <i
                  class="ti ti-trash remove-slide"
                  *ngIf="i != 0"
                  (click)="removeSlideAtIndex(i)"
                ></i>
              </div>

              <div
                class="story-tab"
                *ngIf="this.form.controls.slides.length < 6"
                (click)="addSlide()"
              >
                <i class="ti ti-plus slide-add"></i>
              </div>
            </div>

            <div
              formArrayName="slides"
              [ngClass]="{ 'd-none': i != activeSlideIndex }"
              *ngFor="let item of this.form.controls.slides.controls; let i = index"
            >
              <app-story-slide-form [formControl]="item" />
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          <h3 class="card-title">Превью</h3>
        </div>
        <div class="card-body">
          <app-attachment-cropper-field
            class="mb-3 d-block"
            [aspectRatio]="1"
            formControlName="preview"
            [recommendationInfo]="[
              {
                title: 'Соотношение сторон',
                message: 'Рекомендуемое соотношение сторон для фото или видео - 1:1',
              },
              {
                title: 'Ограничение для фото',
                message: 'Минимальный размер - 80 х 80 Рекомендуемый размер - 240 х 240',
              },
            ]"
          />

          <app-textarea-field
            class="mb-3 d-block"
            placeholder="Введите описание (текст будет отображаться в открытой истории)"
            label="Описание превью"
            formControlName="annotation"
          />
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card sticky-top">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр</h3>
        </div>
        <div class="card-body">
          <div class="card-tabs">
            <!-- Cards navigation -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  href="#tab-top-1"
                  class="nav-link active"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  role="tab"
                  >История</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  href="#tab-top-2"
                  class="nav-link"
                  data-bs-toggle="tab"
                  aria-selected="false"
                  role="tab"
                  tabindex="-1"
                  >Превью</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div id="tab-top-1" class="card tab-pane active show" role="tabpanel">
                <div class="card-body">
                  <app-mobile-stories-preview
                    [slide]="this.form.controls.slides.controls[activeSlideIndex].value"
                  ></app-mobile-stories-preview>
                </div>
              </div>
              <div id="tab-top-2" class="card tab-pane" role="tabpanel">
                <div class="card-body">
                  <app-mobile-story-preview-preview
                    [form]="form"
                  ></app-mobile-story-preview-preview>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/stories'])" class="btn btn-link"> Отмена </a>
            <button
              (click)="didClickSubmit()"
              type="submit"
              class="btn btn-primary"
              [disabled]="!form.valid"
            >
              <span
                *ngIf="this.formState == UIStateType.Loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Опубликовать
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
