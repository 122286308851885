import { ServiceExtensionType } from './service-type';
import { OutputData } from '@editorjs/editorjs';

export class ServiceExtensionCreateRequest {
  constructor(
    public title: string,
    public description: string,
    public serviceId: number,
    public serviceType: ServiceExtensionType,
    public attributes: ServiceExtensionCreateAttributes,
  ) {}
}

export interface ServiceExtensionCreateAttributes {}

export class CATVPacketCreateAttributes implements ServiceExtensionCreateAttributes {
  constructor(public numberOfChannels: number) {}
}

export class SmotreshkaCinemaCreateAttributes implements ServiceExtensionCreateAttributes {
  constructor(
    public iconId: number,
    public bannerId: number,
  ) {}
}

export class SmotreshkaPacketCreateAttributes implements ServiceExtensionCreateAttributes {
  constructor(
    public numberOfChannels: number | null,
    public packId: number | null,
    public coverId: number,
  ) {}
}

export class GeneralCreateAttributes implements ServiceExtensionCreateAttributes {
  constructor(public text: OutputData | null) {}
}
