import { Injectable } from '@angular/core';
import { DeeplinkApiDto } from '../data/dto/deeplink-api-dto';
import { Deeplink } from '../domain/deeplink';

@Injectable({ providedIn: 'root' })
export class DeeplinkMapper {
  constructor() {}
  public toDomain(dto: DeeplinkApiDto): Deeplink {
    return new Deeplink(dto.id, dto.link, dto.title, dto.description);
  }
}
