import { AppLink } from '../../app-link/domain/app-link';

export class ActionButton {
  constructor(
    public id: number,
    public body: string,
    public appLinkId: number,
    public appLink: AppLink,
  ) {}
}
