import { IEnvironment } from '../../../../../environments/ienvironment';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../../../../environments/environment';
import { PaginationResponseDto } from '../../../../../core/dashboard/data/dto/pagination-response.dto';
import { DataStatus } from '../../../../../core/network/data.status';
import { PaginationResponse } from '../../../../../core/dashboard/domain/pagination-response';
import { ServiceDefMapper } from '../mappers/service-def-mapper';
import { ServiceDefApiDto } from '../dto/service-def-api-dto';
import { ServiceDef } from '../../domain/entities/service-def';

@Injectable({ providedIn: 'root' })
export class ServiceDefRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpClient: HttpClientWrapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
    private serviceDefMapper: ServiceDefMapper,
  ) {
    this.applicationConfig = applicationConfig;
  }

  public async findAllByLikeSearchCriteria(
    propertyValue: string,
    page: number,
    perPage: number,
  ): Promise<DataStatus<PaginationResponse<ServiceDef>>> {
    let body = {
      pagination: {
        page_number: page,
        per_page: perPage,
      },
      filters: [
        { column: 'is_deleted', operator: 'eq', value: false },
        // { column: 'is_old', operator: 'eq', value: false },
        { column: 'name', operator: 'ilike', value: `%${propertyValue}%` },
      ],
    };

    let response: DataStatus<PaginationResponseDto<ServiceDefApiDto>> = await this.httpClient.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_definitions`,
      body,
    );
    return response.map((data) => {
      return new PaginationResponse(
        data.per_page,
        data.pages,
        data.count,
        data.data.map((item) => this.serviceDefMapper.toDomain(item)),
      );
    });
  }

  async getByServiceId(service_id: number): Promise<DataStatus<ServiceDef>> {
    let response: DataStatus<ServiceDefApiDto> = await this.httpClient.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_definitions/${service_id}`,
    );

    return response.map((data) => {
      return this.serviceDefMapper.toDomain(data);
    });
  }
}
