<div class="d-block">
  <label [attr.for]="componentId" class="form-label" [ngClass]="{ 'required': isRequired }"
    >{{ label }} <span style="color: #929dab" *ngIf="!isRequired">(опционально)</span></label
  >
  <textarea
    [attr.id]="componentId"
    class="form-control"
    [attr.placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
  ></textarea>
</div>
