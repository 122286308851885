import { AttachmentContentMapper } from '../../../../../entity/attachments/data/mappers/attachment-contnent-mapper';
import { StorySlide } from '../../domain/story';
import { StorySlideApiDto } from '../dto/stories-dto';
import { Injectable } from '@angular/core';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';
import { ActionButtonMapper } from '../../../../../entity/action-button/data/mappers/action-button-mapper';

@Injectable({ providedIn: 'root' })
export class StorySlideMapper {
  constructor(
    private actionButtonMapper: ActionButtonMapper,
    private attachmentMapper: AttachmentMapper,
  ) {}

  public toDomain(dto: StorySlideApiDto): StorySlide {
    return new StorySlide(
      dto.id,
      dto.header,
      dto.body,
      dto.story_id,
      dto.attachment_id,
      this.attachmentMapper.toDomain(dto.attachment),
      dto.action_buttons.map((t) => {
        return this.actionButtonMapper.toDomain(t);
      }),
    );
  }
}
