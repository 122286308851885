import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppLink } from '../../../../../entity/app-link/domain/app-link';
import { AppLinkTypeEnum } from '../../../../../entity/app-link/domain/app-link-create';
import { Deeplink } from '../../../../dashboard/deeplinks/domain/deeplink';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MobileStoriesPreviewComponent } from '../../../../dashboard/stories/pages/components/mobile-stories-preview/mobile-stories-preview.component';
import { MobileStoryPreviewPreviewComponent } from '../../../../dashboard/stories/pages/components/mobile-preview-preview/mobile-story-preview-preview.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { TariffSelectFieldComponent } from '../tariff-select-field/tariff-select-field.component';
import { AddButtonComponent } from '../../buttons/add-button/add-button.component';
import { LoadableWrapperComponent } from '../../loadable-wrapper/loadable-wrapper.component';
import { DeeplinkFieldComponent } from '../deeplink-field/deeplink-field.component';

@Component({
  selector: 'app-app-link-field',
  standalone: true,
  imports: [
    MobileStoriesPreviewComponent,
    MobileStoryPreviewPreviewComponent,
    TextFieldComponent,
    NgClass,
    TariffSelectFieldComponent,
    AddButtonComponent,
    NgForOf,
    LoadableWrapperComponent,
    NgIf,
    DeeplinkFieldComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './app-link-field.component.html',
  styleUrl: './app-link-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AppLinkFieldComponent,
    },
  ],
})
export class AppLinkFieldComponent implements ControlValueAccessor, OnInit {
  private _value: AppLinkFieldOutput = AppLinkFieldComponent._defaultValue;

  private static _defaultValue: AppLinkFieldOutput = {
    appLinkType: AppLinkTypeEnum.HTTP_LINK,
    deepLink: null,
    httpLink: null,
  };

  private onChange?: (output: AppLinkFieldOutput | null) => void;

  protected urlFormControl: FormControl<string | null> = new FormControl<string | null>(null);
  protected deeplinkFormControl: FormControl<Deeplink | null> = new FormControl<Deeplink | null>(
    null,
  );

  get value(): AppLinkFieldOutput {
    return this._value;
  }

  set value(newValue) {
    this._value = newValue;

    console.log(this._value);
    if (this.onChange) {
      this.onChange({
        appLinkType: this._value.appLinkType,
        deepLink: this._value.appLinkType == AppLinkTypeEnum.DEEPLINK ? this._value.deepLink : null,
        httpLink:
          this._value.appLinkType == AppLinkTypeEnum.HTTP_LINK ? this._value.httpLink : null,
      });
    }
  }

  ngOnInit() {
    this.deeplinkFormControl.valueChanges.subscribe((t) => {
      this.value = {
        appLinkType: this.value.appLinkType,
        deepLink: t,
        httpLink: this.value.httpLink,
      };
    });

    this.urlFormControl.valueChanges.subscribe((t) => {
      this.value = {
        appLinkType: this.value.appLinkType,
        deepLink: this.value.deepLink,
        httpLink: t,
      };
    });
  }

  registerOnChange(fn: (output: AppLinkFieldOutput | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: AppLinkFieldOutput | null): void {
    this._value = obj ?? AppLinkFieldComponent._defaultValue;

    this.urlFormControl.setValue(obj?.httpLink ?? null);
    this.deeplinkFormControl.setValue(obj?.deepLink ?? null);
  }

  didClickChangeType(type: AppLinkTypeEnum) {
    this.value.appLinkType = type;
  }

  protected readonly AppLinkTypeEnum = AppLinkTypeEnum;
}

export interface AppLinkFieldOutput {
  appLinkType: AppLinkTypeEnum;
  httpLink: string | null;
  deepLink: Deeplink | null;
}
