<form [formGroup]="abonentServiceForm" (ngSubmit)="didClickSubmit()">
  <div class="row">
    <div class="col-md-6">
      <app-form-component [title]="'Короткая карточка сервиса'">
        <app-attachment-cropper-field
          label="Загрузите иконку сервиса"
          formControlName="icon"
          [recommendationInfo]="[
            {
              title: 'Соотношение сторон',
              message: 'Рекомендуемое соотношение сторон для фото или видео - 1:1',
            },
            {
              title: 'Ограничение для фото',
              message: 'Минимальный размер - 72 x 72 Рекомендуемый размер - 216 х 216',
            },
          ]"
        >
        </app-attachment-cropper-field>

        <app-text-field
          class="mb-3 mt-3"
          [isRequired]="true"
          label="Название сервиса"
          placeholder="Введите название"
          formControlName="title"
        >
        </app-text-field>

        <app-textarea-field
          class="mb-3"
          [isRequired]="true"
          label="Короткое описание сервиса"
          placeholder="Введите короткое описание сервиса"
          formControlName="short_description"
        >
        </app-textarea-field>
      </app-form-component>

      <app-form-component title="Полная карточка сервиса">
        <app-attachment-cropper-field
          label="Загрузите обложку сервиса на экран сервиса"
          formControlName="image"
          [recommendationInfo]="[
            {
              title: 'Рекомендация к обложке',
              message:
                'Рекомендуемый размер для обложки - 1080 х 900, где основное изображение находится в центральной области и помещаеся в зону - 1080 х 672',
            },
          ]"
        >
        </app-attachment-cropper-field>

        <app-select-field
          [selectValues]="availableServiceTypeSelectables"
          class="mb-3 mt-3"
          [isRequired]="true"
          label="Тип сервиса"
          placeholder="Выберите тип сервиса"
          formControlName="type"
        >
        </app-select-field>

        <div class="mb-3">
          <app-text-field
            *ngIf="abonentServiceForm.value.type == AbonentServiceType.web_app"
            class="mb-3"
            label="Ссылка на сервис"
            placeholder="Введите ссылку на сервис"
            formControlName="service_link_web"
          >
          </app-text-field>

          <div *ngIf="abonentServiceForm.value.type == AbonentServiceType.mobile_app">
            <app-text-field
              class="mb-3"
              label="Ссылка на сервис в App Store (iOS)"
              placeholder="Введите ссылку на сервис"
              formControlName="service_link_ios"
            >
            </app-text-field>

            <app-text-field
              class="mb-3"
              label="Ссылка на сервис в Google Play (Android)"
              placeholder="Введите ссылку на сервис"
              formControlName="service_link_android"
            >
            </app-text-field>
          </div>
        </div>

        <app-editor-js-field
          class="mb-3"
          label="Описание сервиса"
          placeholder="Начните вводить..."
          formControlName="full_description"
        >
        </app-editor-js-field>
      </app-form-component>
    </div>

    <div class="col-md-6">
      <div class="card sticky-top">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр экрана сервиса в приложении</h3>
        </div>
        <div class="card-body">
          <app-mobile-abonent-services-detail-preview
            [title]="abonentServiceForm.controls.title.value"
            [description]="abonentServiceForm.controls.full_description.value"
            [image]="abonentServiceForm.controls.image.value?.croppedData"
          >
          </app-mobile-abonent-services-detail-preview>
        </div>

        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/abonent-services'])" class="btn btn-link">Отмена</a>
            <button type="submit" class="btn btn-primary">
              <span
                *ngIf="sendFormState == UIStateType.Loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
