export class ImageAttachmentAttribute implements AttachmentAttributes {
  constructor(
    public filename: string,
    public contentType: string,
    public contentPath: string,
    public contentPathCompressed: string,
  ) {}
}

export class BaseAttachmentAttribute implements AttachmentAttributes {
  constructor(
    public filename: string,
    public contentType: string,
    public contentPath: string,
  ) {}
}

export interface AttachmentAttributes {
  filename: string;
  contentType: string;
  contentPath: string;
}
export class AttachmentContent {
  constructor(
    public attributes: AttachmentAttributes,
    public type: AttachmentContentType,
  ) {}
}

export enum AttachmentContentType {
  PHOTO = 'photo',
  VIDEO = 'video',
  DOCUMENT = 'document',
  ATTACHMENT = 'attachment',
}

export namespace AttachmentContentType {
  export function fromString(str: string): AttachmentContentType {
    return <AttachmentContentType>AttachmentContentType[str as keyof typeof AttachmentContentType];
  }
}

export enum AttachmentType {
  STORY_SLIDE = 'story_slide',
  STORY_PREVIEW = 'story_preview',
  BANNER = 'banner',
  PROMO_BANNER = 'promo_banner',
  PROMO_COVER = 'promo_cover',
  SERVICE_EXT_PACKET_ICON = 'service_ext_packet_icon',
  SERVICE_EXT_CINEMA_ICON = 'service_ext_cinema_icon',
  SERVICE_EXT_BANNER = 'service_ext_banner',
  SERVICE_EXT_COVER = 'service_ext_cover',
  ABONENT_SERVICE_ICON = 'abonent_service_icon',
  ABONENT_SERVICE_COVER = 'abonent_service_cover',
}

export namespace AttachmentType {
  export function fromString(str: string): AttachmentType {
    return <AttachmentType>AttachmentType[str as keyof typeof AttachmentType];
  }
}

export class Attachment {
  constructor(
    public id: number,
    public name: string,
    public mediaType: string,
    public format: string,
    public content: AttachmentContent,
  ) {}

  public getBackgroundImage(): string {
    return 'background-image: url(' + this.content.attributes.contentPath + ')';
  }
}
