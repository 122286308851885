import { Injectable } from '@angular/core';
import { StorySlideCreateRequest } from '../../domain/story-slide-create-request';
import { StorySlideCreateRequestDto } from '../dto/story-slide-create-request-dto';

@Injectable({ providedIn: 'root' })
export class StorySlideCreateRequestMapper {
  toData(entity: StorySlideCreateRequest): StorySlideCreateRequestDto {
    return new StorySlideCreateRequestDto(
      entity.header,
      entity.body,
      entity.storyId,
      entity.attachmentId,
    );
  }
}
