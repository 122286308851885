<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button [title]="'Добавить офис'" [routerLink]="['create']"></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="col col-lg-12 mb-3">
    <select class="form-select" id="cityInput" placeholder="Город" (change)="setOfficeCity($event)">
      <option *ngFor="let officeEnum of Object.keys(OfficeCityType)" [value]="[officeEnum]">
        {{ getOfficeCityType(officeEnum) }}
      </option>
    </select>
  </div>

  <div class="card">
    <div class="card-header">
      <div class="card-title">Офисы</div>
    </div>
    <div class="card-body">
      <app-loadable-wrapper [loadable]="orionOffices" loadingTitle="Загрузка ">
        <div class="row row-cards">
          <div *ngFor="let item of orionOffices.safeData" class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-10">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="datagrid">
                          <div class="datagrid-item">
                            <div class="datagrid-title">Название</div>
                            <div class="datagrid-content">{{ item.title }}</div>
                          </div>
                          <div class="datagrid-item">
                            <div class="datagrid-title">Адрес</div>
                            <div class="datagrid-content">{{ item.address }}</div>
                          </div>
                          <div class="datagrid-item">
                            <div class="datagrid-title">Контакты</div>
                            <div class="datagrid-content">
                              <div *ngFor="let contact of item.contacts">
                                {{ contact.phone | phoneOutput }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="datagrid">
                          <div class="datagrid-item">
                            <div class="datagrid-title">График работы</div>
                            <div class="datagrid-content">
                              <div *ngFor="let weekday of item.businessDays">
                                <div class="row">
                                  <div class="col-lg-2">
                                    {{ getWeekDayAbbrEnum(weekday.weekday) }}
                                  </div>
                                  <div class="col-lg-10">
                                    <span *ngIf="!weekday.isDayOff"
                                      >{{
                                        weekday.openTime?.substring(0, weekday.openTime!.length - 3)
                                      }}
                                      -
                                      {{
                                        weekday.closeTime?.substring(
                                          0,
                                          weekday.closeTime!.length - 3
                                        )
                                      }}</span
                                    >
                                    <span *ngIf="weekday.isDayOff" style="color: red"
                                      >Выходной</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="d-flex justify-content-end">
                      <div class="dropdown">
                        <button
                          class="btn btn-link"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style="padding: 0px"
                        >
                          <i class="ti ti-dots-vertical"></i>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-demo"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" routerLink="update/{{ item.id }}" [state]="item"
                            >Редактировать</a
                          >
                          <button
                            type="button"
                            class="dropdown-item"
                            (click)="delete(item.id).finally()"
                          >
                            Удалить
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-loadable-wrapper>
    </div>
  </div>
</app-page-wrapper>
