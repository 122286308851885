import { Component, Input, OnInit } from '@angular/core';
import { OfficeCityCoordinatesType, OfficeCityType } from '../../../domain/orion-office';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { AngularYandexMapsModule, YaEvent } from 'angular8-yandex-maps';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MobileOrionOfficePreviewComponent } from '../mobile-orion-office-preview/mobile-orion-office-preview.component';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import {
  SelectFieldComponent,
  SelectFieldValue,
} from '../../../../../components/common/form-fields/select-field/select-field.component';
import { TextFieldComponent } from '../../../../../components/common/form-fields/text-field/text-field.component';
import {
  OrionBusinessHoursFieldComponent,
  OrionBusinessHoursFormValue,
} from './components/orion-business-hours-field/orion-business-hours-field.component';
import {
  OrionOfficeContactFormValue,
  OrionOfficeContactsFieldComponent,
} from './components/orion-office-contacts-field/orion-office-contacts-field.component';

@Component({
  selector: 'app-orion-offices-form',
  standalone: true,
  imports: [
    FormComponentComponent,
    AngularYandexMapsModule,
    ReactiveFormsModule,
    MobileOrionOfficePreviewComponent,
    NgIf,
    SelectFieldComponent,
    TextFieldComponent,
    OrionBusinessHoursFieldComponent,
    OrionOfficeContactsFieldComponent,
  ],
  templateUrl: './orion-offices-form.component.html',
  styleUrl: './orion-offices-form.component.css',
})
export class OrionOfficesFormComponent implements OnInit {
  @Input() formState: UIStateType = UIStateType.NotRequested;
  @Input() delegate?: OrionOfficesFormDelegate;

  formGroup!: FormGroup<OrionOfficesCreateForm>;

  get placemarkCoords(): number[] {
    if (this.formGroup.getRawValue().longitude && this.formGroup.getRawValue().latitude) {
      return [this.formGroup.getRawValue().latitude!, this.formGroup.getRawValue().longitude!];
    } else {
      return OfficeCityCoordinatesType[
        this.formGroup.controls.city as unknown as keyof typeof OfficeCityCoordinatesType
      ];
    }
  }

  selectValues: SelectFieldValue<OfficeCityType>[] = [
    { value: OfficeCityType.krasnoyarsk, selectTitle: OfficeCityType.krasnoyarsk.valueOf() },
    { value: OfficeCityType.abakan, selectTitle: OfficeCityType.abakan.valueOf() },
    { value: OfficeCityType.bratsk, selectTitle: OfficeCityType.bratsk.valueOf() },
    { value: OfficeCityType.kansk, selectTitle: OfficeCityType.kansk.valueOf() },
    { value: OfficeCityType.irkutsk, selectTitle: OfficeCityType.irkutsk.valueOf() },
    { value: OfficeCityType.chernogorsk, selectTitle: OfficeCityType.chernogorsk.valueOf() },
    { value: OfficeCityType.kiselevsk, selectTitle: OfficeCityType.kiselevsk.valueOf() },
    { value: OfficeCityType.minusinsk, selectTitle: OfficeCityType.minusinsk.valueOf() },
    { value: OfficeCityType.novokuznetsk, selectTitle: OfficeCityType.novokuznetsk.valueOf() },
    { value: OfficeCityType.prokopyevsk, selectTitle: OfficeCityType.prokopyevsk.valueOf() },
    { value: OfficeCityType.sayanogorsk, selectTitle: OfficeCityType.sayanogorsk.valueOf() },
    { value: OfficeCityType.zelenogorsk, selectTitle: OfficeCityType.zelenogorsk.valueOf() },
    { value: OfficeCityType.zheleznogorsk, selectTitle: OfficeCityType.zheleznogorsk.valueOf() },
  ];
  constructor(
    public router: Router,
    private formBuilder: NonNullableFormBuilder,
  ) {}

  parameters: ymaps.control.ISearchControlParameters = {
    options: {
      provider: 'yandex#search',
      noSuggestPanel: false,
      noPlacemark: true,
    },
  };

  mapState: ymaps.IMapState = {
    type: 'yandex#map',
    controls: [],
    zoom: 12,
  };

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      address: ['', Validators.required],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      city: [OfficeCityType.krasnoyarsk, Validators.required],
      businessDays: [<OrionBusinessHoursFormValue[]>[], Validators.required],
      contacts: [<OrionOfficeContactFormValue[]>[]],
    }) as FormGroup<OrionOfficesCreateForm>;

    this.setCoordinates(OfficeCityType.krasnoyarsk);

    this.formGroup.controls.city.valueChanges.subscribe((t) => {
      this.setCoordinates(t);
    });

    if (this.delegate?.didCreateForm) {
      this.delegate.didCreateForm(this.formGroup);
    }
  }

  private setCoordinates(cityType: OfficeCityType) {
    for (let enumMember in OfficeCityType) {
      if (OfficeCityType[enumMember as keyof typeof OfficeCityType] == cityType) {
        let placemarkCoords =
          OfficeCityCoordinatesType[
            enumMember as unknown as keyof typeof OfficeCityCoordinatesType
          ];
        this.formGroup.controls.latitude.setValue(placemarkCoords[0]);
        this.formGroup.controls.longitude.setValue(placemarkCoords[1]);
      }
    }
  }
  onMapClick($event: YaEvent<ymaps.Map>): void {
    const { target, event } = $event;

    const coords = event.get('coords');

    this.formGroup.patchValue({
      latitude: coords[0].toFixed(6),
      longitude: coords[1].toFixed(6),
    });
  }

  onSubmit() {
    console.log('submit');
    this.delegate?.didSubmitForm(this.formGroup);
  }

  protected readonly officeCityType = OfficeCityType;
  protected readonly UIStateType = UIStateType;
}

export interface OrionOfficesFormDelegate {
  didCreateForm?: (form: FormGroup<OrionOfficesCreateForm>) => void;
  didSubmitForm: (form: FormGroup<OrionOfficesCreateForm>) => void;
}

export interface OrionOfficesCreateForm {
  title: FormControl<string>;
  address: FormControl<string>;
  latitude: FormControl<number | null>;
  longitude: FormControl<number | null>;
  city: FormControl<OfficeCityType>;
  businessDays: FormControl<OrionBusinessHoursFormValue[]>;
  contacts: FormControl<OrionOfficeContactFormValue[]>;
}
