export enum WeekDaysEnum {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export class OfficeBusinessHours {
  constructor(
    public id: number,
    public officeId: number,
    public weekday: WeekDaysEnum,
    public openTime: string | null,
    public closeTime: string | null,
    public isDayOff: boolean,
  ) {}
}
