import {
  Attachment,
  AttachmentAttributes,
  AttachmentContent,
  AttachmentContentType,
  AttachmentType,
  BaseAttachmentAttribute,
  ImageAttachmentAttribute,
} from '../../domain/attachment';
import { AttachmentApiDto, AttributesApiDto, ImageAttributesApiDto } from '../attachment-api-dto';
import { Injectable } from '@angular/core';
import { AttachmentContentMapper } from './attachment-contnent-mapper';

@Injectable({ providedIn: 'root' })
export class AttachmentMapper {
  constructor(private attachmentContentMapper: AttachmentContentMapper) {}
  public toDomain(value: AttachmentApiDto): Attachment {
    let type = AttachmentContentType.fromString(value.content.type);
    return new Attachment(
      value.id,
      value.name,
      value.media_type,
      value.format,
      this.attachmentContentMapper.toDomain(value.content),
    );
  }
}
