import { Injectable } from '@angular/core';
import { OrionOfficeContactCreateRequest } from '../../domain/orion-office-contact-create-request';
import { OrionOfficeContactCreateRequestDto } from '../dto/orion-office-contact-create-request-dto';

@Injectable({ providedIn: 'root' })
export class OrionOfficeContactCreateRequestMapper {
  constructor() {}

  public toData(entity: OrionOfficeContactCreateRequest): OrionOfficeContactCreateRequestDto {
    return new OrionOfficeContactCreateRequestDto(entity.officeId, entity.phone);
  }
}
