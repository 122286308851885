import { TariffExtCreateRequest } from '../../domain/tariff-ext-create-request';
import { TariffExtCreateRequestDto } from '../dto/tariff-ext-create-request-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TariffExtCreateRequestMapper {
  toData(entity: TariffExtCreateRequest): TariffExtCreateRequestDto {
    return new TariffExtCreateRequestDto(entity.name, entity.tariffId, entity.description);
  }
}
