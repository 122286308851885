<div class="mb-3 mt-3">
  <label [attr.for]="componentId" class="form-label" [ngClass]="{ 'required': isRequired }"
    >{{ label }} <span style="color: #929dab" *ngIf="!isRequired">(опционально)</span></label
  >
  <div class="input-icon">
    <input
      [attr.id]="componentId"
      class="form-control"
      placeholder="{{ placeholder }}"
      #dateInput
    />

    <span class="input-icon-addon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="icon"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"
        ></path>
        <path d="M16 3v4"></path>
        <path d="M8 3v4"></path>
        <path d="M4 11h16"></path>
        <path d="M11 15h1"></path>
        <path d="M12 15v3"></path>
      </svg>
    </span>
  </div>
</div>
