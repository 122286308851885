export class AbonentServiceUpdateRequestDto {
  constructor(
    public type: string,
    public title: string,
    public short_description: string,
    public full_description: { [id: string]: any },
    public service_link_web: string | null,
    public service_link_android: string | null,
    public service_link_ios: string | null,
    public icon_id: number,
    public image_id: number,
  ) {}
}
