<div class="mb-3">
  <label [attr.for]="componentId" class="form-label" [ngClass]="{ 'required': isRequired }"
    >{{ label }} <span style="color: #929dab" *ngIf="!isRequired">(опционально)</span></label
  >
  <div class="card">
    <div class="card-body">
      <div [attr.id]="componentId"></div>
    </div>
  </div>
</div>
