import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexDataLabels,
  ApexPlotOptions,
  ChartComponent,
} from 'ng-apexcharts';
import { StatsContainerComponent } from '../stats-container/stats-container.component';
import { ChartOptions } from '../push-stats-chart-bar/push-stats-chart-bar.component';
import { StatisticsRepository } from '../../../repository/statistics-repository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import {
  StatsByNewUsersItem,
  StatsByNewUsersPlatformEnum,
} from '../../../domain/stats-by-new-users-response';

@Component({
  selector: 'app-users-stats-chart-line',
  standalone: true,
  imports: [ChartComponent, StatsContainerComponent],
  templateUrl: './users-stats-chart-line.component.html',
  styleUrl: './users-stats-chart-line.component.css',
})
export class UsersStatsChartLineComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: ChartOptions;

  dateFrom: Date = new Date(Date.now());
  dateUntil: Date = new Date(Date.now());

  pageTitle: string = 'Главная';
  pageSubtitle: string = 'Статистика по модулям приложения';

  amount?: number = 0;
  androidAmount?: number = 0;
  iosAmount?: number = 0;

  protected plotOptions: ApexPlotOptions = {
    bar: {
      dataLabels: {
        position: 'top',
      },
    },
  };

  protected dataLabels: ApexDataLabels = {
    enabled: true,
    formatter: function (val: string) {
      return val;
    },
    offsetY: -20,
    style: {
      fontSize: '14px',
      colors: ['#304758'],
    },
  };

  series: ApexAxisChartSeries = [
    {
      name: 'Кол-во',
      data: [],
    },
  ];

  constructor(private statisticsRepository: StatisticsRepository) {
    this.chartOptions = {
      xaxis: {
        type: 'datetime',

        labels: {
          show: true,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
        axisTicks: {
          show: false,
        },
        position: 'bottom',
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    };
  }

  ngOnInit() {
    this.dateFrom.setMonth(this.dateFrom.getMonth() - 1);

    this.statisticsRepository.getStatsByNewUsers(this.dateFrom, this.dateUntil).then((value) => {
      let data = Loadable.getFromDataStatus(value).data.data;
      let periodMapAndroid = this.getMapForPeriod(this.dateFrom, this.dateUntil);
      let periodMapIos = this.getMapForPeriod(this.dateFrom, this.dateUntil);

      data.forEach((t) => {
        switch (t.platform) {
          case StatsByNewUsersPlatformEnum.ANDROID:
            periodMapAndroid.get(t.date.getTime())?.push(t);
            break;
          case StatsByNewUsersPlatformEnum.IOS:
            periodMapIos.get(t.date.getTime())?.push(t);
            break;
        }
      });

      let iosArr: { x: any; y: any }[] = [];
      let androidArr: { x: any; y: any }[] = [];

      periodMapIos.forEach((v, k) => {
        let count = 0;

        v.forEach((t) => {
          count += t.count;
        });

        iosArr.push({ x: k, y: count });
      });

      periodMapAndroid.forEach((v, k) => {
        let count = 0;
        v.forEach((t) => {
          count += t.count;
        });

        androidArr.push({ x: k, y: count });
      });

      this.series = [
        {
          name: 'iOS',
          data: iosArr,
        },
        {
          name: 'Android',
          data: androidArr,
        },
      ];

      let androidCount = 0;
      let iosCount = 0;

      iosArr.forEach((t) => {
        iosCount += t.y;
      });

      androidArr.forEach((t) => {
        iosCount += t.y;
      });

      this.iosAmount = iosCount;
      this.androidAmount = androidCount;

      this.amount = androidCount + iosCount;
    });
  }

  private getMapForPeriod(dateFrom: Date, dateUntil: Date) {
    let map = new Map<number, StatsByNewUsersItem[]>();

    let lastDate = new Date(dateFrom.getTime());

    lastDate.setUTCHours(0, 0, 0, 0);
    while (true) {
      map.set(lastDate.getTime(), []);

      lastDate.setDate(lastDate.getDate() + 1);

      if (lastDate > dateUntil) {
        break;
      }
    }

    return map;
  }
}
