import { NgIf } from '@angular/common';
import { Component, Input, OnInit, viewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageCropperComponent } from 'ngx-image-cropper';
import * as url from 'url';
import { Task } from '../../../../../core/utils/task';
import { Attachment, AttachmentContent } from '../../../../../entity/attachments/domain/attachment';
import { RecommendationInfo } from '../attachment-cropper-field/attachment-cropper-field.component';

@Component({
  selector: 'app-attachment-field',
  standalone: true,
  imports: [ImageCropperComponent, NgIf],
  templateUrl: './attachment-field.component.html',
  styleUrl: './attachment-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AttachmentFieldComponent,
    },
  ],
})
export class AttachmentFieldComponent implements OnInit, ControlValueAccessor {
  private static id: number = 0;
  componentId = `attachment-cropper=field-${AttachmentFieldComponent.id++}`;

  @Input() aspectRatio: number = 1;
  @Input() label: string = '';
  @Input() recommendationInfo: RecommendationInfo[] = [];

  @Input() fixedFileSize: { width: number; height: number } | null = null;

  sizeIsValid = true;

  private _data: AttachmentOutput | null = null;
  private onChange?: (obj: AttachmentOutput | null) => void;

  get data(): AttachmentOutput | null {
    return this._data;
  }

  set data(newValue) {
    this._data = newValue;

    if (this.onChange) {
      this.onChange(this._data);
    }
  }

  fileBrowseHandler(event: any): void {
    this.data = new AttachmentOutput(event.target.files[0], null, null);

    new Task(async () => {
      if (this.data?.file) {
        this.fromFile(this.data?.file);
      }
    });
  }

  private fromAttachment(data: Attachment) {
    if (this.isImageFile(data.content.attributes.contentType)) {
      this.createImagePreview(data.content.attributes.contentPath);
    }

    if (this.isVideoFile(data.content.attributes.contentType)) {
      this.createVideoPreview(data.content.attributes.contentPath);
    }
  }

  private fromFile(data: File) {
    if (this.isImageFile(data.type)) {
      let image = new Image();
      image.src = URL.createObjectURL(data);
      image.onload = (t) => {
        if (this.fixedFileSize) {
          this.sizeIsValid =
            this.fixedFileSize.width == image.width && this.fixedFileSize.height == image.height;
        }
      };

      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        this.data = new AttachmentOutput(
          data,
          reader.result as string,
          this.data?.attachment ?? null,
        );
      };
    }

    if (this.isVideoFile(data.type)) {
      this.createVideoPreview(data);
    }
  }

  isVideoFile(type: string): boolean {
    return type.startsWith('video/');
  }

  isImageFile(type: string): boolean {
    return type.startsWith('image/');
  }

  createImagePreview(file: File | string) {
    let image = new Image();

    console.log(`image ${file}`);
    image.crossOrigin = 'anonymous';
    image.src = file instanceof File ? URL.createObjectURL(file) : file;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    image.onload = (t) => {
      if (this.fixedFileSize) {
        this.sizeIsValid =
          this.fixedFileSize.width == image.width && this.fixedFileSize.height == image.height;
      }

      if (ctx) {
        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        this.data = new AttachmentOutput(
          this.data!.file,
          canvas.toDataURL('image/png'),
          this.data?.attachment ?? null,
        );
      }
    };
  }
  createVideoPreview(file: File | string): void {
    const video = document.createElement('video');

    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');

    const fileURL = file instanceof File ? URL.createObjectURL(file) : file;
    video.crossOrigin = 'anonymous';
    video.src = fileURL;

    const onLoadedData = () => {
      video.currentTime = Math.min(1, video.duration); // Установить время на 5 секунд или меньше, если видео короче

      video.addEventListener('seeked', onSeeked);
    };

    const onSeeked = () => {
      try {
        if (ctx) {
          console.log('seeked');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          console.log(canvas.toDataURL('image/png'));

          this.data = new AttachmentOutput(
            file instanceof File ? file : null,
            canvas.toDataURL('image/png'),
            this.data?.attachment ?? null,
          );

          URL.revokeObjectURL(fileURL); // Освободить память
          video.removeEventListener('loadeddata', onLoadedData);
          video.removeEventListener('seeked', onSeeked);
          video.remove();
        }
      } catch (error) {
        console.log(error);
        video.removeEventListener('loadeddata', onLoadedData);
        video.removeEventListener('seeked', onSeeked);
        video.remove();
      }
    };

    video.addEventListener('loadeddata', onLoadedData);
    video.load(); // Загрузка видео
  }

  removeData() {
    this.data = null;
  }

  convert(str: string): string {
    return str.replace(/\n/g, '<br>');
  }

  public ngOnInit(): void {}

  public registerOnChange(fn: (obj: AttachmentOutput | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public writeValue(obj: AttachmentOutput | null): void {
    this.data = obj;

    if (obj?.attachment) {
      this.fromAttachment(obj.attachment);
    }
  }
}

export interface DefaultAttachment {
  attachmentId: number;
  attachmentContent: AttachmentContent;
}
export class AttachmentOutput {
  constructor(
    public file: File | null,
    public previewBase64: string | null,
    public attachment: Attachment | null,
  ) {}

  getBackgroundImage(): string {
    return `background-image: url('${this.previewBase64}')`;
  }
}
