<div class="page">
  <!-- Sidebar -->
  <aside class="navbar navbar-vertical navbar-expand-lg navbar-transparent">
    <div class="container-xxl">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebar-menu"
        aria-controls="sidebar-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-brand navbar-brand-autodark">
        <a href=".">
          <img
            src="/assets/dist/img/logo.svg"
            width="110"
            height="32"
            alt="Tabler"
            class="navbar-brand-image"
          />
        </a>
      </div>
      <div class="collapse navbar-collapse" id="sidebar-menu">
        <ul class="navbar-nav pt-lg-3">
          <app-nav-item
            title="Главная"
            [isDropdown]="false"
            iconName="home"
            routerLink="/"
            [ignoreActiveState]="true"
          ></app-nav-item>

          <app-nav-item
            title="Истории"
            [isDropdown]="true"
            iconName="history-toggle"
            [includedRouterLinks]="['/stories', '/stories/create-story']"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Список историй"
                routerLink="stories"
              ></app-dropdown-item>
              <app-dropdown-item
                dropdownTitle="Добавление истории"
                routerLink="stories/create-story"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Баннеры"
            [isDropdown]="true"
            iconName="chalkboard"
            [includedRouterLinks]="['/banners', '/banners/create-banner']"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Список баннеров"
                routerLink="banners"
              ></app-dropdown-item>
              <app-dropdown-item
                dropdownTitle="Добавление баннера"
                routerLink="banners/create-banner"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <!--          <app-nav-item-->
          <!--            title="Заявки на подключение"-->
          <!--            [isDropdown]="false"-->
          <!--            routerLink="connection-requests"-->
          <!--            iconName="ghost"-->
          <!--          ></app-nav-item>-->

          <app-nav-item
            title="Сервисы"
            [isDropdown]="true"
            iconName="layout-2"
            [includedRouterLinks]="[
              '/abonent-services',
              '/abonent-services/create-abonent-service',
            ]"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Список сервисов"
                routerLink="abonent-services"
              ></app-dropdown-item>
              <app-dropdown-item
                dropdownTitle="Добавление сервиса"
                routerLink="abonent-services/create-abonent-service"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Услуги"
            [isDropdown]="true"
            iconName="checkup-list"
            [includedRouterLinks]="[
              '/service-ext-list',
              '/service-ext-list/add',
              '/service-ext-list/update',
              '/available-services',
            ]"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Расширения услуг"
                routerLink="service-ext-list"
              ></app-dropdown-item>

              <app-dropdown-item
                dropdownTitle="Доступные услуги"
                routerLink="available-services"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Тарифы"
            [isDropdown]="true"
            iconName="file-typography"
            [includedRouterLinks]="['/available-tariffs', '/tariff-ext-list']"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Доступные тарифы"
                routerLink="available-tariffs"
              ></app-dropdown-item>

              <app-dropdown-item
                dropdownTitle="Расширения тарифов"
                routerLink="tariff-ext-list"
              ></app-dropdown-item>

              <app-dropdown-item
                dropdownTitle="Создать расширение тарифа"
                routerLink="tariff-ext-list/create"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Офисы"
            [isDropdown]="false"
            routerLink="orion-offices"
            iconName="building-skyscraper"
          ></app-nav-item>

          <app-nav-item
            title="Диплинки"
            [isDropdown]="true"
            iconName="link"
            [includedRouterLinks]="['/deeplink-list', '/deeplink-list/create']"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Список диплинков"
                routerLink="deeplink-list"
              ></app-dropdown-item>

              <app-dropdown-item
                dropdownTitle="Добавление диплинка"
                routerLink="deeplink-list/create"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>
        </ul>
      </div>
    </div>
  </aside>
  <header class="navbar navbar-expand-md d-print-none navbar-transparent">
    <div class="container-xxl">
      <div class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"></div>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-link d-flex lh-1 text-reset p-0">
          <span
            *ngIf="adminUser.safeData?.photo !== null; else avatarPlaceholder"
            class="avatar avatar-sm"
            style="background-image: url(&quot; https://api-erp.orionnet.ru/v1/virtual/avatar/{{
              adminUser.safeData?.photo
            }} &quot;)"
          ></span>
          <ng-template #avatarPlaceholder>
            <span class="avatar-placeholder avatar avatar-sm">
              {{ avatarNaming }}
            </span>
          </ng-template>
          <div class="d-none d-xl-block ps-2">
            <div>{{ adminUser.safeData?.name }}</div>
            <div class="mt-1 small text-secondary">{{ adminUser.safeData?.stuff }}</div>
          </div>
        </div>
        <button class="btn logout-btn" type="button" (click)="logout()">
          <div class="d-flex align-items-center">
            <i class="ti ti-logout"></i><span class="logout-text">Выйти</span>
          </div>
        </button>
      </div>
    </div>
  </header>
  <div class="page-wrapper">
    <router-outlet></router-outlet>

    <app-footer class="mt-auto"></app-footer>
  </div>
</div>

@for (item of this.presentedModal; track item) {
  <app-modal-view-component [modalConfig]="item"></app-modal-view-component>
}
