import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { MobilePreviewComponent } from '../../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { FormGroup } from '@angular/forms';
import { ServiceExtForm } from '../../service-extension-form/service-extension-form.component';
import { GeneralAttributesFormOutput } from '../../service-extension-attributes/service-extension-attributes.component';

@Component({
  selector: 'app-mobile-general-preview',
  standalone: true,
  imports: [
    MobilePreviewComponent,
    PreviewBoxComponent,
    NgIf,
    MobilePreviewComponent,
    PreviewBoxComponent,
  ],
  templateUrl: './mobile-general-preview.component.html',
  styleUrl: './mobile-general-preview.component.css',
})
export class MobileGeneralPreviewComponent implements OnInit {
  @Input() form?: FormGroup<ServiceExtForm>;

  bannerPreview?: string;

  ngOnInit() {
    this.form?.valueChanges.subscribe(() => {
      const banner = this.generalAttributes?.banner.croppedData;
      if (banner) {
        const reader = new FileReader();
        reader.readAsDataURL(banner);
        reader.onloadend = () => {
          this.bannerPreview = reader.result as string;
        };
      }
    });
  }

  get title(): string | undefined {
    return this.form?.getRawValue().title.length == 0
      ? this.form?.getRawValue().service?.name
      : this.form?.getRawValue().title;
  }

  get description(): string | undefined {
    return this.form?.getRawValue().description.length == 0
      ? this.form?.getRawValue().service?.description
      : this.form?.getRawValue().description;
  }

  get cost(): string | undefined {
    return this.form?.getRawValue().service?.cost.toFixed(2);
  }

  private get generalAttributes(): GeneralAttributesFormOutput | undefined {
    if (this.form?.getRawValue().attributes instanceof GeneralAttributesFormOutput) {
      return this.form?.getRawValue().attributes as GeneralAttributesFormOutput;
    }
    return undefined;
  }
}
