<li class="nav-item" [class.dropdown]="isDropdown" [class.active]="isActiveIncludedRoute">
  <a
    class="nav-link"
    [class.show]="isActiveIncludedRoute"
    [class.dropdown-toggle]="isDropdown"
    [routerLink]="routerLink"
    data-bs-toggle="dropdown"
    data-bs-auto-close="false"
    role="button"
    aria-expanded="false"
  >
    <span class="nav-link-icon d-md-none d-lg-inline-block" *ngIf="iconName != null">
      <i-tabler [name]="iconName" class="icon-on-item"></i-tabler>
    </span>

    <span class="nav-link-title">
      {{ title }}
    </span>
  </a>

  <ng-template [ngIf]="isDropdown">
    <ng-content></ng-content>
  </ng-template>
</li>
