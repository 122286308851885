export type TaskFunction<T> = (context: TaskContext) => Promise<T>;

export class Task<T> {
  private readonly taskContext: TaskContext;
  private readonly promise: Promise<void>;
  public constructor(taskFunction: TaskFunction<T>) {
    this.taskContext = new TaskContext();
    this.promise = this.run(taskFunction);
    this.promise.finally();
  }

  private async run(taskFunction: TaskFunction<T>) {
    try {
      const result = await taskFunction(this.taskContext);

      console.log('Task completed with result:', result);
    } catch (error) {
      console.error('Task failed with error:', error);
    }
  }

  static sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public cancel() {
    console.log('Task is cancelled');
    this.taskContext.cancelTask();
  }

  public toPromise(): Promise<void> {
    return this.promise;
  }
}

export class TaskContext {
  private _isCancelled: boolean = false;

  get isCancelled(): boolean {
    return this._isCancelled;
  }

  cancelTask() {
    this._isCancelled = true;
  }
}
