import { Component, OnInit } from '@angular/core';
import { NavItemComponent } from '../components/common/nav-item/nav-item.component';
import { DropdownItemComponent } from '../components/common/dropdown-item/dropdown-item.component';
import { DropdownMenuComponent } from '../components/common/dropdown-menu/dropdown-menu.component';
import { FooterComponent } from '../components/common/footer/footer.component';
import { Router, RouterOutlet } from '@angular/router';
import { PageWrapperComponent } from '../components/common/page-wrapper/page-wrapper.component';
import { PageHeaderComponent } from '../components/common/page-header/page-header.component';
import { AdminUserRepository } from '../../entity/admin-user/data/repositories/AdminUserRepository';
import { Loadable, UIStateType } from '../../core/utils/wrappers/loadable';
import { AdminUser } from '../../entity/admin-user/domain/admin-user';
import { NgIf } from '@angular/common';
import { ModalService } from '../components/common/modal-service/modal-service';
import { ModalParamsInterface } from '../components/common/modal-service/modal-component/domain/modal-params-interface';
import { ModalViewComponent } from '../components/common/modal-service/modal-component/modal-view-component/modal-view-component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    NavItemComponent,
    DropdownItemComponent,
    DropdownMenuComponent,
    FooterComponent,
    RouterOutlet,
    PageWrapperComponent,
    PageHeaderComponent,
    ModalViewComponent,
    NgIf,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.css',
  providers: [ModalService],
})
export class MainComponent implements OnInit {
  presentedModal: ModalParamsInterface[] = [];

  constructor(
    private modalService: ModalService,
    private adminUserRepo: AdminUserRepository,
    private router: Router,
  ) {}

  avatarNaming?: string;
  public adminUser: Loadable<AdminUser> = new Loadable<AdminUser>();

  async getAdminUser() {
    if (this.adminUser.status != UIStateType.Loading) {
      this.adminUser = Loadable.loading();
      let response = await this.adminUserRepo.get();
      this.adminUser = Loadable.getFromDataStatus(response);

      if (this.adminUser.safeData) {
        this.mapUser(this.adminUser.safeData);
      }
    }
  }

  private mapUser(user: AdminUser) {
    this.avatarNaming = `${user.name.charAt(user.name.length - 5)}${user.name.charAt(user.name.length - 2)}`;
  }

  ngOnInit(): void {
    this.getAdminUser().finally();
    this.modalService.presentedModals$.subscribe((value) => {
      this.presentedModal = value;
    });
  }

  logout() {
    this.router.navigate(['auth/logout']).finally();
  }

  protected readonly UIStateType = UIStateType;
}
