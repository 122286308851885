export class StatsBySentSmsResponse {
  constructor(public data: StatsBySentSmsItem[]) {}

  get allCount(): number {
    return this.data
      .map((t) => {
        return t.count;
      })
      .reduce((accumulator, currentValue) => accumulator + currentValue);
  }
}

export class StatsBySentSmsItem {
  constructor(
    public count: number,
    public date: Date,
  ) {}
}
