import { Component, Input, OnInit } from '@angular/core';
import { MobilePreviewComponent } from '../../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { NgIf } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { ServiceExtForm } from '../../service-extension-form/service-extension-form.component';
import { SmotreshkaPocketFormOutput } from '../../service-extension-attributes/service-extension-attributes.component';

@Component({
  selector: 'app-mobile-smotreshka-packet-preview',
  standalone: true,
  imports: [MobilePreviewComponent, PreviewBoxComponent, NgIf],
  templateUrl: './mobile-smotreshka-packet-preview.component.html',
  styleUrl: './mobile-smotreshka-packet-preview.component.css',
})
export class MobileSmotreshkaPacketPreviewComponent implements OnInit {
  ngOnInit(): void {
    this.form?.valueChanges.subscribe(() => {
      const image = this.pocketAttributes?.cover.croppedData;

      console.log('213');
      if (image) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          this.iconPreview = reader.result as string;
        };
      }
    });
  }

  @Input() form?: FormGroup<ServiceExtForm>;

  get title(): string | undefined {
    return this.form?.getRawValue().title.length == 0
      ? this.form?.getRawValue().service?.name
      : this.form?.getRawValue().title;
  }

  get description(): string | undefined {
    return this.form?.getRawValue().description.length == 0
      ? this.form?.getRawValue().service?.description
      : this.form?.getRawValue().description;
  }

  iconPreview?: string;

  get cost(): string | undefined {
    return this.form?.getRawValue().service?.cost.toFixed(2);
  }

  private get pocketAttributes(): SmotreshkaPocketFormOutput | undefined {
    if (this.form?.getRawValue().attributes instanceof SmotreshkaPocketFormOutput) {
      return this.form?.getRawValue().attributes as SmotreshkaPocketFormOutput;
    }
    return undefined;
  }
}
