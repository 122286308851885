<div class="mb-3" [formGroup]="formGroup">
  <label class="form-label required">Заполните график работы офиса</label>
  <div formArrayName="items" *ngFor="let item of formGroup.controls.items.controls">
    <div [formGroup]="item">
      <div class="row align-items-center time-row">
        <div class="col-lg-3">
          {{ WeekdayLocalized.localized(item.getRawValue().weekDay) }}
        </div>
        <div class="col-lg-3">
          <input
            [attr.disabled]="item.controls.isDayOff.value ? '' : null"
            formControlName="openTime"
            type="time"
            class="form-control"
            placeholder="Начало работы"
          />
        </div>
        <div class="col-lg-3">
          <input
            [attr.disabled]="item.controls.isDayOff.value ? '' : null"
            formControlName="closeTime"
            type="time"
            class="form-control"
            placeholder="Окончание работы"
          />
        </div>
        <div class="col-lg-3">
          <label class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="isDayOff" />
            <span class="form-check-label">Выходной</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
