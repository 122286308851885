<app-stats-container title="Отправлено СМС" [amount]="this.amount">
  <div class="card">
    <div class="card-body">
      <apx-chart
        [series]="this.series"
        [chart]="{
          type: 'bar',
          height: 200,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        }"
        [fill]="{
          colors: ['#066FD1'],
        }"
        [dataLabels]="this.dataLabels"
        [plotOptions]="plotOptions"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
      ></apx-chart>
    </div>
  </div>
</app-stats-container>
