import { Component, OnInit, SkipSelf } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  ɵFormGroupRawValue,
  ɵTypedOrUntyped,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BannerRepository } from '../../data/repositories/banner-repository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Router } from '@angular/router';
import { FileInputComponent } from '../../../../../entity/file-input/file-input.component';
import { ImagePreviewWithButtonComponent } from '../../../../components/image-preview-with-button/image-preview-with-button.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { AttachmentType } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/attachment-repository';
import {
  BannerForm,
  BannerFormComponent,
  BannerFormDelegate,
} from '../../components/forms/banner-form/banner-form';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { Task } from '../../../../../core/utils/task';
import { BannerCreateRequest } from '../../domain/banner-create-request';
import { AppLinkRepository } from '../../../../../entity/app-link/data/repo/app-link-repository';
import { AppLinkCreate } from '../../../../../entity/app-link/domain/app-link-create';
import { ActionButtonsRepository } from '../../../../../entity/action-button/data/repo/action-buttons-repository';
import { ActionButtonCreateRequest } from '../../../../../entity/action-button/domain/action-button-create-request';
import { BehaviorSubject } from 'rxjs';
import { ActionButton } from '../../../../../entity/action-button/domain/action-button';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';

@Component({
  selector: 'app-create-banner',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    FileInputComponent,
    ImagePreviewWithButtonComponent,
    ImageCropperComponent,
    BannerFormComponent,
  ],
  providers: [ModalService],
  templateUrl: './banners-create.component.html',
  styleUrl: './banners-create.component.css',
})
export class BannerCreateComponent implements OnInit, BannerFormDelegate {
  public bannersCreateStatus: BehaviorSubject<Loadable<Banner>> = new BehaviorSubject(
    Loadable.notRequested(),
  );

  pageTitle: string = 'Добавление баннера';
  pageSubtitle: string = 'Баннеры';

  constructor(
    private bannersRepo: BannerRepository,
    private attachmentRepo: AttachmentRepository,
    private appLinkRepository: AppLinkRepository,
    private actionButtonRepo: ActionButtonsRepository,
    public router: Router,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.bannersCreateStatus.subscribe((t) => {
      switch (t.status) {
        case UIStateType.Success:
          new Task(async () => {
            await this.router.navigate(['banners']);

            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: 'Баннер успешно создан',
            });
          });
          break;

        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: t.message!,
          });
          break;
      }
    });
  }

  didSubmitForm(form: FormGroup<BannerForm>) {
    if (form.valid) {
      new Task(async () => {
        this.bannersCreateStatus.next(Loadable.loading());

        let request = await this.mapToRequest(form.getRawValue());
        let response = await this.bannersRepo.create(request);

        this.bannersCreateStatus.next(Loadable.getFromDataStatus(response));
      });
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля.',
      });
    }
  }

  async mapToRequest(
    form: ɵTypedOrUntyped<BannerForm, ɵFormGroupRawValue<BannerForm>, any>,
  ): Promise<BannerCreateRequest> {
    let createAppLink = Loadable.getFromDataStatus(
      await this.appLinkRepository.create(
        new AppLinkCreate(
          form.appLink!.appLinkType,
          form.appLink!.deepLink,
          form.appLink!.httpLink,
        ),
      ),
    );

    let createAttachment = await this.attachmentRepo.createOnlyResult(
      form.image!.croppedData!,
      AttachmentType.BANNER,
    );

    let createActionButton: Loadable<ActionButton> | null = null;
    if (form.buttonTitle) {
      createActionButton = Loadable.getFromDataStatus(
        await this.actionButtonRepo.create(
          new ActionButtonCreateRequest(form.buttonTitle!, createAppLink.data.id),
        ),
      );
    }

    return new BannerCreateRequest(
      form.title,
      form.description,
      createAttachment.id,
      createAppLink.data.id,
      createActionButton ? createActionButton.data.id : null,
    );
  }

  protected readonly UIStateType = UIStateType;
}
