import { Attachment } from '../../../../entity/attachments/domain/attachment';
import { ActionButton } from '../../../../entity/action-button/domain/action-button';
import { AppLink } from '../../../../entity/app-link/domain/app-link';

export class Banner {
  constructor(
    public id: number,
    public title: string,
    public description: string | null,
    public imageId: number | null,
    public appLinkId: number | null,
    public actionButtonId: number | null,
    public image: Attachment | null,
    public appLink: AppLink | null,
    public actionButton: ActionButton | null,
  ) {}

  public getBackgroundImage(): string {
    return 'background-image: url(' + this.image?.content.attributes.contentPath + ')';
  }
}
