import { DeeplinkCreate } from '../domain/deeplink-create';
import { DeeplinkCreateApiDto } from '../data/dto/deeplink-create-api-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeeplinkCreateMapper {
  toDto(domain: DeeplinkCreate): DeeplinkCreateApiDto {
    return new DeeplinkCreateApiDto(domain.link, domain.title, domain.description);
  }
}
