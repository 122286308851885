<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить диплинк'"
    (click)="router.navigate(['/deeplink-list/create'])"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-loadable-wrapper
    [loadable]="deeplinkList"
    loadingTitle="Загрузка диплинков..."
    [errorRepeatAction]="fetchDeeplinks"
  >
    <app-pagination-view
      [data]="deeplinkList"
      [perPage]="perPage"
      [pageNumber]="pageNumber"
      [delegate]="this"
    >
      <table class="table table-vcenter card-table">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <th class="text-center">Название</th>
            <th class="text-center">Ссылка</th>
            <th class="text-center">Описание</th>
            <th class="w-1"></th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let item of deeplinkList.safeData?.data">
            <tr app-deeplink-list-item [item]="item" [didDeleteItem]="didDeleteItem"></tr>
          </ng-container>
        </tbody>
      </table>
    </app-pagination-view>
  </app-loadable-wrapper>
</app-page-wrapper>
