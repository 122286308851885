<div class="d-block">
  <label [attr.for]="componentId" class="form-label" [ngClass]="{ 'required': isRequired }"
    >{{ label }} <span style="color: #929dab" *ngIf="!isRequired">(опционально)</span></label
  >

  <div class="form-control-container">
    <div class="input-icon" *ngIf="!selectedTariff">
      <input
        [attr.id]="componentId"
        [ngModel]="searchText$.value"
        (ngModelChange)="inputOnChange($event)"
        class="form-control"
        placeholder="{{
          placeholder.length == 0 ? 'Введите название услуги из биллинга' : placeholder
        }}"
        (focusout)="didChangeFocus(false)"
        (focusin)="didChangeFocus(true)"
      />
      <span class="input-icon-addon">
        <i *ngIf="tariffs?.status != UIStateType.Loading; else loading" class="ti ti-search"></i>
        <ng-template #loading>
          <div class="spinner-border spinner-border-sm text-secondary" role="status"></div>
        </ng-template>
      </span>
    </div>

    <div *ngIf="selectedTariff" class="input-icon">
      <div class="input-group input-group-flat">
        <input class="form-control" [ngModel]="selectedTariff.tariffInfo.name" disabled />
        <span class="input-group-text">
          <button type="button" class="clear-search-btn" (click)="clearTariff()">
            <i class="ti ti-x"></i>
          </button>
        </span>
      </div>
    </div>

    <div
      *ngIf="tariffs && tariffs.status == UIStateType.Success && tariffs.data && !selectedTariff"
      class="array-result"
    >
      <div
        *ngFor="let service of tariffs.data; let i = index"
        class="array-result-item"
        (click)="didSelectService(service)"
      >
        <div class="item-id">ID:{{ service.tariffInfo.tariffId }}</div>
        <div class="item-name">{{ service.tariffInfo.name }}</div>
      </div>
    </div>
  </div>
</div>
