<div class="card">
  <div class="card-body" *ngIf="!data">
    <label class="form-label">{{ label }}</label>

    <div class="container-fluid image-select">
      <input
        class="form-control"
        type="file"
        [attr.id]="componentId"
        (change)="fileBrowseHandler($event)"
        [accept]="'image/*, video/*'"
        aria-describedby="validationImageFeedback"
      />
      <span class="dnd-text">Выберите фото или просто перетащите его в это окно</span>
      <label [attr.for]="componentId">Выбрать файл</label>
    </div>
  </div>

  <div class="card-body" *ngIf="data">
    <div class="files-list">
      <div class="single-file">
        <div class="info">
          <h4 class="name">
            <i class="ti ti-file"></i>
            {{ data.file?.name ?? data.attachment?.content?.attributes?.filename }}
          </h4>
        </div>
        <div class="delete">
          <button class="btn btn-danger remove-icon-btn" (click)="removeData()">
            <i class="ti ti-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="this.recommendationInfo.length > 0" class="mt-3 information_doc card-footer">
    <div class="row">
      @for (item of this.recommendationInfo; track item.title) {
        <div class="col">
          <div class="file-req-header">{{ item.title }}</div>
          <div class="file-req-text" [innerHTML]="this.convert(item.message)"></div>
        </div>
      }
    </div>
  </div>
</div>
