<footer class="footer footer-transparent d-print-none">
  <div class="container-fluid">
    <div class="row text-center align-items-center flex-row-reverse">
      <div class="col-lg-auto ms-lg-auto">
        <ul class="list-inline list-inline-dots mb-0">
          <li class="list-inline-item">
            <a href="https://tabler.io/docs" target="_blank" class="link-secondary" rel="noopener"
              >Documentation</a
            >
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-auto mt-3 mt-lg-0">
        <ul class="list-inline list-inline-dots mb-0">
          <li class="list-inline-item">
            Copyright &copy; 2024
            <a href="" class="link-secondary">Tabler</a>. All rights reserved.
          </li>
          <li class="list-inline-item">Орион Телеком ESYS-Dashboard</li>
        </ul>
      </div>
    </div>
  </div>
</footer>
