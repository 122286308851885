import { AppLinkApiDto } from '../dto/app-link-api-dto';
import { AppLink } from '../../domain/app-link';
import { AppLinkTypeEnum } from '../../domain/app-link-create';
import { DeeplinkMapper } from '../../../../features/dashboard/deeplinks/mappers/deeplink-mapper';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppLinkMapper {
  constructor(private deeplinkMapper: DeeplinkMapper) {}
  toDomain(dto: AppLinkApiDto): AppLink {
    console.log(dto);

    let appLinkType = AppLinkTypeEnum.fromString(dto.type);
    return new AppLink(
      dto.id,
      appLinkType,
      dto.deeplink_id,
      dto.http_link,
      dto.deeplink ? this.deeplinkMapper.toDomain(dto.deeplink) : null,
    );
  }
}
