import { PaginationResponse } from '../../domain/pagination-response';
import { PaginationResponseDto } from '../../../../core/dashboard/data/dto/pagination-response.dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PaginationResponseItemMapper {
  toDomain<T, Dto>(
    dto: PaginationResponseDto<Dto>,
    iterator: (item: Dto) => T,
  ): PaginationResponse<T> {
    return new PaginationResponse<T>(
      dto.pages,
      dto.count,
      dto.per_page,
      dto.data.map((item) => {
        return iterator(item);
      }),
    );
  }
}
