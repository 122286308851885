import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-select-field',
  standalone: true,
  imports: [NgClass, NgForOf, FormsModule, NgIf, ReactiveFormsModule],
  templateUrl: './select-field.component.html',
  styleUrl: './select-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectFieldComponent,
    },
  ],
})
export class SelectFieldComponent implements ControlValueAccessor, OnInit {
  private static id: number = 0;
  componentId = `select-field-${SelectFieldComponent.id++}`;

  @Input() selectValues: SelectFieldValue<any>[] = [];
  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';

  private _selectedValue: SelectFieldValue<any> | null = null;

  protected selectFormControl: FormControl<number | null> = new FormControl(null);

  public get selectedValue(): SelectFieldValue<any> | null {
    return this._selectedValue;
  }

  public set selectedValue(newValue: SelectFieldValue<any> | null) {
    console.log(newValue);
    this._selectedValue = newValue;
    this.setToFormControl(newValue);

    if (this.onChange) {
      this.onChange(this._selectedValue?.value);
    }
  }

  private setToFormControl(value: SelectFieldValue<any> | null) {
    let selectedValue = this.selectValues.findIndex((t) => {
      return t.value == value?.value;
    });

    this.selectFormControl.setValue(selectedValue);
  }
  ngOnInit() {
    this.selectFormControl.valueChanges.subscribe((t) => {
      if (t) {
        if (this.selectValues[t] != this.selectedValue) {
          this.selectedValue = this.selectValues[t];
        }
      }
    });
  }

  private onChange?: (value: any) => void;

  registerOnChange(fn: (value: SelectFieldValue<any>) => void): void {
    console.log('registerOnChange');
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    console.log(obj);
    console.log('writeValue');
    let selectedValue = this.selectValues.find((t) => {
      return t.value == obj;
    });
    //
    // console.log(selectedValue)
    //
    this.selectedValue = selectedValue ?? null;
  }
}

export type SelectFieldValue<T> = {
  value: T;
  selectTitle: string;
};
