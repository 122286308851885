import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ServiceExtensionAttributes } from '../../../domain/entities/service-extension';
import { ServiceExtensionType } from '../../../domain/entities/service-type';
import { GeneralServiceExtFormComponent } from './general-service-ext-form/general-service-ext-form.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { SmotreshkaCinemaExtFormComponent } from './smotreshka-cinema-ext-form/smotreshka-cinema-ext-form.component';
import { AttachmentCropperData } from '../../../../../components/common/form-fields/attachment-cropper-field/attachment-cropper-field.component';
import { OutputData } from '@editorjs/editorjs';
import { SmotreshkaPocketExtFormComponent } from './smotreshka-pocket-ext-form/smotreshka-pocket-ext-form.component';
import { CatvPocketExtFormComponent } from './ctv-pocket-ext-form/catv-pocket-ext-form.component';

@Component({
  selector: 'app-service-extension-attributes',
  standalone: true,
  imports: [
    GeneralServiceExtFormComponent,
    NgIf,
    SmotreshkaCinemaExtFormComponent,
    NgSwitch,
    NgSwitchCase,
    SmotreshkaPocketExtFormComponent,
    CatvPocketExtFormComponent,
  ],
  templateUrl: './service-extension-attributes.component.html',
  styleUrl: './service-extension-attributes.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ServiceExtensionAttributesComponent,
    },
  ],
})
export class ServiceExtensionAttributesComponent
  implements ControlValueAccessor, ServiceExtensionAttributesDelegate
{
  @Input()
  extensionType: ServiceExtensionType | null = null;

  private onChange?: (attr: AttributedFormOutput | null) => void;
  private _currenAttributes: AttributedFormOutput | null = null;

  set currentAttributes(newValue: AttributedFormOutput | null) {
    this._currenAttributes = newValue;

    if (this.onChange) {
      this.onChange(this._currenAttributes);
    }
  }
  get currentAttributes(): ServiceExtensionAttributes | null {
    return this._currenAttributes;
  }

  registerOnChange(fn: (attr: AttributedFormOutput | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: AttributedFormOutput | null): void {
    this.currentAttributes = obj;
  }

  protected readonly ServiceExtensionType = ServiceExtensionType;

  onChangeValue(output: AttributedFormOutput | null): void {
    this.currentAttributes = output;

    console.log(output);
  }
}

export interface ServiceExtensionAttributesDelegate {
  onChangeValue: (output: AttributedFormOutput | null) => void;
}

export interface AttributedFormOutput {}
export class SmotreshkaCinemaAttributesFormOutput implements AttributedFormOutput {
  constructor(
    public banner: AttachmentCropperData,
    public icon: AttachmentCropperData,
  ) {}
}

export class GeneralAttributesFormOutput implements AttributedFormOutput {
  constructor(
    public banner: AttachmentCropperData,
    public text: OutputData | null,
  ) {}
}

export class SmotreshkaPocketFormOutput implements AttributedFormOutput {
  constructor(public cover: AttachmentCropperData) {}
}

export class CATVPocketFormOutput implements AttributedFormOutput {
  constructor(public numberOfChannels: number) {}
}
