import { NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { StoryCreateForm } from '../story-create-form/story-create-form.component';

@Component({
  selector: 'app-mobile-story-preview-preview',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent, PreviewBoxComponent],
  templateUrl: './mobile-story-preview-preview.component.html',
  styleUrl: './mobile-story-preview-preview.component.css',
})
export class MobileStoryPreviewPreviewComponent implements OnInit {
  @Input() public form?: FormGroup<StoryCreateForm>;

  previewImage: string | null = null;

  public ngOnInit(): void {
    this.form?.valueChanges.subscribe((t) => {
      if (t?.preview?.croppedData) {
        const reader = new FileReader();
        reader.readAsDataURL(t.preview.croppedData);
        reader.onloadend = () => {
          this.previewImage = reader.result as string;
        };
      }
    });
  }
}
