import { Injectable } from '@angular/core';
import { OutputData } from '@editorjs/editorjs';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';
import { DeeplinkMapper } from '../../../deeplinks/mappers/deeplink-mapper';
import {
  CATVPacketAttributes,
  GeneralAttributes,
  ServiceExtension,
  ServiceExtensionAttributes,
  SmotreshkaCinemaAttributes,
  SmotreshkaPacketAttributes,
} from '../../domain/entities/service-extension';

import { ServiceExtensionType } from '../../domain/entities/service-type';
import {
  CATVPacketAttributesDto,
  GeneralAttributesDto,
  ServiceExtAttributesDto,
  ServiceExtensionApiDto,
  SmotreshkaCinemaAttributesDto,
  SmotreshkaPacketAttributesDto,
} from '../dto/service-ext-api-dto';

@Injectable({ providedIn: 'root' })
export class ServiceExtensionMapper {
  constructor(
    private attachmentMapper: AttachmentMapper,
    private deeplinkMapper: DeeplinkMapper,
  ) {}

  public toDomain(dto: ServiceExtensionApiDto): ServiceExtension {
    let type = this.mapTypeFromString(dto.service_type);
    return new ServiceExtension(
      dto.id,
      dto.title,
      dto.description,
      dto.service_id,
      type,
      this.mapAttributesToDomain(dto.attributes, type),
    );
  }

  mapTypeFromString(type: string): ServiceExtensionType {
    return <ServiceExtensionType>(
      ServiceExtensionType[type.toUpperCase() as keyof typeof ServiceExtensionType]
    );
  }

  private mapAttributesToDomain(
    attributes: ServiceExtAttributesDto,
    type: ServiceExtensionType,
  ): ServiceExtensionAttributes {
    switch (type) {
      case ServiceExtensionType.CATV_PACKET:
        const attr = attributes as CATVPacketAttributesDto;
        return new CATVPacketAttributes(attr.number_of_channels);
      case ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA:
        let cinemaAttr = attributes as SmotreshkaCinemaAttributesDto;
        return new SmotreshkaCinemaAttributes(
          cinemaAttr.icon_id,
          cinemaAttr.banner_id,
          this.attachmentMapper.toDomain(cinemaAttr.banner),
          this.attachmentMapper.toDomain(cinemaAttr.icon),
        );

      case ServiceExtensionType.SMOTRESHKA_TV_PACKET:
        let pocketAttr = attributes as SmotreshkaPacketAttributesDto;
        return new SmotreshkaPacketAttributes(
          pocketAttr.pack_id,
          this.attachmentMapper.toDomain(pocketAttr.cover),
        );

      case ServiceExtensionType.GENERAL:
        let generalAttr = attributes as GeneralAttributesDto;
        return new GeneralAttributes(
          this.attachmentMapper.toDomain(generalAttr.banner),
          generalAttr.text as OutputData | null,
        );
    }
  }
}
