import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AutocompleteSearchInputResultComponent } from './autocomplete-search-input-result/autocomplete-search-input-result.component';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, Subject, switchMap } from 'rxjs';
import { PaginationResponse } from '../../../../../core/dashboard/domain/pagination-response';
import { CommonModule } from '@angular/common';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';

@Component({
  selector: 'app-autocomplete-search-input',
  standalone: true,
  templateUrl: './autocomplete-search-input.component.html',
  styleUrl: './autocomplete-search-input.component.css',
  imports: [AutocompleteSearchInputResultComponent, FormsModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSearchInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteSearchInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public focus: Boolean = false;

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public set value(v: any) {
    this.selectedValue = v;
    this.onChange(v);
  }

  public get value(): any {
    return this.selectedValue;
  }

  protected readonly UIStateType = UIStateType;

  // @ViewChild('inputElement') inputElement: ElementRef | null = null;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public withClearButton: boolean = false;
  @Input() public min = 3;
  @Input() public maxHeightTableResult: number = 500;
  @Input() public disabled: boolean = false;
  @Input() public listItemTemplate!: any;

  @Input() public delay = 500;
  @Input() public queryParams: any[] = [];

  @Output() foundEntityEmitter = new EventEmitter<any>();
  @Output() queryString = new EventEmitter<string>();

  @Input() currentFoundEntityName: string = '';

  @Input() public entities: Loadable<PaginationResponse<any>> | null = null;
  public selectedValue: any | null = null;

  public queryChange$: Subject<any> = new Subject();

  /**
   * Пути в выбранном объекте, для составления строки представления
   */
  @Input() public valuePathRepresentation: string[] = [];

  /**
   * Путь до поля в выбранном объекте, который будет содержаться в value
   */
  @Input() public ngValuePath: string | null = null;

  public clear() {
    this.entities = null;
  }

  public onSelectValue(entity: any | null) {
    this.onTouch();
    if (!entity) {
      return;
    }
    this.value = entity;
    this.clear();
    this.foundEntityEmitter.emit(entity);
  }

  clearSearchInput() {
    this.currentFoundEntityName = '';
    this.value = null;
  }

  ngOnDestroy(): void {
    this.queryChange$.unsubscribe();
  }

  ngOnInit(): void {
    this.queryChange$
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
        filter((query: string) => query.length >= this.min),
        switchMap((query: string) => {
          this.queryString.emit(query);
          return query;
        }),
      )
      .subscribe(
        (query: string) => {},
        (error: any) => {
          console.log(error);
        },
      );
  }

  public onFocus() {
    this.focus = true;
  }

  public onFocusOut() {
    this.focus = false;
  }
}
