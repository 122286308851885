import { Injectable } from '@angular/core';
import {
  CATVPacketCreateAttributes,
  GeneralCreateAttributes,
  SmotreshkaCinemaCreateAttributes,
  SmotreshkaPacketCreateAttributes,
} from '../../domain/entities/service-extension-create';
import {
  CATVPacketCreateAttributesDto,
  CreateServiceExtensionRequestDto,
  GeneralCreateAttributesDto,
  SmotreshkaCinemaCreateAttributesDto,
  SmotreshkaPacketCreateAttributesDto,
} from '../dto/create-service-ext-request-dto';
import {
  ServiceExtensionAttributes,
  ServiceExtensionCreateRequest,
} from '../../domain/entities/service-extension';
import { ServiceExtensionType } from '../../domain/entities/service-type';

@Injectable({ providedIn: 'root' })
export class CreateServiceExtRequestMapper {
  toData(value: ServiceExtensionCreateRequest): CreateServiceExtensionRequestDto {
    return {
      title: value.title,
      description: value.description,
      service_id: value.serviceId,
      service_type: value.serviceType.valueOf(),
      attributes: this.mapAttributes(value.attributes, value.serviceType)!,
    };
  }

  private mapAttributes(
    attributes: ServiceExtensionAttributes,
    type: ServiceExtensionType,
  ): SmotreshakAttrDTO | null {
    switch (type) {
      case ServiceExtensionType.CATV_PACKET:
        const attr = attributes as CATVPacketCreateAttributes;
        return {
          number_of_channels: attr.numberOfChannels,
        };
      case ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA:
        let cinemaAttr = attributes as SmotreshkaCinemaCreateAttributes;
        return {
          icon_id: cinemaAttr.iconId,
          banner_id: cinemaAttr.bannerId,
        };
      case ServiceExtensionType.SMOTRESHKA_TV_PACKET:
        let pocketAttr = attributes as SmotreshkaPacketCreateAttributes;
        return {
          cover_id: pocketAttr.coverId,
        };

      case ServiceExtensionType.GENERAL:
        let generalAttr = attributes as GeneralCreateAttributes;
        return {
          text: JSON.stringify(generalAttr.text),
        };

      default:
        return null;
    }
  }
}

export type SmotreshakAttrDTO =
  | SmotreshkaCinemaCreateAttributesDto
  | SmotreshkaPacketCreateAttributesDto
  | GeneralCreateAttributesDto
  | CATVPacketCreateAttributesDto;
