<div class="card">
  <div class="card-body">
    <div class="card-stats-top-line">
      <h5 class="card-stats-title">
        {{ title }}
      </h5>

      <span class="period">30 дн.</span>
    </div>
    <span class="card-stats-amount">{{ amount }}</span>
    <ng-content></ng-content>
  </div>
</div>
