import { Story, StorySlide } from '../../domain/story';
import { StoryApiDto } from '../dto/stories-dto';
import { Injectable } from '@angular/core';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';
import { ActionButtonMapper } from '../../../../../entity/action-button/data/mappers/action-button-mapper';
import { StorySlideMapper } from './story-slide';

@Injectable({ providedIn: 'root' })
export class StoryMapper {
  constructor(
    private actionButtonMapper: ActionButtonMapper,
    private attachmentMapper: AttachmentMapper,
    private storySlideMapper: StorySlideMapper,
  ) {}

  public toDomain(dto: StoryApiDto): Story {
    return new Story(
      dto.id,
      dto.name,
      new Date(dto.created_at),
      new Date(dto.updated_at),
      new Date(dto.created_by),
      dto.updated_by ? new Date(dto.updated_by) : null,
      this.attachmentMapper.toDomain(dto.preview),
      dto.analytics_target_name,
      dto.story_views,
      dto.story_likes,
      dto.annotation,
      dto.slides.map((t) => {
        return this.storySlideMapper.toDomain(t);
      }),
      dto.is_active,
      new Date(dto.deactivated_at),
      dto.admin_creator,
      dto.admin_editor,
    );
  }
}
