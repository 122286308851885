<app-mobile-preview>
  <div class="full-desc-container">
    <div class="preview-body">
      <img class="bottomsheet-controls" src="assets/dist/img/bottomsheet_controls.png" />

      <div class="cover" style="background-image: url(&quot;{{ bannerPreview }}&quot;)">
        <div *ngIf="bannerPreview == undefined" class="cover-preview"></div>
      </div>

      <div class="full-desc-text">
        <div *ngIf="title == undefined" class="title-placeholder">Название услуги</div>
        <div *ngIf="title != undefined" class="title">{{ title }}</div>

        <div *ngIf="cost == undefined" class="cost-placeholder">Стоимость</div>
        <div *ngIf="cost != undefined" class="cost">{{ cost }} ₽/мес.</div>

        <div *ngIf="fullDescription == undefined" class="desc-placeholder">
          Подробное описание услуги
        </div>
        <div class="desc">
          <div [innerHTML]="fullDescription"></div>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>
