import { Component, Input, SkipSelf, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { Deeplink } from '../../../domain/deeplink';
import { Router } from '@angular/router';
import { DeeplinkRepository } from '../../../data/repo/deeplink-repository';
import { ModalService } from '../../../../../components/common/modal-service/modal-service';
import { ModalType } from '../../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { Task } from '../../../../../../core/utils/task';

@Component({
  selector: '[app-deeplink-list-item]',
  standalone: true,
  imports: [NgIf],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './deeplink-list-item.component.html',
  styleUrl: './deeplink-list-item.component.css',
})
export class DeeplinkListItemComponent {
  @Input() item?: Deeplink;
  @Input() didDeleteItem?: () => void;

  deeplinkDeleteStatus: Loadable<void> = Loadable.notRequested();
  constructor(
    private router: Router,
    private deeplinkRepository: DeeplinkRepository,
    @SkipSelf() private modalService: ModalService,
  ) {}

  didClickUpdate() {
    this.router.navigate([`deeplink-list/update/${this.item?.id}`]).finally();
  }

  didClickDelete() {
    new Task(async () => {
      await this.deleteItem();
    });
  }

  private async deleteItem() {
    if (this.deeplinkDeleteStatus.status != UIStateType.Loading && this.item) {
      this.deeplinkDeleteStatus = Loadable.loading();

      let response = await this.deeplinkRepository.delete(this.item.id);

      this.deeplinkDeleteStatus = Loadable.getFromDataStatus(response);

      if (this.deeplinkDeleteStatus.status == UIStateType.Error) {
        this.modalService.createModal({
          type: ModalType.DANGER,
          message: this.deeplinkDeleteStatus.message!,
        });
      }

      if (this.deeplinkDeleteStatus.status == UIStateType.Success && this.didDeleteItem) {
        this.didDeleteItem();
      }
    }
  }

  protected readonly UIStateType = UIStateType;
}
