<div class="mb-3" [formGroup]="formGroup">
  <label class="form-label required">Номера телефонов</label>
  <div formArrayName="contacts" *ngFor="let item of contacts.controls; let i = index">
    <div [formGroupName]="i">
      <div class="row phone-row mb-2">
        <div class="col-md-10">
          <div class="input-group input-group-flat">
            <span class="input-group-text">+7</span>
            <input
              class="form-control"
              formControlName="phone"
              placeholder="Введите контактный номер телефона"
              mask="(000)-000-00-00"
            />
          </div>
        </div>
        <div class="col-md-2">
          <button type="button" class="btn btn-link preview-btn" (click)="deleteContact(i)">
            <i class="ti ti-x" style="font-size: 24px"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-link" (click)="addContacts()">
    <i class="ti ti-plus"></i> Добавить еще один номер
  </button>
</div>
