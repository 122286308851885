<app-stats-container title="Отправлено пушей" [amount]="this.amount">
  <apx-chart
    [series]="this.series"
    [chart]="{
      type: 'bar',
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    }"
    [fill]="{
      colors: ['#066FD1'],
    }"
    [dataLabels]="this.dataLabels"
    [plotOptions]="plotOptions"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
  ></apx-chart>
</app-stats-container>
