import { Component, OnInit, SkipSelf } from '@angular/core';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { FormGroup, ɵFormGroupRawValue, ɵTypedOrUntyped } from '@angular/forms';
import {
  DeeplinkCreateForm,
  DeeplinkFormDelegate,
  DeeplinksCreateFormComponent,
} from '../components/deeplinks-create-form/deeplinks-create-form.component';
import { DeeplinkRepository } from '../../data/repo/deeplink-repository';
import { ModalService } from '../../../../components/common/modal-service/modal-service';
import { DeeplinkCreate } from '../../domain/deeplink-create';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { Deeplink } from '../../domain/deeplink';
import { ModalType } from '../../../../components/common/modal-service/modal-component/domain/modal-params-interface';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deeplink-create-page',
  standalone: true,
  imports: [
    BreadcrumbsComponent,
    PageHeaderComponent,
    PageWrapperComponent,
    DeeplinksCreateFormComponent,
  ],
  templateUrl: './deeplink-create-page.component.html',
  styleUrl: './deeplink-create-page.component.css',
})
export class DeeplinkCreatePageComponent implements DeeplinkFormDelegate, OnInit {
  pageSubtitle = 'Диплинки';
  pageTitle = 'Добавление диплинка';

  createDeeplinkStatus$ = new BehaviorSubject<Loadable<Deeplink>>(Loadable.notRequested());

  constructor(
    private router: Router,
    private deeplinkRepository: DeeplinkRepository,
    @SkipSelf() private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.createDeeplinkStatus$.subscribe((t) => {
      switch (t.status) {
        case UIStateType.Success:
          this.router.navigate(['deeplink-list']).then(async () => {
            this.modalService.createModal({
              type: ModalType.SUCCESS,
              message: 'Диплинк успешно создан',
              acceptButtonAction: () => {},
            });
          });

          break;

        case UIStateType.Error:
          this.modalService.createModal({
            type: ModalType.DANGER,
            message: t.message!,
          });
          break;
      }
    });
  }

  didCreateForm(form: FormGroup<DeeplinkCreateForm>): void {}

  didSubmitForm(form: FormGroup<DeeplinkCreateForm>): void {
    this.validateAndSendForm(form).finally();
  }

  private async validateAndSendForm(form: FormGroup<DeeplinkCreateForm>) {
    if (form.valid) {
      let domain = this.mapToDomain(form.getRawValue());

      this.createDeeplinkStatus$.next(Loadable.loading());
      let response = await this.deeplinkRepository.create(domain);

      this.createDeeplinkStatus$.next(Loadable.getFromDataStatus(response));
    } else {
      this.modalService.createModal({
        type: ModalType.DANGER,
        message: 'Заполнены не все обязательные поля.',
      });
    }
  }

  private mapToDomain(
    value: ɵTypedOrUntyped<DeeplinkCreateForm, ɵFormGroupRawValue<DeeplinkCreateForm>, any>,
  ): DeeplinkCreate {
    return new DeeplinkCreate(value.link, value.title, value.description);
  }
}
