<div class="mb-3 mt-3">
  <label [attr.for]="componentId" class="form-label" [ngClass]="{ 'required': isRequired }"
    >{{ label }} <span style="color: #929dab" *ngIf="!isRequired">(опционально)</span></label
  >
  <select [attr.id]="componentId" class="form-select" required [formControl]="selectFormControl">
    <option disabled selected value="" *ngIf="placeholder">
      {{ placeholder }}
    </option>

    <option *ngFor="let value of selectValues; let i = index" [value]="i">
      {{ value?.selectTitle }}
    </option>
  </select>
</div>
