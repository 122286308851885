<div class="d-block">
  <label
    [attr.for]="componentId"
    class="form-label"
    [ngClass]="{ 'required': isRequired }"
    *ngIf="label.length > 0"
    >{{ label }} <span style="color: #929dab" *ngIf="!isRequired">(опционально)</span></label
  >
  <input
    [attr.id]="componentId"
    class="form-control"
    [attr.placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="updateValue($event)"
  />
</div>
