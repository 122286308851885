<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить сервис'"
    [routerLink]="['create-abonent-service']"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-loadable-wrapper [loadable]="abonentServices" loadingTitle="Загрузка данных...">
    <div class="row row-cards">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Порядок отображения у пользователя</h3>
            <div class="ms-auto text-secondary">
              <div class="btn-list flex-nowrap">
                <button
                  *ngIf="!isEditButtonsActive"
                  class="btn-link"
                  (click)="activateEditButtons()"
                >
                  Редактировать
                </button>

                <button
                  *ngIf="isEditButtonsActive"
                  class="btn btn-primary"
                  (click)="activateEditButtons(); saveOrdering()"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>

          <div
            *ngIf="abonentServices.safeData"
            cdkDropList
            [cdkDropListDisabled]="!isEditButtonsActive"
            [cdkDropListData]="abonentServices.data"
            class="list-group list-group-flush overflow-scroll"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              *ngFor="let item of abonentServices.data"
              [cdkDragData]="item"
              class="list-group-item"
              cdkDrag
            >
              <div class="row">
                <div class="col-auto">
                  <span class="service-image" [style]="item.getBackgroundImage()"></span>
                </div>

                <div class="col text-truncate">
                  <div class="text-body d-block">{{ item.title }}</div>
                  <div class="text-secondary text-truncate mt-n1">
                    {{ item.short_description }}
                  </div>
                </div>

                <div class="col-auto">
                  <div class="btn-list flex-nowrap h-100 justify-content-center">
                    <div
                      *ngIf="deleteServiceStatus == UIStateType.Loading && isEditButtonsActive"
                      class="d-flex align-content-center align-items-center"
                    ></div>

                    <button
                      href="#"
                      *ngIf="isEditButtonsActive"
                      class="btn btn-link"
                      (click)="onDelete(item.id)"
                    >
                      <div class="spinner-border" *ngIf="deleteItemsId.includes(item.id)"></div>
                      <i
                        class="ti ti-trash"
                        style="color: red"
                        *ngIf="!deleteItemsId.includes(item.id)"
                      ></i>
                    </button>

                    <button class="btn btn-link" *ngIf="isEditButtonsActive">
                      <i class="ti ti-selector"></i>
                    </button>

                    <a
                      *ngIf="!isEditButtonsActive"
                      [routerLink]="['update-abonent-service', item.id]"
                      [state]="item"
                      class="btn btn-link"
                    >
                      <i class="ti ti-edit"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Предпросмотр списка в приложении</h3>
          </div>
          <div class="card-body">
            <app-mobile-abonent-services-list
              [title]="'Сервисы'"
              [iterable]="abonentServices.safeData ?? []"
            ></app-mobile-abonent-services-list>
          </div>
        </div>
      </div>
    </div>
  </app-loadable-wrapper>
</app-page-wrapper>
