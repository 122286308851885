import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgForOf } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-orion-ofiice-contacts-field',
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf, NgxMaskDirective],
  templateUrl: './orion-office-contacts-field.component.html',
  styleUrl: './orion-office-contacts-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OrionOfficeContactsFieldComponent,
    },
  ],
})
export class OrionOfficeContactsFieldComponent implements OnInit, ControlValueAccessor {
  formGroup!: FormGroup;

  private onChange?: (values: OrionOfficeContactFormValue[]) => void;

  constructor(private fb: NonNullableFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      contacts: this.fb.array([]),
    });

    this.formGroup.valueChanges.subscribe((t) => {
      let values = this.contacts.getRawValue().map((t) => {
        return new OrionOfficeContactFormValue(t['phone']);
      });

      if (this.onChange) {
        this.onChange(values);
      }
    });
  }

  get contacts(): FormArray {
    return this.formGroup.get('contacts') as FormArray;
  }

  newContactsItem() {
    return this.fb.group({
      phone: new FormControl<string>('', Validators.required),
    });
  }

  addContacts() {
    this.contacts.push(this.newContactsItem());
  }

  deleteContact(i: number) {
    this.contacts.removeAt(i);
  }

  registerOnChange(fn: (data: OrionOfficeContactFormValue[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: OrionOfficeContactFormValue[]): void {
    this.contacts.clear();

    obj.forEach((t) => {
      this.contacts.push(
        this.fb.group({
          phone: new FormControl<string>(t.phone, Validators.required),
        }),
      );
    });
  }
}

export class OrionOfficeContactFormValue {
  constructor(public phone: string) {}
}
