<div
  class="modal modal-blur fade"
  [class.show]="showAnimation"
  tabindex="-1"
  [style.display]="isShow ? 'block' : 'none'"
  aria-modal="true"
  [attr.role]="isShow ? 'dialog' : null"
>
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      <div
        class="modal-status"
        [ngClass]="
      {
      'bg-success': this.isSuccessAlert,
      'bg-danger': this.isDangerAlert
       }"
      ></div>
      <div class="modal-body text-center py-4">
        <svg
          *ngIf="isSuccessAlert"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon mb-2 text-green icon-lg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
          <path d="M9 12l2 2l4 -4"></path>
        </svg>

        <svg
          *ngIf="isDangerAlert"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon mb-2 text-danger icon-lg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 9v4"></path>
          <path
            d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"
          ></path>
          <path d="M12 16h.01"></path>
        </svg>

        <h3>{{ modalConfig?.header ?? this.defaultHeader}}</h3>
        <div class="text-secondary" *ngIf="modalConfig?.message">{{ modalConfig?.message }}</div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link link-secondary me-auto"
          (click)="close(this.modalConfig?.closeButtonAction)"
        >
          {{ modalConfig?.closeButtonText ?? 'Закрыть' }}
        </button>
        <button
          *ngIf="modalConfig?.acceptButtonText"
          type="button"
          class="btn btn-primary"
          (click)="close(this.modalConfig?.acceptButtonAction)"
        >
          {{ modalConfig?.acceptButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal-backdrop fade"
  [class.show]="showAnimation"
  tabindex="-1"
  [style.display]="isShow ? 'block' : 'none'"
></div>
