import { Deeplink } from '../../../features/dashboard/deeplinks/domain/deeplink';

export class AppLinkCreate {
  constructor(
    public type: AppLinkTypeEnum,
    public deeplink: Deeplink | null,
    public httpLink: string | null,
  ) {}
}
export enum AppLinkTypeEnum {
  HTTP_LINK = 'http_link',
  DEEPLINK = 'deeplink',
}

export namespace AppLinkTypeEnum {
  export function fromString(str: string): AppLinkTypeEnum {
    return str as AppLinkTypeEnum;
    // return <AppLinkTypeEnum>AppLinkTypeEnum[str as keyof typeof AppLinkTypeEnum];
  }
}
