<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-deeplinks-create-form
    [delegate]="this"
    [sendStatus]="updateDeeplinkStatus$.value.status"
  ></app-deeplinks-create-form>
</app-page-wrapper>
