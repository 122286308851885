import { AbonentService } from '../../domain/abonent-service';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { AbonentServiceMapper } from '../mappers/abonent-service';
import { DataStatus } from '../../../../../core/network/data.status';
import { AbonentServiceApiDto } from '../dto/abonent-service-dto';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { AbonentServiceCreateRequest } from '../../domain/abonent-service-create-request';
import { AbonentServiceUpdateRequest } from '../../domain/abonent-service-update-request';
import { AbonentServiceCreateRequestMapper } from '../mappers/abonent-service-create-request-mapper';
import { AbonentServiceUpdateRequestMapper } from '../mappers/abonent-service-update-request-mapper';

@Injectable({ providedIn: 'root' })
export class AbonentServiceRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private abonentServiceMapper: AbonentServiceMapper,
    private abonentServiceCreateRequestMapper: AbonentServiceCreateRequestMapper,
    private abonentServiceUpdateRequestMapper: AbonentServiceUpdateRequestMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAll(): Promise<DataStatus<AbonentService[]>> {
    let response: DataStatus<AbonentServiceApiDto[]> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services`,
    );
    return response.map((data) => {
      return data.map((item) => this.abonentServiceMapper.toDomain(item));
    });
  }

  async getById(service_id: number) {
    let response: DataStatus<AbonentServiceApiDto> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services/${service_id}`,
    );

    return response.map((data) => {
      return this.abonentServiceMapper.toDomain(data);
    });
  }

  async create(request: AbonentServiceCreateRequest): Promise<DataStatus<AbonentService>> {
    let body = this.abonentServiceCreateRequestMapper.toData(request);
    let response: DataStatus<AbonentServiceApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services`,
      body,
    );

    return response.map((t) => this.abonentServiceMapper.toDomain(t));
  }

  async delete(abonent_service_id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services/${abonent_service_id}`,
    );
  }

  async update(
    abonentServiceUpdateRequest: AbonentServiceUpdateRequest,
  ): Promise<DataStatus<AbonentService>> {
    let body = this.abonentServiceUpdateRequestMapper.toData(abonentServiceUpdateRequest);

    let response: DataStatus<AbonentServiceApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services/${abonentServiceUpdateRequest.id}`,
      body,
    );
    return response.map((t) => this.abonentServiceMapper.toDomain(t));
  }
}
