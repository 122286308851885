import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import {
  StorySlideFormItem,
  StorySlideFormOutput,
} from '../story-slide-form/story-slide-form.component';

@Component({
  selector: 'app-mobile-stories-preview',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent, NgIf],
  templateUrl: './mobile-stories-preview.component.html',
  styleUrl: './mobile-stories-preview.component.css',
})
export class MobileStoriesPreviewComponent {
  @Input() slide!: StorySlideFormOutput | null;
}
