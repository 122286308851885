import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { DataStatus } from '../../../../../core/network/data.status';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { OfficeContactMapper } from '../mappers/orion-office-contact';
import { OfficeContact } from '../../domain/orion-office-contact';
import { OfficeContactApiDto } from '../dto/orion-office-contact-dto';
import { OrionOfficeContactCreateRequest } from '../../domain/orion-office-contact-create-request';
import { OrionOfficeContactCreateRequestMapper } from '../mappers/orion-office-conntact-create-request-mapper';

@Injectable({ providedIn: 'root' })
export class OrionOfficesContactsRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private officeContactMapper: OfficeContactMapper,
    private contactCreateRequestMapper: OrionOfficeContactCreateRequestMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async getAllByOffice(office_id: number): Promise<DataStatus<OfficeContact[]>> {
    let payload = {
      'office_id': office_id,
    };

    let response: DataStatus<OfficeContactApiDto[]> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts`,
      payload,
    );

    return response.map((data) => data.map((item) => this.officeContactMapper.toDomain(item)));
  }

  async update(
    request: OrionOfficeContactCreateRequest,
    contact_id: number,
  ): Promise<DataStatus<OfficeContact>> {
    let response: DataStatus<OfficeContactApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts/${contact_id}`,
      this.contactCreateRequestMapper.toData(request),
    );

    return response.map((data) => this.officeContactMapper.toDomain(data));
  }

  async create(request: OrionOfficeContactCreateRequest): Promise<DataStatus<OfficeContact>> {
    let response: DataStatus<OfficeContactApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts`,
      this.contactCreateRequestMapper.toData(request),
    );

    return response.map((data) => this.officeContactMapper.toDomain(data));
  }

  async delete(contact_id: number): Promise<DataStatus<void>> {
    return await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices_contacts/${contact_id}`,
    );
  }
}
