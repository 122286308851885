<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-abonent-service-form
    [sendFormState]="abonentServiceChangeStatus$.value.status"
    [delegate]="sendFormDelegate"
  >
  </app-abonent-service-form>
</app-page-wrapper>
