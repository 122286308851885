<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-lg-6">
      <app-form-component [title]="'Карточка офиса'">
        <!--                <div class="mb-3">-->
        <!--                    <label class="form-label required">Выберите город</label>-->
        <!--                    <select-->
        <!--                        class="form-select"-->
        <!--                        formControlName="city"-->
        <!--                        (change)="getOfficeCityCoordinates($event)"-->
        <!--                    >-->
        <!--                        <option *ngFor="let enum of keys(officeCityType)" [value]="[enum]">-->
        <!--                            {{ getOfficeCityType(enum) }}-->
        <!--                        </option>-->
        <!--                    </select>-->
        <!--                </div>-->

        <app-select-field
          formControlName="city"
          label="Выберите город"
          [isRequired]="true"
          placeholder="Выберите город"
          [selectValues]="selectValues"
        ></app-select-field>

        <app-text-field
          class="mb-3"
          label="Название офиса"
          formControlName="title"
          placeholder="Введите название офиса, оно будет отображаться в списке в дашборде"
          [isRequired]="true"
        ></app-text-field>

        <app-text-field
          class="mb-3"
          label="Укажите адрес офиса"
          formControlName="address"
          placeholder="Введите название улицы, номер строения и номер офиса"
          [isRequired]="true"
        ></app-text-field>

        <div class="mb-3">
          <label class="form-label required">Укажите точку на карте</label>
          <div class="mb-3 map-container align-items-center justify-content-center px-0">
            <ya-map (yaclick)="onMapClick($event)" [center]="placemarkCoords" [state]="mapState">
              <ya-control type="SearchControl" [parameters]="parameters"></ya-control>
              <ya-placemark [geometry]="placemarkCoords"></ya-placemark>
            </ya-map>
          </div>
        </div>

        <hr class="divider" />

        <app-orion-business-hours-field
          class="mb-3"
          formControlName="businessDays"
        ></app-orion-business-hours-field>

        <app-orion-ofiice-contacts-field class="mb-3" formControlName="contacts">
        </app-orion-ofiice-contacts-field>
      </app-form-component>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Предпросмотр офиса в приложении</div>
        </div>
        <div class="card-body">
          <app-mobile-orion-office-preview [form]="this.formGroup">
          </app-mobile-orion-office-preview>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/orion-offices'])" class="btn btn-link"> Отмена </a>
            <button type="submit" class="btn btn-primary" name="submit_button" (click)="onSubmit()">
              <span
                *ngIf="formState == UIStateType.Loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
