import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-textarea-field',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, NgClass, NgIf],
  templateUrl: './textarea-field.component.html',
  styleUrl: './textarea-field.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaFieldComponent,
    },
  ],
})
export class TextareaFieldComponent implements ControlValueAccessor {
  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';

  private static id: number = 0;
  componentId = `text-field-${TextareaFieldComponent.id++}`;

  private _value: string = '';
  private onChange?: (value: string) => void;

  public get value(): string {
    return this._value;
  }

  public set value(newValue: string) {
    this._value = newValue;

    if (this.onChange) {
      this.onChange(this._value);
    }
  }

  updateValue(event: any) {
    this.value = event;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }
}
