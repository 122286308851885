import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { ImagePreviewWithButtonComponent } from '../../../../components/image-preview-with-button/image-preview-with-button.component';
import { FormGroup } from '@angular/forms';
import { BannerForm } from '../forms/banner-form/banner-form';

@Component({
  selector: 'app-mobile-banner-preview',
  standalone: true,
  imports: [
    NgFor,
    MobilePreviewComponent,
    PreviewBoxComponent,
    NgIf,
    ImagePreviewWithButtonComponent,
  ],
  templateUrl: './mobile-banner-preview.component.html',
  styleUrl: './mobile-banner-preview.component.css',
})
export class MobileBannerPreviewComponent implements OnInit {
  @Input() public form!: FormGroup<BannerForm>;

  backgroundImage: string | null = null;

  processImageToBase64(imageBlob: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onloadend = () => {
      this.backgroundImage = reader.result as string;
    };
  }

  ngOnInit() {
    this.form.controls.image.valueChanges.subscribe((t) => {
      if (t.croppedData) {
        this.processImageToBase64(t.croppedData);
      } else {
        this.backgroundImage = null;
      }
    });
  }
}
