<app-mobile-preview>
  <div class="catv-container">
    <div class="preview-header">
      <div class="preview-header-content">
        <div class="title">Услуги кабельное ТВ</div>
        <app-preview-box
          width="100%"
          height="64px"
          [cornerRadius]="12"
          style="margin-bottom: 12px"
        ></app-preview-box>
        <app-preview-box width="100%" height="35px" [cornerRadius]="6"></app-preview-box>
      </div>
    </div>
    <div class="preview-body">
      <div class="catv-item">
        <div class="catv-item-body">
          <div *ngIf="title == undefined" class="catv-item-title-placeholder">Название пакета</div>
          <div *ngIf="title != undefined" class="catv-item-title">{{ title }}</div>

          <div *ngIf="description == undefined" class="catv-item-secondary">
            Описание пакета каналов
          </div>
          <div *ngIf="description != undefined" class="catv-item-secondary">{{ description }}</div>
        </div>
        <hr />
        <div class="catv-item-footer">
          <div class="row" style="display: flex; align-items: center">
            <div *ngIf="cost != undefined" class="col-6 cost">{{ cost }} ₽/ мес.</div>
            <div *ngIf="cost == undefined" class="col-6 cost-placeholder">Стоимость</div>
            <div class="col-6">
              <div style="float: right">
                <input class="tgl tgl-flat" id="cb4" type="checkbox" />
                <label class="tgl-btn" for="cb4"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-preview-box
        width="100%"
        height="152px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="152px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="152px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
    </div>
  </div>
</app-mobile-preview>
