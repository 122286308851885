import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AutocompleteSearchInputResultComponent } from '../../inputs/autocomplete-search-input/autocomplete-search-input-result/autocomplete-search-input-result.component';
import { NgClass, NgComponentOutlet, NgForOf, NgIf } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Service } from '../../../../dashboard/services/domain/entities/service';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ServiceDefRepository } from '../../../../dashboard/services/data/repositories/service-def-repository';
import { ServiceDef } from '../../../../dashboard/services/domain/entities/service-def';

@Component({
  selector: 'app-service-select-field',
  standalone: true,
  imports: [
    AutocompleteSearchInputResultComponent,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgComponentOutlet,
    NgForOf,
    NgClass,
  ],
  templateUrl: './service-select-field.component.html',
  styleUrl: './service-select-field.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ServiceSelectFieldComponent,
    },
  ],
})
export class ServiceSelectFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private static id: number = 0;

  componentId = `service-select-field-${ServiceSelectFieldComponent.id++}`;

  protected services: Loadable<ServiceDef[]> = new Loadable<ServiceDef[]>({ kind: 'NotRequested' });
  protected isFocused: boolean = false;

  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';

  private onChange?: (value: ServiceDef | null) => void;
  private onTouched!: () => void;

  private _selectedService: ServiceDef | null = null;

  set selectedService(newValue: ServiceDef | null) {
    this._selectedService = newValue;

    if (this.onChange) {
      this.onChange(newValue);
    }
  }
  get selectedService(): ServiceDef | null {
    return this._selectedService;
  }

  protected searchText$ = new BehaviorSubject<string>('');

  constructor(private servicesRepository: ServiceDefRepository) {}

  didChangeFocus(isFocused: boolean) {
    this.isFocused = isFocused;
  }
  ngOnInit() {
    this.searchText$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value.length >= 2) {
        this.searchServices(value).finally();
        console.log(`str ${value}`);
      } else {
        this.services = new Loadable<ServiceDef[]>({ kind: 'NotRequested' });
      }
    });
  }

  ngOnDestroy() {
    this.searchText$.unsubscribe();
  }

  private async searchServices(value: string) {
    this.services = new Loadable<ServiceDef[]>({ kind: 'Loading' });

    let result = await this.servicesRepository.findAllByLikeSearchCriteria(value, 1, 20);
    this.services = Loadable.getFromDataStatus(
      result.map((data) => {
        return data.data;
      }),
    );
  }

  inputOnChange(str: string) {
    this.searchText$.next(str);
  }

  registerOnChange(fn: (value: ServiceDef | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  didSelectService(service: ServiceDef) {
    this.selectedService = service;
  }

  clearService() {
    this.selectedService = null;
  }

  writeValue(obj: Service | null): void {}

  protected readonly UIStateType = UIStateType;
  protected readonly console = console;
}
