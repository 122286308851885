<div class="preview-container px-0">
  <div class="preview-div">
    <img [src]="preview" class="preview-img img-fluid h-100" />
  </div>
  <button
    *ngIf="fakeBtnText != ''"
    id="preview-fake-btn"
    type="button"
    class="preview-fake-btn btn btn-primary"
  >
    {{ fakeBtnText }}
  </button>
</div>
