import { Inject, Injectable } from '@angular/core';
import { OAuthAccessToken } from '../../domain/entities/oauth-access-token';
import { DataStatus, DataStatusSuccess } from '../../../../core/network/data.status';
import { OAuthAccessTokenLocal } from '../../../../core/localData/oauth-access-token-local';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthTokenApiDto } from '../../data/api/dto/oauth-token-api-dto';
import { OAuthTokenMapper } from '../../data/api/dto/oauth-token-mapper';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { firstValueFrom } from 'rxjs';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { LocalStorage } from '../../../../core/storage/local-storage';

@Injectable({ providedIn: 'root' })
export class OAuthClient {
  config: IEnvironment;

  constructor(
    private httpClient: HttpClientWrapper,
    private oauthTokenLocal: OAuthAccessTokenLocal,
    private oauthTokenMapper: OAuthTokenMapper,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorage,
    @Inject(APP_CONFIG) config: IEnvironment,
  ) {
    this.config = config;
  }

  setUrlFromAuthStarted(url: string) {
    this.localStorage.setItem('url_from_auth_started', url);
  }
  getUrlFromAuthStarted(): string | null {
    let authStarted = this.localStorage.getItem('url_from_auth_started');
    this.localStorage.removeItem('url_from_auth_started');

    return authStarted;
  }

  private setAccessToken(token: OAuthAccessToken) {
    this.oauthTokenLocal.accessToken = token.accessToken;
    this.oauthTokenLocal.refreshToken = token.refreshToken;
    this.oauthTokenLocal.expiresAt = token.expiresAt;
  }

  public redirectToAuthPage(): void {
    this.setUrlFromAuthStarted(this.router.url);

    window.location.href = `${this.config.authUrl}/oauth/authorize?response_type=code&client_id=${this.config.clientId}&redirect_uri=${this.config.authRedirectUrl}&scope=*`;
  }

  async getTokens(): Promise<void> {
    const code = this.route.snapshot.queryParamMap.get('code');

    if (code != null) {
      const body = new FormData();
      body.set('method', 'POST');
      body.set('client_id', this.config.clientId.toString());
      body.set('redirect_uri', this.config.authRedirectUrl);
      body.set('client_secret', this.config.clientSecret);
      body.set('code', code);
      body.set('grant_type', 'authorization_code');

      let url = `${this.config.authUrl}/oauth/token`;
      let response: DataStatus<OAuthTokenApiDto> = await this.httpClient.post(url, body);

      if (response instanceof DataStatusSuccess) {
        let anotherResponse = response as DataStatusSuccess<OAuthTokenApiDto>;
        let oauthAccessToken: OAuthAccessToken = this.oauthTokenMapper.toDomain(
          anotherResponse.data,
        );
        this.setAccessToken(oauthAccessToken);
      } else {
        throw new Error('error getting tokens');
      }
    }
  }

  async refreshToken(): Promise<string> {
    const body = new FormData();
    let refreshToken: string | null = this.oauthTokenLocal.refreshToken;

    if (!refreshToken) {
      throw new Error('Refresh token is required');
    }

    body.set('client_id', this.config.clientId.toString());
    body.set('redirect_uri', this.config.authRedirectUrl);
    body.set('client_secret', this.config.clientSecret);
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refreshToken);

    let url = `${this.config.authUrl}/oauth/token`;
    let response: DataStatus<OAuthTokenApiDto> = await this.httpClient.post(url, body);

    if (response instanceof DataStatusSuccess) {
      let anotherResponse = response as DataStatusSuccess<OAuthTokenApiDto>;
      let oauthAccessToken: OAuthAccessToken = this.oauthTokenMapper.toDomain(anotherResponse.data);
      this.setAccessToken(oauthAccessToken);
      return oauthAccessToken.accessToken;
    } else {
      throw new Error('Error refresh token');
    }
  }

  async revokeToken(): Promise<void> {
    const accessTokenBody = new FormData();
    const accessToken: string | null = this.oauthTokenLocal.accessToken;

    accessTokenBody.set('client_id', this.config.clientId.toString());
    accessTokenBody.set('client_secret', this.config.clientSecret);
    accessTokenBody.set('token_type_hint', 'access_token');
    accessTokenBody.set('token', accessToken!);

    let url = `${this.config.authUrl}/oauth/revoke`;
    try {
      await this.httpClient.post(url, accessTokenBody);
      this.oauthTokenLocal.removeToken();
    } catch (error) {
      console.log(error);
    }
  }
}
