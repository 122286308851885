import { Injectable } from '@angular/core';
import { BannerCreateRequest } from '../../domain/banner-create-request';
import { BannerCreateRequestDto } from '../dto/banner-create-request-dto';

@Injectable({ providedIn: 'root' })
export class BannerCreateRequestMapper {
  toData(entity: BannerCreateRequest): BannerCreateRequestDto {
    return new BannerCreateRequestDto(
      entity.title,
      entity.description,
      entity.imageId,
      entity.appLinkId,
      entity.actionButtonId,
    );
  }
}
