<div class="card">
  <div class="card-body">
    <label class="form-label">{{ label }}</label>

    <div
      class="container-fluid image-select"
      *ngIf="!attachmentCropperData.origin && !attachmentCropperData.base64Origin"
    >
      <input
        class="form-control"
        type="file"
        [attr.id]="componentId"
        (change)="fileBrowseHandler($event)"
        [accept]="'image/*'"
        aria-describedby="validationImageFeedback"
      />
      <span class="dnd-text">Выберите фото или просто перетащите его в это окно</span>
      <label [attr.for]="componentId">Выбрать файл</label>
    </div>

    <div
      class="image-cropper-container"
      *ngIf="attachmentCropperData.origin || attachmentCropperData.base64Origin"
    >
      <button class="btn btn-danger remove-icon-btn" (click)="clearOriginImage()">
        <i class="ti ti-trash"></i>
      </button>

      <image-cropper
        #cropperRef
        format="jpeg"
        [imageBase64]="attachmentCropperData.base64Origin"
        [imageFile]="attachmentCropperData.origin"
        [aspectRatio]="aspectRatio"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        (startCropImage)="startCropImage()"
      ></image-cropper>
    </div>
  </div>

  <div *ngIf="this.recommendationInfo.length > 0" class="mt-3 information_doc card-footer">
    <div class="row">
      @for (item of this.recommendationInfo; track item.title) {
        <div class="col">
          <div class="file-req-header">{{ item.title }}</div>
          <div class="file-req-text" [innerHTML]="this.convert(item.message)"></div>
        </div>
      }
    </div>
  </div>
</div>
